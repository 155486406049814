import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
} from "@/components/ui/card";
import { cn } from "@/lib/utils";

interface ICardSettings {
    title: string;
    id: string;
    color?: string;
    icon: React.ReactNode;
    onClick: (id: string) => void;
}

const CardSetting: React.FC<ICardSettings> = ({ id, color, title, icon, onClick }) => {
    
    const handleCardClick = () => {
        onClick(id);
    };

    if (!color) return null;

    return (
        <Card 
            style={{ borderColor: color }} 
            className={cn('w-60', 'relative', 'h-37', 'border-2', 'cursor-pointer')}
            onClick={handleCardClick}
        >
            <CardHeader className="items-center">
                {icon && (
                    <div 
                        style={{ borderColor: color, backgroundColor: 'white' }} 
                        className="w-[60px] border-2 p-[17px] rounded-[32px] absolute top-[-30px] items-center"
                    >
                        {icon}
                    </div>
                )}
            </CardHeader>
            <CardContent className={cn('text-center', 'font-semibold')}>
                <p className="text-gray-500">{title}</p>
            </CardContent>
        </Card>
    );
};

export default CardSetting;