import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/common/presentation/components/ui/table';

import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { getResidents } from '@/modules/residents/presentation/slices/residentSlice';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
// import TableCustom from '@/common/presentation/components/Table/TableCustom';
import TrackerService from '../../infrastructure/services/TrackerService';
import * as temperatureActions from '../../presentation/slices/TrackerSlice';
import { Input } from '@/common/presentation/components/ui/input';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';

import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';

import { Pencil, Ellipsis, CircleFadingPlus, Search } from 'lucide-react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { Label } from '@/common/presentation/components/ui/label';
import { Switch } from '@/common/presentation/components/ui/switch';
import { useTranslation } from 'react-i18next';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import useHygineTypeColumns from '../components/hygine/HygineTypeColumns';
import RenderHygineTypeActionsColumn from '../components/hygine/RenderHygineTypeActionsColumn';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

interface TrackerHygieneTypeProps {
    dataHygieneReport: () => void;
}

function TrackerHygieneType({ dataHygieneReport }: TrackerHygieneTypeProps) {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const dispatch = useDispatch();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [types, setTypes] = useState([]);
    const [filteredTypes, setFilteredTypes] = useState([]);
    const [dataid, setDataid] = useState(null);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [selectedHygiene, setSelectedHygiene] = useState<string>(params.e || 'type');
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [select, setSelect] = useState(null);
    const { locationSelectedId } = useLocation();
    const { t } = useTranslation();
    const onSelectHygiene = (e: string) => {
        setParam('e', e);
        setSelectedHygiene(e);
    };
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);
    const [hygineTypeColumns] = useState(
        useHygineTypeColumns().map((value) => {
            if(value.key === "actions") {
                return {
                    ...value,
                    render: (rowData: any) => RenderHygineTypeActionsColumn(rowData, handleButtonOpenDialog.bind(this))
                };
            }

            return value;
        })
    );

    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (locationSelectedId && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        locationSelectedId === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    const columnsreport = [
        { label: t('trackers.customtype.Name'), value: 'name', type: 'date' },
        { label: t('trackers.customtype.Status'), value: 'status', type: 'text' }
    ];

    const setpTypes = async () => {
        const json = { location_id: locationSelectedId };
        const response = await TrackerService.getTrackerHygieneType(json);
        if (response && response.data) {
            const jsonData = JSON.parse(response.data);
            const columnField = jsonData.column_field;
            const columntype = jsonData.column_type;
            setSelect(columntype);
            setTypes(columnField);
            setFilteredTypes(
                columnField.filter((value) => value.status === "active")
            );
            setDataid(response.id);
        }

    };

    useEffect(() => {
        if (!types) {
            setpTypes();
        }
    }, [types, dataid, select]);


    const Fields = {
        'Edit': [
            { fieldName: 'name', label: t('trackers.customtype.Name'), type: 'text' },
        ]
    };

    const [fields, setDialogField] = useState(false);

    const handleButtonOpenDialog = (fields: any[], title: string, row: any[], description: string) => {
        setShowEditDialog(true);
        setFormValue(row);
        setDialogDescription(description);
        setDialogField(fields);
        setDialogTitle(title);
        setAction(title);
    };

    const handleSubmit = async () => {
        const json: { [key: string]: any } = {};
        if (action == 'Edit') {
            json["id"] = dataid;
            json["id_data"] = formData.id;
            json["data"] = {
                "name": formData.name,
                "status": formData.status === true || formData.status === 'active' ? 'active' : 'inactive'
            };
            const response = await TrackerService.updateTrackerHygieneType(json);
            dataHygieneReport();
        } else if (action == 'EditSelect') {
            json["id"] = dataid;
            json["id_data"] = formData.id;
            json["data"] = {
                "name": formData.name,
                "status": formData.status === true || formData.status === 'active' ? 'active' : 'inactive'
            };
            const response = await TrackerService.updateTrackerHygieneTypeSelect(json);
            dataHygieneReport();
        } else if (action == 'NewSelect') {
            json["data"] = {
                "name": formData.name,
                "status": 'active'
            };
            json["location_id"] = locationSelectedId;
            const response = await TrackerService.saveTrackerHygieneTypeSelect(json)
            dataHygieneReport();
        } else if (action == 'Delete') {
            json["id"] = dataid;
            json["id_data"] = formData.id;
            json["data"] = {
                "name": formData.name,
                "status": 'inactive'
            };
            const response = await TrackerService.updateTrackerHygieneType(json);
            dataHygieneReport();
        } else {
            json["data"] = {
                "name": formData.name,
                "status": 'active'
            };
            json["location_id"] = locationSelectedId;
            const response = await TrackerService.saveTrackerHygieneType(json);
            dataHygieneReport();
        }

        setShowEditDialog(false);

        setpTypes();
    };

    useEffect(() => {
        setpTypes();
    }, [locationSelectedId]);

    const [filters, setFilters] = useState({
        name: '',
        status: ''
    });

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
        setFormData({});
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const handleChangechecked = (checked, column) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [column.fieldName]: checked
        }));
    };


    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const renderInputForColumn = (column, index) => {
        switch (column.type) {
            case 'text':
                return (
                    <input
                        id={column.fieldName}
                        name={column.fieldName}
                        type="text"
                        value={formData[column.fieldName] || ''}
                        onChange={handleChange}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                    />
                );
            case 'switch':
                return (<Switch
                    checked={formData[column.fieldName] === 'inactive' ? false : !!formData[column.fieldName]}
                    onCheckedChange={(checked) => handleChangechecked(checked, column)}
                />);
            default:
                return null;
        }
    };
    return (
        <div>
            {
                selectedHygiene === 'type' && (
                    <div>
                        {
                            locationSelectedId && (
                                <div>
                                    <Card className="border-t-4 border-t-primary/50">
                                        <CardHeader>
                                            <div className='flex justify-between'>
                                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                                    {t('trackers.trackerhygiene.hygieneFields')}
                                                </CardTitle>
                                            </div>
                                        </CardHeader>

                                        <CardContent>
                                            <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                                    <CustomDialog
                                                        isOpen={showEditDialog}
                                                        onClose={handleCloseEditDialog}
                                                        title={dialogTitle}
                                                        onSubmit={handleSubmit}
                                                        description={dialogDescription}
                                                    >
                                                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                                            <div className="grid gap-4">
                                                                {fields && fields.map((field, index) => (
                                                                    <div key={field.fieldName} className="flex flex-col">
                                                                        <Label htmlFor={field.fieldName} className='mb-1'>{field.label}</Label>

                                                                        {renderInputForColumn(field, index)}
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            <div className='flex justify-end mt-5'>
                                                                <Button type='submit'>
                                                                    {t('trackers.trackerhygiene.accept')}
                                                                </Button>
                                                            </div>
                                                        </form>
                                                    </CustomDialog>

                                                    <TableCustomVirtuoso
                                                        data={filteredTypes}
                                                        columns={hygineTypeColumns}
                                                        renderCellContent={(index, column, data) => data[index][column.key]}
                                                        additionalButtons={
                                                            <Button onClick={() => handleButtonOpenDialog(Fields['Edit'], 'New', [])} className='h-9'>
                                                                <CircleFadingPlus className="h-4 w-4 mr-1" />
                                                                <span>
                                                                    {t("trackers.trackerhygiene.new")}
                                                                </span>
                                                            </Button>
                                                        }
                                                    />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div >
    )
}

export default TrackerHygieneType;
