import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const medicationService = {
  getAllMedications: async (locationId, filter, medicationId) => {
    let url = 'medications/listmedications';

    if (locationId) {
      url += `?location_id=${locationId}`;
    }

    if (filter) {
      url += `${locationId ? '&' : '?'}filter=${encodeURIComponent(filter)}`;
    }

    if (medicationId) {
      url += `${filter || locationId ? '&' : '?'}medication_id=${medicationId}`;
    }

    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      throw new Error('Error fetching medications: ' + error.message);
    }
  },

  getAllMedicationsByLocation: async (medicationId, filter, residentId) => {
    let url = 'medications/listMedicationsByLocation';
    const queryParams = [];

    if (medicationId) {
      queryParams.push(`medication_id=${medicationId}`);
    }

    if (filter) {
      queryParams.push(`filter=${encodeURIComponent(filter)}`);
    }

    if (residentId) {
      queryParams.push(`resident_id=${residentId}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      throw new Error('Error fetching medications: ' + error.message);
    }
  },

  getAllTypes: async (locationId?: string) => {
    try {
      const url = locationId !== undefined ? `?location_id=${locationId}` : '';
      return await axios.get(`medications/listtypes${url}`);
    } catch (error) {
      throw new Error('Error fetching types: ' + error);
    }
  },

  getAllTimes: async () => {
    try {
      return await axios.get(`medications/listtimes`);
    } catch (error) {
      throw new Error('Error fetching times: ' + error);
    }
  },

  getAllRoutes: async () => {
    try {
      return await axios.get(`medications/listroutes`);
    } catch (error) {
      throw new Error('Error fetching routes: ' + error);
    }
  },

  getAllResponsible: async () => {
    try {
      return await axios.get(`medications/listResponsibleAdministration`);
    } catch (error) {
      throw new Error('Error fetching routes: ' + error);
    }
  },

  getAllForms: async () => {
    try {
      return await axios.get(`medications/list-medication-form`);
    } catch (error) {
      throw new Error('Error fetching form: ' + error);
    }
  },

  getAllMyMedicines: async () => {
    try {
      return await axios.get(`medications/listMyMedicines`);
    } catch (error) {
      throw new Error('Error fetching medication: ' + error);
    }
  },

  createResidentMedications: async (formData: any) => {
    try {
      const response = await axios.post(`medications/createResidentMedications`, formData);
      return response.data;
    } catch (error: any) {
      throw new Error('Error creating medication: ' + error.message);
    }
  },

  getMedicationsPdf: async (residentId: string, documentType: string = 'emar', requestData: any) => {
    try {
      const response = await axios.post(
        `medications/resident-medications-pdf/${residentId}/${documentType}`,
        requestData,
        {
          responseType: 'blob'
        }
      );
      return response;
    } catch (error: any) {
      if (error.response) {
        // El servidor respondió con un código de estado fuera del rango de 2xx
        const errorMessage = error.response.data.message || 'The resident has no medications';
        console.error('Error del servidor:', errorMessage);
        throw new Error(errorMessage);
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió respuesta
        console.error('No se recibió respuesta del servidor:', error.request);
        throw new Error('No se recibió respuesta del servidor');
      } else {
        // Algo sucedió al configurar la solicitud que desencadenó un error
        console.error('Error al configurar la solicitud:', error.message);
        throw new Error(error.message);
      }
    }
  },

  getTimesPdf: async (locationId) => {
    try {
      const response = await axios.get(`medications/times/${locationId}/pdf`, {
        responseType: 'blob'
      });
      return response;
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'The resident has no medications';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No se recibió respuesta del servidor');
      } else {
        throw new Error(error.message);
      }
    }
  },

  async getAllMedicationsByClientId(locationId, residentId, filter, type, status, page = 1, limit = 10) {
    try {
      let url = 'medications/list/resident-medication';

      const queryParams = new URLSearchParams();

      if (locationId) {
        queryParams.append('location_id', locationId);
      }

      if (residentId) {
        queryParams.append('resident_id', residentId);
      }

      if (filter) {
        queryParams.append('filter', encodeURIComponent(filter));
      }

      if (type) {
        queryParams.append('type', encodeURIComponent(type));
      }

      if (status) {
        queryParams.append('status', encodeURIComponent(status));
      }

      queryParams.append('page', page);
      queryParams.append('limit', limit);

      if (queryParams.toString()) {
        url += `?${queryParams.toString()}`;
      }

      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching medications: ' + error.message);
    }
  },

  getResidentMedicationTime: async (residentId?: string) => {
    try {
      const response = await axios.get(`medications/medications/${residentId}/medication-times`);
      return response;
    } catch (error: any) {
      throw new Error('Error fetching list medications by client: ' + error.message);
    }
  },

  getAllMedicationsByResidentId: async (residentId: string, page = 1, limit = 50) => {
    try {
      const response = await axios.get(`medications/resident/${residentId}/medication`, {
        params: {
          resident_id: residentId,
          page: page,
          limit: limit
        }
      });
      return response.data;
    } catch (error) {
      throw new Error('Error fetching medications by resident ID: ' + error.message);
    }
  },

  getResiddentMedicationById: async (id: string) => {
    try {
      const response = await axios.get(`medications/getResidentMedication/${id}`);
      return response.data;
    } catch (error: any) {
      throw new Error('Error fetching resident medication by ID: ' + error.message);
    }
  },

  editResidentMedicationById: async (id: string, data: any) => {
    try {
      const response = await axios.put(`medications/editResidentMedication/${id}`, data);
      return response.data;
    } catch (error: any) {
      throw new Error('Error editing residentmedication: ' + error.message);
    }
  },

  disposeMedication: async (disposalData: any) => {
    try {
      const response = await axios.post('medications/destroyed', disposalData);
      return response;
    } catch (error) {
      throw new Error('Error disposing medication: ' + error.message);
    }
  },

  getDestroyedMedications: async (residentId) => {
    try {
      const response = await axios.get(`medications/destroyed?residentId=${residentId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching destroyed medications: ' + error.message);
    }
  },

  copyMedication: async (formCopy: { residentId: string; medicationId: string }) => {
    try {
      const response = await axios.post('medications/medication-copy', formCopy);
      return response;
    } catch (error) {
      throw new Error('Error copy medication: ' + error.message);
    }
  },

  getMedicationAudits: async (residentMedicationId: string) => {
    try {
      const response = await axios.get(`medications/medication-audits/${residentMedicationId}`);
      return response;
    } catch (error) {
      throw new Error('Error copy medication: ' + error.message);
    }
  },

  getCentralizedMedication: async (residentId: string, page: number = 1, limit: number = 50) => {
    try {
      const response = await axios.get(
        `medications/centralized/${residentId}?resident_id=${residentId}&page=${page}&limit=${limit}`
      );
      return response;
    } catch (error) {
      throw new Error('Error fetching centralized medication: ' + error.message);
    }
  },

  updateMassiveResidentMedications: async (medicationsData) => {
    try {
      const response = await axios.post('medications/update-massive', medicationsData);
      return response;
    } catch (error) {
      throw new Error('Error fetching update massve: ' + error.message);
    }
  }
};

export default medicationService;
