import { Calendar } from '@/common/presentation/components/ui/calendar';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { MonthAndYearSelector } from '@/common/presentation/components/CustomCalendar/MonthAndYearSelector';
import { TimePicker } from '@/common/presentation/components/CustomCalendar/TimePicker';

interface CustomCalendarProps {
  onChange?: (date: Date) => void;
  MonthAndYearPicker?: boolean;
  timePicker?: boolean;
  baseSelectorYear?: number;
  maxSelectorYear?: number;
  classMonthYearSelect?: string;
  selectedDate?: Date;
}

export const CustomCalendar = ({
  onChange,
  MonthAndYearPicker = false,
  timePicker = false,
  baseSelectorYear,
  maxSelectorYear,
  classMonthYearSelect = '',
  selectedDate
}: CustomCalendarProps) => {
  const [date, setDate] = useState<Date>(selectedDate || new Date());

  useEffect(() => {
    if (selectedDate) {
      setDate(selectedDate);
    }
  }, [selectedDate]);

  const handleDateChange = (selectedDate: Date | undefined) => {
    if (!selectedDate) return;
    const updatedDate = new Date(date);
    updatedDate.setFullYear(selectedDate.getFullYear());
    updatedDate.setMonth(selectedDate.getMonth());
    updatedDate.setDate(selectedDate.getDate());
    setDate(updatedDate);
    onChange?.(updatedDate);
  };

  const handleTimeChange = (newTime: string) => {
    const [hours, minutes] = newTime.split(':').map(Number);
    const updatedDate = new Date(date);
    updatedDate.setHours(hours);
    updatedDate.setMinutes(minutes);
    updatedDate.setSeconds(0);
    setDate(updatedDate);
    onChange?.(updatedDate);
  };

  const handleMonthYearChange = (newDate: Date) => {
    const updatedDate = new Date(date);
    updatedDate.setFullYear(newDate.getFullYear());
    updatedDate.setMonth(newDate.getMonth());
    setDate(updatedDate);
    onChange?.(updatedDate);
  };

  return (
    <div className="flex flex-col items-center w-full">
      {MonthAndYearPicker && (
        <MonthAndYearSelector
          date={date}
          onChange={handleMonthYearChange}
          baseSelectorYear={baseSelectorYear}
          maxSelectorYear={maxSelectorYear}
          className={classMonthYearSelect}
        />
      )}

      <div className="flex flex-col sm:flex-row justify-center items-start w-full space-y-4 sm:space-y-0 sm:space-x-4">
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleDateChange}
          month={date}
          defaultMonth={date}
          initialFocus
        />

        {timePicker && (
          <div className="w-full sm:w-auto">
            <TimePicker onChange={handleTimeChange} value={format(date, 'HH:mm')} />
          </div>
        )}
      </div>
    </div>
  );
};
