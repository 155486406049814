import { Search } from 'lucide-react';
import { useEffect, useState, useRef } from 'react';
import { cn } from '@/lib/utils';
import { Input } from '@/common/presentation/components/registry/new-york/ui/input';
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup
} from '@/common/presentation/components/registry/new-york/ui/resizable';
import { Separator } from '@/common/presentation/components/registry/new-york/ui/separator';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/common/presentation/components/registry/new-york/ui/tabs';
import { TooltipProvider } from '@/common/presentation/components/registry/new-york/ui/tooltip';
import { NoteDisplay } from '@/modules/notes/presentation/components/common/noteDisplay';
import { NoteList } from '@/modules/notes/presentation/components/notes/noteList';
import { DraftList } from '@/modules/notes/presentation/components/notes/draftList';
import { CreateNote } from './notes/createNote';
import { CreateStaffNote } from './staffNotes/createStaffNote';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import {
  fetchGetNotesCategories,
  fetchGetQuickNotes,
  fetchRefreshNotes,
  fetchRefreshStaffNotes,
  removeNoteFilter,
  setNoteFilters,
  fetchGetNotes,
  cleanNotes
} from '../slices/NoteSlice';
import { fetchGetDepartments } from '@/modules/departments/presentation/slices/DepartmentSlice';
import { useDispatch } from 'react-redux';
import { getResidentsByLocation } from '@/modules/residents/presentation/slices/residentsSlice';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { getStaffByLocation } from '@/modules/staff/presentation/slices/staffSlice';
import { Button } from '@/common/presentation/components/registry/new-york/ui/button';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { UpdateNote } from './notes/UpdateNote';
import { websocket } from '@/utils/helpers/websocket.helper';
import { useConfigurationsProvider } from '@/common/infrastructure/providers/ConfigurationsProvider';
import NoteHelperService from '../../infrastructure/services/NoteHelperService';
import { NoteStaffList } from './staffNotes/noteStaffList';
import { ImperativePanelHandle } from 'react-resizable-panels';
import { useMediaQuery } from 'react-responsive';
import { NoteFilters } from './common/noteFilters';
import { ShareNote } from './common/shareNote';
import { useTranslation } from 'react-i18next';
import { Navigation } from './common/navigation';
import { fetchrefreshDrafts } from '../slices/NoteSlice';
import { Badge } from '@/common/presentation/components/ui/badge';

interface NoteProps {
  sizes?: number[] | undefined;
  defaultCollapsed?: boolean;
}

export function Note({ sizes = [440, 655] }: NoteProps) {
  // Redux
  const dispatch = useDispatch();
  const noteSelected = useSelector((state: RootState) => state.notes.noteId);
  const notes = useSelector((state: RootState) => state.notes.notes.payload);
  const selectedNotes = useSelector((state: RootState) => state.notes.selectedNotes);
  const drafts = useSelector((state: RootState) => state.notes.drafts.data);
  const staffNotes = useSelector((state: RootState) => state.notes.staffNotes.payload);
  const metaNote = useSelector((state: RootState) => state.notes.notes?.meta);

  // Initial
  const { t } = useTranslation();
  const { clientInfo } = useConfigurationsProvider();

  // Hooks
  const { params, setParam } = useRouteParams();
  const [crudState, setCrud] = useState<CrudState>(NoteHelperService.defineState({}));
  const [size, setSize] = useState<any>(sizes);
  const refA = useRef<ImperativePanelHandle>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (isMobile) {
      refA.current?.resize(size[0]);
    }
  }, [size]);

  useEffect(() => {
    dispatch<any>(getResidentsByLocation(params.l === 'all-locations' ? undefined : params.l));
    dispatch<any>(getStaffByLocation(params.l === 'all-locations' ? undefined : params.l));
    dispatch<any>(fetchGetDepartments());
    dispatch<any>(fetchGetNotesCategories());
    dispatch<any>(fetchGetQuickNotes());

    websocket.subscribe(`notes-${clientInfo.id}`, (data: any) => {
      if (data.message[0] == 'staff') {
        dispatch<any>(fetchRefreshStaffNotes());
      } else {
        dispatch<any>(fetchRefreshNotes());
      }
    });
  }, []);

  // Behavior
  const setCrudState = (state: CrudState) => {
    // Callback
    crudState.action(state);
    // Change state
    setCrud(state);

    if (isMobile) {
      const newSize = NoteHelperService.resizePanel(state);
      setSize(newSize);
    }
  };

  const handleDrafts = () => {
    dispatch<any>(fetchrefreshDrafts());
    setCrudState(NoteHelperService.defineState({ showingDrafts: true }));
  };

  const handleUnreadNotes = () => {
    dispatch(setNoteFilters(['unread', 'true']));
    dispatch<any>(cleanNotes());
  };

  const handleAllNotes = () => {
    dispatch(removeNoteFilter('unread'));
    dispatch<any>(cleanNotes());
  };

  const handleInputSearchNotes = async (event: any) => {
    dispatch(setNoteFilters(['search', event.target.value ?? '']));
    dispatch(setNoteFilters(['nextPage', '1']));
  };

  const handleSubmitSearchNotes = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch<any>(fetchRefreshNotes());
  };

  return (
    <TooltipProvider delayDuration={0}>
      <ResizablePanelGroup
        direction="horizontal"
        onLayout={(sizes: number[]) => {
          document.cookie = `react-resizable-panels:layout=${JSON.stringify(sizes)}`;
        }}
        className="max-h-[90vh] items-stretch"
      >
        <ResizablePanel defaultSize={size[0]} ref={refA}>
          <Tabs defaultValue={crudState.showingDrafts ? 'drafts' : 'all'}>
            {selectedNotes.length <= 0 ? (
              <div className="flex items-center px-4 py-2">
                <Navigation crudState={crudState} setCrudState={setCrudState} />
                {!crudState.showingStaffNotes && (
                  <TabsList className="ml-auto">
                    <TabsTrigger
                      value="all"
                      onClick={() => handleAllNotes()}
                      className="text-zinc-600 dark:text-zinc-200"
                    >
                      {t('notes.allNotes')}
                    </TabsTrigger>
                    <TabsTrigger
                      value="unread"
                      onClick={() => handleUnreadNotes()}
                      className="text-zinc-600 dark:text-zinc-200 relative"
                    >
                      {`${t('notes.unread')}`}
                      {metaNote.quantityUnread && (<Badge variant="default" className='rounded-full mb-7 absolute right-0'>
                        {metaNote.quantityUnread}
                      </Badge>)}
                    </TabsTrigger>
                    <TabsTrigger
                      value="drafts"
                      onClick={() => handleDrafts()}
                      className="text-zinc-600 dark:text-zinc-200"
                    >
                      {t('notes.draft')}
                    </TabsTrigger>
                  </TabsList>
                )}
                <div className={cn(!crudState.showingStaffNotes ? 'ml-1' : 'ml-auto')}>
                  <NoteFilters crudState={crudState}></NoteFilters>
                </div>
              </div>
            ) : (
              <ShareNote></ShareNote>
            )}

            <Separator />

            <div className="bg-background/95 p-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
              <form onSubmit={handleSubmitSearchNotes}>
                <div className="relative flex">
                  <Input placeholder={t('notes.search')} className="pl-8" onChange={handleInputSearchNotes} />
                  <Button variant="ghost" size="icon" type="submit">
                    <Search className="absolute rigth-2 top-2.5 h-4 w-4 text-muted-foreground" />
                  </Button>
                </div>
              </form>
            </div>

            {/* All Notes Tab */}
            <TabsContent value="all" className="m-0">
              {crudState.showingStaffNotes ? (
                <NoteStaffList items={staffNotes} setCrudState={setCrudState} crudState={crudState} />
              ) : (
                <NoteList
                  key="all-notes"
                  setCrudState={setCrudState}
                  crudState={crudState}
                  items={notes}
                  setSize={isMobile ? setSize : undefined}
                />
              )}
            </TabsContent>

            {/* Unread Notes Tab */}
            <TabsContent value="unread" className="m-0">
              {crudState.showingStaffNotes ? (
                <NoteStaffList items={staffNotes} setCrudState={setCrudState} crudState={crudState} />
              ) : (
                <NoteList
                  key="unread-notes"
                  setCrudState={setCrudState}
                  crudState={crudState}
                  items={notes}
                  setSize={isMobile ? setSize : undefined}
                />
              )}
            </TabsContent>

            {/* Drafts Tab */}
            <TabsContent value="drafts" className="m-0">
              <DraftList items={drafts} setCrudState={setCrudState} crudState={crudState} />
            </TabsContent>
          </Tabs>
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={size[1]}>
          {!crudState.isCreating &&
            !crudState.isUpdating &&
            !crudState.isFinishingDraft &&
            !crudState.isUpdatingStaffNote &&
            !crudState.isCreatingStaffNote ? (
            notes && (
              <NoteDisplay
                note={
                  !crudState.showingStaffNotes
                    ? notes?.find((item) => item.id === noteSelected) || null
                    : staffNotes?.find((item) => item.id === noteSelected) || null
                }
                setCrudState={setCrudState}
                crudState={crudState}
              />
            )
          ) : !crudState.isUpdatingStaffNote && !crudState.isCreatingStaffNote ? (
            crudState.isCreating || crudState.isFinishingDraft ? (
              <CreateNote crudState={crudState} setCrudState={setCrudState} />
            ) : (
              <UpdateNote
                crudState={crudState}
                setCrudState={setCrudState}
                note={notes?.find((item) => item.id === crudState.itemId) || null}
              />
            )
          ) : (
            <CreateStaffNote
              crudState={crudState}
              setCrudState={setCrudState}
              note={staffNotes?.find((item) => item.id === crudState.itemId) || null}
            ></CreateStaffNote>
          )}
        </ResizablePanel>
      </ResizablePanelGroup>
    </TooltipProvider>
  );
}
