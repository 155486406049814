import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MedicationConfiguration from "../infrastructure/services/MedicationConfiguration";

export interface IMedicationConfigurationsState {
  allowedUsers: [],
  isLoading: boolean;
  error: string | null;
}

const initialState: IMedicationConfigurationsState = {
  allowedUsers:[],
    isLoading: false,
    error: ''
};


export const getAllowUsersEditMedication = createAsyncThunk('allowStaffEditMedication/getEnabledMedications',
 async () => {
    const response = await MedicationConfiguration.getAllowUser();
    return response;
  }
);

export const updateAllowUsersEditMedication = createAsyncThunk('allowStaffEditMedication/update',
  async (formData: any ) => {
     const response = await MedicationConfiguration.update(formData);
     return response;
   }
 );

const MedicationConfigurationSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    allowUsersEditMedicationLoading: (state)=>{
     state.isLoading = true;
     state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder.
    addCase(getAllowUsersEditMedication.fulfilled, (state, action) => {
      state.allowedUsers = action.payload;
      state.isLoading = false;
    });
  }
});

export const { allowUsersEditMedicationLoading } = MedicationConfigurationSlice.actions;
export default MedicationConfigurationSlice.reducer;