import './quill.css';
import '@blocknote/core/fonts/inter.css';
import '@blocknote/mantine/style.css';
import 'react-quill/dist/quill.snow.css';
import { CrudState } from '@/modules/notes/domain/note.domain';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EditorToolbar, { modules, formats } from './editorToolbar';
import ReactQuill from 'react-quill';



interface QuillJsValues {
  content: string;
  html_content: string;
  images: File[];
}

interface NotesEditorProps {
  value?: string;
  onChange: (values: QuillJsValues) => void;
  disabled?: boolean;
  submit?: boolean;
  translator?: boolean;
  crudState?: CrudState;
}

export const QuillJS = ({
  value: propValue = '',
  onChange,
  disabled,
  submit = false,
  translator = false,
  crudState
}: NotesEditorProps) => {

  // Initial
  const { t } = useTranslation();

  const quillRef = useRef<ReactQuill>(null);
  const [value, setValue] = useState(propValue || '');

  useEffect(() => {
    if (crudState && crudState.isFinishingDraft || (value !== propValue)) {
      setValue(propValue);
      handleChange(propValue);
    }
  }, [propValue]);

  const handleChange = (content: string) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      let images: File[] = [];
      editor.getContents().forEach((element: any) => {
        if (element.insert && element.insert.image) {
          images.push(element.insert.image);
        }
      });

      let htmlContent = content;
      let tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlContent;
      let imgTags = tempDiv.querySelectorAll('img');
      imgTags.forEach((img) => img.remove());
      let cleanedHtmlContent = tempDiv.innerHTML;

      setValue(content);
      onChange({
        content: editor.getText(),
        html_content: cleanedHtmlContent,
        images: images
      });
    }
  };

  return (
    <>
      <div className="w-full overflow-y-auto">
        <EditorToolbar submit={submit} translator={translator} />
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={value}
          onChange={handleChange}
          placeholder={t('notes.writeSomething')}
          formats={formats}
          modules={modules}
          readOnly={disabled}
        />
      </div>
    </>
  );
};
