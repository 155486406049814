import { CircleFadingPlus, UserRoundX } from 'lucide-react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { Button } from '@/common/presentation/components/ui/button';
import { AppDispatch, RootState } from '@/store/store';

import { useNewResident } from '@/modules/residents/infrastructure/hooks/use-new-resident';
import { columns } from '@/modules/residents/presentation/components/columns';

import { useNavigate } from 'react-router-dom';
import { LoadingComponent } from '@/modules/residents/presentation/components/LoadingComponent';

import { Dialogs as ResidentDashboardDialogs } from '@/modules/residents/presentation/components/Dashboard/dialogs/Dialogs';
import * as residentsActions from '@/modules/residents/presentation/slices/residentsSlice';
import { useInnactiveResidentsDialog } from '@/modules/residents/infrastructure/hooks/dashboard/useInactiveResidentsDialog';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

export const ResidentsPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { onOpen } = useNewResident();
  const navigate = useNavigate();
  const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
  const { onOpen: onOpenInnactiveResidentsDialog } = useInnactiveResidentsDialog();
  const { locationSelectedId } = useLocation();

  useEffect(() => {
    if (locationSelectedId) {
      const location = locationSelectedId === 'all-locations' ? undefined : locationSelectedId;
      //dispatch(residentsActions.getResidentsByLocation(location));
    }
  }, [locationSelectedId]);

  const selectedRow = useCallback(
    (rowIndex: number) => {
      const selectedResident = residents[rowIndex];
      navigate(`/residents/profile?r=${selectedResident.id}`);
    },
    [residents, navigate]
  );

  const aditionalActions = useMemo(
    () => (
      <div className="flex ml-auto w-auto gap-4">
        <Button onClick={onOpenInnactiveResidentsDialog} className="flex ml-auto w-auto gap-2" variant="outline">
          <UserRoundX className="h-4 w-4" />
          Inactive Residents
        </Button>
        <Button onClick={onOpen} className="flex ml-auto w-auto gap-2" variant="default">
          <CircleFadingPlus className="h-4 w-4" />
          Create Resident
        </Button>
      </div>
    ),
    [onOpenInnactiveResidentsDialog, onOpen]
  );

  return (
    <>
      <LocationSelector />

      {status === 'loading' && <LoadingComponent status={status} label="Loading Residents" />}

      {status === 'failed' && <div>Failed to load residents</div>}

      {status === 'idle' && (
        <div className="flex flex-col gap-4 my-4">
          <SingleTable
            data={residents}
            columns={columns}
            onRowClick={selectedRow}
            filterKey="first_name"
            aditionalActions={aditionalActions}
          />
        </div>
      )}

      <ResidentDashboardDialogs />
    </>
  );
};
