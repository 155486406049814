import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllMedicationsByClientId,
  fetchMedicationAudits,
  copyMedication,
  GenerateTimesPDF
} from '../slices/medicationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { ArrowLeft } from 'lucide-react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { Button } from '@/common/presentation/components/ui/button';
import { useTranslation } from 'react-i18next';
import useColumnsResidentMedications from './columnsResidentMedications';
import RenderActionsColumn from './renderActionsColumn';
import ConfirmationModal from './ConfirmationModal';
import DialogAudit from '../components/Historial/DialogAudit';
import useColumnsAudit from '../components/Historial/columnsAudit';
import { useNewListMedication } from '../../infrastructure/hooks/use-new-list-medications';
import FilterTable from './FilterTable';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import CreateMedication from '../components/CreateMedications/CreateMedication';
import { getLocation } from '@/modules/locations/presentation/slices/locationSlice';
import * as residentActions from '@/modules/residents/presentation/slices/residentSlice';
import { format, isValid, parse } from 'date-fns';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { NavigationItems } from '../components/NavigationItems';
import { useNewMar } from '../../infrastructure/hooks/use-new-mar';

const MedicationsPage: React.FC = () => {
  const { onOpen } = useNewListMedication();
  const { onOpen: onOpenMar } = useNewMar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const { location } = useSelector((state: RootState) => state.locations.location);
  const { medicationsByClientId, medicationstatus } = useSelector((state: RootState) => state.medications);
  const columnsAudit = useColumnsAudit();
  const locationPage = useLocation();
  const [shouldOpenCopy, setShouldOpenCopy] = useState(false);
  const [shouldOpeAudit, setShouldOpeAudit] = useState(false);
  const [audits, setAudits] = useState<any[]>([]);
  const [filters, setFilters] = useState<{ filter: string }>({ filter: '' });
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(50);
  const [medications, setMedications] = useState<any[]>([]);
  const [selectedMedicationId, setSelectedMedicationId] = useState<string | undefined>(undefined);
  const [sorting, setSorting] = useState({ key: '', direction: 'ascending' });
  const [showForm, setShowForm] = useState(false);
  const columnsResidentMedications = useColumnsResidentMedications(filters.status);

  const typeOptions = [
    { name: 'Routine', value: 'routine' },
    { name: 'PRN', value: 'prn' }
  ];

  const statusOptions = [
    { name: 'Active', value: 'true' },
    { name: 'Inactive', value: 'false' },
    { name: 'ALL', value: 'ALL' }
  ];

  const fetchFilteredData = useCallback(
    async (newFilters: { filter: string; status: string; type: string }) => {
      const filterType = newFilters.type && newFilters.type !== 'ALL' ? newFilters.type : '';
      const filterStatus =
        newFilters.status === 'true' || newFilters.status === 'false' || newFilters.status === 'ALL'
          ? newFilters.status
          : '';

      try {
        const response = await dispatch(
          getAllMedicationsByClientId({
            locationId: params.l,
            residentId: params.r,
            filter: newFilters.filter,
            type: filterType,
            status: filterStatus,
            page: 1,
            limit: pageSize
          })
        ).unwrap();

        setMedications(response.data);
        setFilters(newFilters);
        setCurrentPage(1);
        setHasMore(true);
      } catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    },
    [dispatch, params.l, params.r, pageSize]
  );

  const fetchMedications = useCallback(
    async (page = 1) => {
      try {
        await dispatch(
          getAllMedicationsByClientId({
            locationId: params.l,
            residentId: params.r,
            filter: filters.filter,
            type: filters.type || '',
            status: filters.status || '',
            page,
            limit: pageSize
          })
        ).unwrap();
        setCurrentPage(page);
        setHasMore(true);
      } catch (error) {
        console.error('Error loading medications:', error);
      }
    },
    [dispatch, params.l, params.r, filters, pageSize]
  );

  useEffect(() => {
    fetchMedications();
  }, [fetchMedications]);

  const transformedMedications = useMemo(() => {
    return medicationsByClientId.map((medication) => {
      const filleDate = medication?.filleDate
        ? isValid(parse(medication.filleDate, 'yyyy-MM-dd', new Date()))
          ? format(parse(medication.filleDate, 'yyyy-MM-dd', new Date()), t('dateFormats.dateFormat'))
          : ''
        : '';

      return {
        ...medication,
        time: medication.time ? [...new Set(medication.time)].join(', ') : '',
        filleDate,
        status: medication.status
      };
    });
  }, [medicationsByClientId, t]);

  useEffect(() => {
    setMedications(transformedMedications);
  }, [transformedMedications]);

  useEffect(() => {
    if (!location?.name && params.l) {
      dispatch(getLocation(params.l));
    }
  }, [dispatch, params.l, location?.name]);

  const handleEdit = useCallback(
    (medicationId: number) => {
      navigate(`/medications/edit/${medicationId}?l=${params.l || ''}`);
    },
    [navigate, params.l]
  );

  const handleCopy = (medicationId: string) => {
    setSelectedMedicationId(medicationId);
    setShouldOpenCopy(true);
  };

  const handleAudit = useCallback(
    async (medicationId: string) => {
      setShouldOpeAudit(true);
      try {
        const response = await dispatch(fetchMedicationAudits(medicationId)).unwrap();
        setAudits(response || []);
      } catch (error) {
        console.error('Error fetching medication audits:', error);
        setAudits([]);
      }
    },
    [dispatch]
  );

  const handleGoBack = () => {
    navigate(locationPage.key === 'default' ? '/medications' : -1);
  };

  const fetchMoreData = useCallback(() => {
    if (!hasMore || medicationstatus === 'loading') return;
    fetchMedications(currentPage + 1);
  }, [hasMore, medicationstatus, fetchMedications, currentPage]);

  const handleConfirmCopy = async () => {
    if (selectedMedicationId) {
      try {
        await dispatch(copyMedication({ medicationId: selectedMedicationId })).unwrap();
        setShouldOpenCopy(false);
        getAllMedicationsByClientId({
          locationId: params.l,
          residentId: params.r,
          filter: newFilters.filter,
          type: filterType,
          status: filterStatus,
          page: 1,
          limit: pageSize
        })
      } catch (error) {
        console.error('Error copying medication:', error);
      }
    }
  };

  const handleSort = useCallback(
    (columnKey, direction) => {
      setSorting({ key: columnKey, direction });

      const sortedData = [...medications].sort((a, b) => {
        if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
        if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
        return 0;
      });

      setMedications(sortedData);
    },
    [medications]
  );

  const handleTimesPdf = async () => {
    try {
      const locationId = params.l;
      const resultAction = await dispatch(GenerateTimesPDF({ locationId }));
      if (GenerateTimesPDF.fulfilled.match(resultAction)) {
        const pdfBlob = resultAction.payload;

        const url = URL.createObjectURL(new Blob([pdfBlob], { type: 'application/pdf' }));

        window.open(url, '_blank');
      } else {
        console.error('Failed to generate PDF:', resultAction.error.message);
      }
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  const handleFilter = useCallback(
    (searchText: string) => {
      dispatch(
        getAllMedicationsByClientId({
          locationId: params.l,
          residentId: params.r,
          filter: searchText,
          type: filters.type || '',
          status: filters.status || '',
          page: 1,
          limit: pageSize
        })
      );
    },
    [dispatch]
  );

  const handlecentralized = () => {
    navigate('/medications/centralized');
  };

  const handledestruction = () => {
    navigate('/medications/destruction');
  };

  return (
    <div className="gap-4 my-4">
      <div>
        <div className="flex items-center justify-between space-x-4 mb-4 my-5">
          <div className="flex items-center space-x-4">
            <Button variant="ghost" onClick={handleGoBack}>
              <ArrowLeft className="mr-2 h-4 w-4" />
              {t('medications.back')}
            </Button>
            <div className="font-semibold text-2xl">
              {t('medications.title')}{' '}
              <span className="font-bold">{params.l === 'all-locations' ? 'All Locations' : location?.name}</span>
            </div>
          </div>
          <Tabs className="ml-auto">
            <TabsList>
              <TabsTrigger
                value="centralized"
                onClick={handlecentralized}
                className="data-[state=active]:bg-primary data-[state=active]:text-white"
              >
                {t('medications.centralized')}
              </TabsTrigger>
              <TabsTrigger
                value="destruction"
                onClick={handledestruction}
                className="data-[state=active]:bg-primary data-[state=active]:text-white"
              >
                {t('medications.destruction')}
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        {!params.l ? (
          <LocationSelector selections={false} />
        ) : (
          <>
            <LocationSelector selections={false} quickView={true} />
            <ResidentSelector
              onSelect={(residentId) => residentId === 'all-residents' && dispatch(residentActions.resetResident())}
              locationId={params.l}
              residentId={params.r}
              showFilterInput={false}
            />
          </>
        )}

        <div className="flex flex-col gap-4 my-4">
          <TableCustomVirtuoso
            data={medications}
            columns={columnsResidentMedications.map((col) =>
              col.key === 'actions'
                ? {
                    ...col,
                    render: (rowData) => (
                      <RenderActionsColumn
                        rowData={rowData}
                        handleEdit={handleEdit}
                        handleCopy={handleCopy}
                        handleAudit={handleAudit}
                      />
                    )
                  }
                : col
            )}
            onFilterChange={handleFilter}
            renderCellContent={(index, column, data) => data[index][column.key]}
            onSort={handleSort}
            loadMore={fetchMoreData}
            hasMore={hasMore}
            isLoading={medicationstatus === 'loading'}
            rowClickPath={(rowData) => `/medications/edit/${rowData.id}`}
            onFilterClear={fetchMedications}
            additionalButtons={
              <>
                <NavigationItems />
                <Button
                  onClick={() => onOpen(params.l || undefined, params.r || undefined)}
                  size="sm"
                  className="flex items-center"
                >
                  <img src="/svg/pdf_icon.svg" alt="Icono de PDF" className="mr-1 w-4 h-4" />
                  <span className="text-xs">{t('medications.listMedications')}</span>
                </Button>
                {params.l && params.r && !(params.l === 'all-locations' && params.r === 'all-residents') && (
                  <Button className="flex flex-row items-center gap-2" onClick={handleTimesPdf} size="sm">
                    <img src="/svg/pdf_icon.svg" alt="Icono de PDF" className="size-5" />
                    {t('medications.times')}
                  </Button>
                )}
                <Button onClick={() => setShowForm(true)} size="sm">
                  {t('medications.newMedication')}
                </Button>
              </>
            }
            filters={
              <FilterTable
                typeOptions={typeOptions}
                statusOptions={statusOptions}
                filters={filters}
                setFilters={setFilters}
                fetchFilteredData={fetchFilteredData}
              />
            }
          />
        </div>
      </div>

      <ConfirmationModal
        isOpen={shouldOpenCopy}
        onClose={() => setShouldOpenCopy(false)}
        onConfirm={handleConfirmCopy}
        message={t('medications.confirmationModal.copyMessage')}
      />

      <DialogAudit
        isOpen={shouldOpeAudit}
        onClose={() => setShouldOpeAudit(false)}
        audits={audits}
        columns={columnsAudit}
        className="md:max-w-[625px] lg:max-w-[625px]"
      />

      <CustomDialog
        isOpen={showForm}
        onClose={() => setShowForm(false)}
        title="New Medication"
        className="md:max-w-[625px] lg:max-w-[625px]"
      >
        <CreateMedication onClose={() => setShowForm(false)} />
      </CustomDialog>
    </div>
  );
};

export default MedicationsPage;
