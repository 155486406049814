import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from '@/common/presentation/components/ui/dialog';
import clsx from 'clsx';

interface DialogDemoProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  width: string,
  height: string,
  newDialog: boolean;
  children: React.ReactNode;
  onSubmit?: (formData: { [key: string]: string }) => void;
  buttonLabel?: string;
  className?: string;
}

export function CustomDialog({ isOpen, onClose, title, description, children, className }: DialogDemoProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose} modal>
      <DialogContent
        className={clsx(
          'flex flex-col max-h-[90vh] min-w-[300px] min-h-[200px] shadow-lg rounded-md border-t-4 border-primary p-6', // Adjust max width and height
          className
        )}
      >
        <DialogHeader>
          <DialogTitle className="text-primary text-2xl font-bold">{title}</DialogTitle>
        </DialogHeader>
        {description && <DialogDescription className="mb-2">{description}</DialogDescription>}
        <hr className="border-primary w-full my-2" />
        <div className="overflow-auto flex-grow">{children}</div>
      </DialogContent>
    </Dialog>
  );
}
