import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { AxiosRequestConfig } from 'axios';

const DashboardService = {
  getAlerts: async (location: string, signal?: AbortSignal) => {
    let url = `/alerts?location_id=${location}`;
    try {
      const config: AxiosRequestConfig = { signal };
      const response = await axios.get(url, config);
      return response.data;
    } catch (error: any) {
      if (error.name === 'CanceledError') {
        console.log('Request was canceled');
        return;
      }
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error fetching alerts';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  getSpeedTest: async (date: any, noCheck: boolean|null, signal?: AbortSignal) => {
    let url = `/alerts/speed-test?timestamp=${date}`;
    if (noCheck === true) {
      url += `&no_check=1`;
    }
    try {
      const config: AxiosRequestConfig = {
        responseType: 'blob',
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0'
        },
        signal
      };
      const response = await axios.get(url, config);
      return response.data;
    } catch (error: any) {
      if (error.name === 'CanceledError') {
        console.log('Request was canceled');
        return;
      }
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error testing';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  getSystemTest: async (date: any, signal?: AbortSignal) => {
    let url = `/alerts/system-test?timestamp=${date}`;
    try {
      const config: AxiosRequestConfig = { signal };
      const response = await axios.get(url, config);
      return response.data;
    } catch (error: any) {
      if (error.name === 'CanceledError') {
        console.log('Request was canceled');
        return;
      }
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error testing';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  getSpeedTransactionTest: async (date: any, signal?: AbortSignal) => {
    let url = `/alerts/speed-transaction-test?timestamp=${date}`;
    try {
      const config: AxiosRequestConfig = { signal };
      const response = await axios.get(url, config);
      return response.data;
    } catch (error: any) {
      if (error.name === 'CanceledError') {
        console.log('Request was canceled');
        return;
      }
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error testing';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  }
};

export default DashboardService;