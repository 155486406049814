import { cn } from '@/lib/utils';
import { Badge } from '@/common/presentation/components/registry/new-york/ui/badge';
import { ScrollArea } from '@/common/presentation/components/registry/new-york/ui/scroll-area';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { NoteI } from '../../../domain/note.domain';
import NoteService from '../../../infrastructure/services/NoteService';
import { fetchGetNotes, fetchRefreshNotes, setNoteId, setSelectedNotes } from '../../slices/NoteSlice';
import { useEffect, useState } from 'react';
import InfiniteScroll from '@/common/presentation/components/ui/infinite-scroll';
import { Loader2, ShieldAlert, Loader, Images } from 'lucide-react';
import { Checkbox } from '@/common/presentation/components/ui/checkbox';
import { } from 'lucide-react';
import { CrudState } from '@/modules/notes/domain/note.domain';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import { useTranslation } from 'react-i18next';

interface NoteListProps {
  items: NoteI[] | [];
  setCrudState: (crudState: CrudState) => void;
  setSize?: (sizes: number[]) => void;
  crudState: CrudState;
  scroll?: boolean;
}

export function NoteList({ items, setCrudState, crudState, scroll = true, setSize }: NoteListProps) {
  // Initial
  const { formatDate } = useDateHelpers();
  const { t } = useTranslation();

  // Redux
  const dispatch = useDispatch();
  const authUserId = useSelector((state: RootState) => state.auth.user?.id);
  const noteSelected = useSelector((state: RootState) => state.notes.noteId);
  const residents = useSelector((state: RootState) => state.residents.allResidents.residents);
  const selectedNotes = useSelector((state: RootState) => state.notes.selectedNotes);
  const metaNote = useSelector((state: RootState) => state.notes.notes?.meta);

  // Hooks
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isReadingNote, setIsReadingNote] = useState(false);
  const [loadingItemId, setLoadingItemId] = useState(null);

  useEffect(() => {
    if (metaNote && metaNote.page >= metaNote.lastPage) {
      setHasMore(false);
    }
  }, [metaNote]);

  //Behaviors
  const setNote = (noteId: string) => {
    dispatch(setNoteId(noteId));
  };

  const residentNames = residents.reduce((acc, item) => {
    acc[item.id] = item.first_name + ' ' + item.last_name;
    return acc;
  }, {});

  const unreadNote = async (noteId: any) => {
    setIsReadingNote(true);
    setLoadingItemId(noteId);

    await NoteService.unreadNote({
      note_id: noteId,
      status: 'read'
    });

    await dispatch<any>(fetchRefreshNotes());

    setIsReadingNote(false);
  };

  const fectchMoreNotes = async () => {
    setLoading(true);
    await dispatch<any>(fetchGetNotes());
    setLoading(false);
  };

  const handleCheckBoxNote = (noteId: string) => {
    dispatch(setSelectedNotes(noteId));
  };

  if (!items) {
    return;
  }

  return (
    <ScrollArea className="h-[calc(100vh-15rem)]">
      <div className="flex flex-col gap-2 p-4 pt-0">
        {items?.map((item) => (
          <div
            key={item.id}
            className={cn(
              'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all group hover:bg-accent cursor-pointer',
              noteSelected === item.id && 'bg-primary/10'
            )}
            onClick={() => {
              setCrudState(NoteHelperService.defineState({}));
              setNote(item.id);
              if (setSize) {
                setSize([0, 655]);
              }
            }}
          >
            <div className="flex w-full flex-col gap-1">
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <div className="font-semibold">
                    {item.resident_id ? residentNames[item.resident_id] : t('notes.everyone')}
                  </div>
                </div>
                <div
                  className={cn(
                    'ml-auto text-xs group-hover:hidden',
                    noteSelected === item.id ? 'text-foreground' : 'text-muted-foreground',
                    selectedNotes.includes(item.id) && 'hidden'
                  )}
                >
                  {formatDate(item.created_at)}
                </div>
                <div
                  className={cn(
                    'ml-auto text-xs hidden group-hover:inline-block',
                    selectedNotes.includes(item.id) && 'inline-block'
                  )}
                >
                  <div className="flex">
                    <samp className="mr-1">{t('notes.share')}</samp>
                    <Checkbox checked={selectedNotes.includes(item.id)} onClick={() => handleCheckBoxNote(item.id)} />
                  </div>
                </div>
              </div>
              <div className="flex justify-between">
                <div className="text-xs font-medium">{`${t('notes.by') + ' '}${item.author}`}</div>
                {item.images && item.images?.length > 0 && <Images className="w-5 h-5" />}
              </div>
            </div>
            <div className="text-xs text-muted-foreground text-ellipsis line-clamp-3" title={item.content} dangerouslySetInnerHTML={{ __html: item.html_content }}></div>
            <div className="flex  justify-between w-full">
              <div className="flex items-center">
                <Badge className="text-xs p-1" variant={'secondary'}>
                  {t('notes.' + item.visibility)}
                </Badge>
                {Boolean(item.urgent) && <ShieldAlert className="w-5 h-5 pl-1" />}
              </div>
              {item.created_by == authUserId ? (
                <Badge variant="default">{t('notes.author')}</Badge>
              ) : item.readers.filter((item: any) => item.user_id == authUserId).length > 0 &&
                item.readers.filter((item: any) => item.user_id == authUserId)[0].status == 'read' ? (
                <Badge variant="default">{t('notes.read')}</Badge>
              ) : (
                <Badge variant="destructive" onClick={() => unreadNote(item.id)}>
                  {loadingItemId === item.id ? <Loader className="mr-2 h-4 w-4 animate-spin" /> : t('notes.unread')}
                </Badge>
              )}
            </div>
          </div>
        ))}

        {scroll && (
          <InfiniteScroll hasMore={hasMore} isLoading={loading} next={fectchMoreNotes} threshold={1}>
            {hasMore && <Loader2 className="my-4 h-8 w-8 animate-spin" />}
          </InfiniteScroll>
        )}
      </div>
    </ScrollArea>
  );
}
