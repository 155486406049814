// import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

export const GetPicklist = async () => {
  const response = await axios.get('/staff/get-picklist');
  return response;
};
const StaffService = {
  createStaffMember: async (data: any) => {
    const response = await axios.post('/staff', { ...data });
    return response;
  },

  getStaffById: async (id: string) => {
    const response = await axios.get(`/residents/${id}`);
    return response;
  },
  getStaff: async (locationId?: string) => {
    const response = await axios.get(`/staff${locationId ? `?location_id=${locationId}` : ''}`);
    return response;
  },
  getStaffSelector: async (locationId: string = '', roles: string = '') => {
    const response = await axios.get(`/staff/get-staff-selector?locationId=${locationId}&roles=${roles}`);
    return response;
  },
  getStaffMemberById: async (id: string) => {
    const response = await axios.get(`/staff/${id}`, {
      params: {
        include_user_notes: true // Indicar al backend que incluya los datos de las notas del usuario
      }
    });
    return response;
  },
  updateCustomFieldsForm: async (data: any) => {
    const response = await axios.put(`/staff/custom-fields/${data.staff_member_id}`, { ...data });
    return response;
  },
  deleteProfileImage: async (staff_member_id: string) => {
    const response = await axios.delete(`/staff/staff-image/${staff_member_id}`);
    return response;
  },
  updateStaffPersonalInformation: async (data: any) => {
    const response = await axios.put(`/staff/personal-information/${data.staff_member_id}`, { ...data });
    return response;
  },
  updateStaffMemberTerminationForm: async (data: any) => {
    const response = await axios.put(`/staff/termination-information/${data.staff_member_id}`, { ...data });
    return response;
  },
  updateStaffcompanyForm: async (data: any) => {
    const response = await axios.put(`/staff/company-information/${data.staff_member_id}`, { ...data });
    return response;
  },
  updateStaffTrainingForm: async (data: any) => {
    const response = await axios.put(`/staff/training-information/${data.staff_member_id}`, { ...data });
    return response;
  },
  uploadStaffProfileImage: async (data: any) => {
    const formData = new FormData();
    formData.append('file', data.file);

    const response = await axios.post(`/staff/staff-image/${data.staff_member_id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response;
  },
  updateStaffnotesForm: async (data: any) => {
    const response = await axios.put(`/staff/notes-staff/${data.staff_member_id}`, { ...data });
    return response;
  },
  updateStaffloginForm: async (data: any) => {
    const response = await axios.put(`/staff/login-staff/${data.staff_id}`, { ...data });
    return response;
  },
  getStaffConfigurations: async () => {
    const response = await axios.get('/staff/configurations');
    return response;
  },
  postStaffConfigurations: async (data: any) => {
    const response = await axios.post('/staff/configurations', { ...data });
    return response;
  }
};

export default StaffService;
