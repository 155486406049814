import { Dialog, DialogContent, DialogTrigger } from '@/common/presentation/components/ui/dialog';

interface NoteImageProps {
  images: Array<{ image: string }>;
}

export function ImgageGrid({ images }: NoteImageProps) {
  const gridCols = images.length === 1 ? 'grid-cols-1' : images.length === 2 ? 'grid-cols-2' : 'grid-cols-3';

  return (
    <div className="flex w-max space-x-4 p-4">
      <div className={`grid ${gridCols} gap-4`}>
        {images.map((item, index) => (
          <Dialog key={index}>
            <DialogTrigger>
              <div>
                <img alt="Description" className="w-full h-full max-w-[90px] max-h-[90px] object-cover rounded-lg" src={item.image} />
              </div>
            </DialogTrigger>
            <DialogContent className="max-w-none w-[80%] h-[80%] bg-transparent flex items-center justify-center">
              <div className="w-full h-full max-w-full max-h-full flex items-center justify-center">
                <img alt="Description" src={item.image} className="object-contain max-w-full max-h-full rounded-lg" />
              </div>
            </DialogContent>
          </Dialog>
        ))}
      </div>
    </div>
  );
}
