import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { LucideIcon } from 'lucide-react';
import React from 'react';

interface IRowField {
  first_label: string;
  second_label: string;
  third_label?: string;
  value1: string;
  value2: string;
}

interface IProps {
  label: string;
  icon: LucideIcon;
  fields: IRowField[];
  onInputChange: (settingIndex: number, fieldIndex: number, inputName: 'value1' | 'value2', value: string) => void;
  indexAlert: number;
}

const VitalSetting: React.FC<IProps> = ({ label, fields, icon: Icon, onInputChange, indexAlert }) => {
  return (
    <div className="flex flex-col rounded-lg border p-4">
      <div className="flex flex-row gap-2 align-top justify-start items-center">
        <Icon className="text-primary" size={20} />
        <Label className="text-lg font-normal">{label}</Label>
      </div>
      <Separator className="my-4 border border-primary rounded-lg" />
      <div className="space-y-4 text-base">
        {fields.map((rowField, indexRowFields) => (
          <div key={indexRowFields} className="grid grid-cols-1 sm:grid-cols-6 items-center gap-2">
            <label className="sm:col-span-2">{rowField.first_label}</label>
            <Input
              onChange={(e) => {
                onInputChange(indexAlert, indexRowFields, 'value1', e.target.value);
              }}
              type="number"
              placeholder="0"
              className="border rounded-md px-2 py-1 w-full sm:w-16 sm:col-span-1"
            />
            <span className="sm:col-span-1">{rowField.second_label}</span>
            <Input
              type="number"
              onChange={(e) => {
                onInputChange(indexAlert, indexRowFields, 'value2', e.target.value);
              }}
              placeholder="0"
              className="border rounded-md px-2 py-1 w-full sm:w-16 sm:col-span-1"
            />
            {rowField.third_label && <span className="sm:col-span-1 pl-1">{rowField.third_label}</span>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VitalSetting;
