import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import Title from '../Title/Title';
import React, { ReactNode } from 'react';

interface IProps {
  title: string;
  description: string;
  children: ReactNode;
  button?: ReactNode;
};

const CustomCard: React.FC<IProps> = ({ title, description, children, button }) => {
  return (
    <Card className="col-span-3 border-t-4 border-t-primary/80">
      <CardHeader>
        <div className="flex items-center justify-between">
          <CardTitle className="text-lg font-bold">
            <Title>{title}</Title>
          </CardTitle>
         { button && (button)}
        </div>
        <div className="flex w-full items-center justify-between">
          <div>{description}</div>
        </div>
      </CardHeader>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CustomCard;
