import { Form, FormControl, FormField, FormItem, FormLabel } from '@/common/presentation/components/ui/form';
import { Switch } from '@/common/presentation/components/ui/switch';
import { toast } from '@/common/presentation/components/ui/use-toast';
import * as residentConfigurationsActions from '@/modules/residents/presentation/slices/residentConfigurationsSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

interface Props {
  values: Partial<FormValues>;
}

const formSchema = z.object({
  // level_of_care: z.boolean().optional(),
  // xxxx: z.boolean().optional(),
  religious_preferences: z.boolean().optional(),
  administrator_can_see: z.boolean().optional(),
  custom_fields: z.boolean().optional(),
  notes: z.boolean().optional(),
  discharge: z.boolean().optional()
});

type FormValues = z.infer<typeof formSchema>;

const defaultValues =  {
  level_of_care: false,
  xxxx: false,
  religious_preferences: false,
  administrator_can_see: false,
  custom_fields: false,
  notes: false,
  discharge: false
}

export const FormCards = ({ values }: Props) => {
  const { status } = useSelector((state: RootState) => state.residents.configurations);
  const dispatch = useDispatch<AppDispatch>();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange'
  });

  const onSubmit = async (data: FormValues) => {
    const response = await dispatch(
      residentConfigurationsActions.postResidentsConfigurations({
        resident_form_cards: data
      })
    );

    if (response.payload !== undefined) {
      toast({
        title: 'Form cards updated',
        variant: 'default'
      });
    }
  };

  
  useEffect(() => {
    form.reset(values);
  }, [values]);


  return (
    <>
      <div className="flex flex-col gap-4">
        <div>
          <h2 className="text-xl font-bold">Resident form cards</h2>
          <p>Here you can configure the residents form cards that will be displayed in the residents form page.</p>
        </div>

        <Form {...form}>
          <form
            onChange={form.handleSubmit(onSubmit)}
            className="flex flex-col w-full items-start justify-center gap-4"
          >
            <div className="flex flex-col gap-4 w-full">
              {/* <FormField
                control={form.control}
                name="level_of_care"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">Level Of Care</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="xxxx"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">XXXX</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              /> */}
              <FormField
                control={form.control}
                name="religious_preferences"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">Relogious Preferences</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="administrator_can_see"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">Administrator Can See</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="custom_fields"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">Custom Fields</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="notes"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">Notes</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="discharge"
                render={({ field }) => (
                  <FormItem className="flex items-center justify-between">
                    <FormLabel className="text-base">Discharge</FormLabel>
                    <FormControl>
                      <Switch checked={field.value} onCheckedChange={field.onChange} disabled={status === 'loading'} />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>
      </div>
    </>
  );
};
