import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@/common/presentation/components/ui/button';
import { Input } from '@/common/presentation/components/ui/input';
import TableCustomVirtoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import { cn } from '@/lib/utils';
import { Popover, PopoverContent, PopoverTrigger } from '@/common/presentation/components/ui/popover';
import { format, parse, isValid, isAfter, isBefore } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { Badge } from '@/common/presentation/components/ui/badge';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import columnsChart from './columnChartRX';

const ChartRX = ({ control, fields, onAddRow }) => {
  const { t } = useTranslation();
  const [localTableData, setLocalTableData] = useState([]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
    return isValid(parsedDate) ? format(parsedDate, 'dd/MM/yyyy') : '';
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());
    return isValid(parsedDate) ? format(parsedDate, 'yyyy-MM-dd') : null;
  };

  const isDateInRange = (startDate, endDate) => {
    const today = new Date();
    return isAfter(endDate, today) && isBefore(startDate, today);
  };

  useEffect(() => {
    if (fields && Array.isArray(fields) && fields.length > 0) {
      const processedData = fields.map((item) => ({
        ...item,
        filleDate: formatDate(item.filleDate),
        cycle_start_date: formatDate(item.cycle_start_date),
        cycle_end_date: formatDate(item.cycle_end_date),
        expiration_date: formatDate(item.expiration_date),
        status: item.status ?? true // Asignar un valor predeterminado si no existe
      }));
      setLocalTableData(processedData);
    } else {
      setLocalTableData(onAddRow ? [onAddRow] : []);
    }
  }, [fields, onAddRow]);
  

  const handleCellChange = useCallback(
    (index, columnKey, value) => {
      const updatedData = localTableData.map((row, i) => {
        if (i === index) {
          const newRow = { ...row, [columnKey]: value };

          // Validar si se está modificando la fecha de inicio o fin
          if (columnKey === 'cycle_start_date' || columnKey === 'cycle_end_date') {
            const startDate = parse(newRow.cycle_start_date, 'dd/MM/yyyy', new Date());
            const endDate = newRow.cycle_end_date ? parse(newRow.cycle_end_date, 'dd/MM/yyyy', new Date()) : null;
            const today = new Date();

            // Validar si la fecha de inicio es hoy y no hay fecha de fin
            const isStartDateToday = format(startDate, 'yyyy-MM-dd') === format(today, 'yyyy-MM-dd');

            if (isStartDateToday && !endDate) {
              newRow.status = true; // Activar el estado si la fecha de inicio es hoy y no hay fecha de fin
            } else if (!isDateInRange(startDate, endDate)) {
              newRow.status = false; // Desactivar el estado si las fechas no están en rango
            } else {
              newRow.status = true; // Mantener el estado activo si está en rango
            }
          }

          return newRow;
        }
        return row;
      });
      setLocalTableData(updatedData);
    },
    [localTableData]
  );

  const renderCellContent = (index, column, data) => {
    return (
      <td key={column.value} className={cn('px-4 py-2 whitespace-nowrap flex justify-center text-center')}>
        <Controller
          name={`chart.${index}.${column.value}`}
          control={control}
          render={({ field }) => {
            const value = field.value;

            if (column.type === 'checkbox') {
              const isActive = localTableData[index]?.status;

              return (
                <Badge
                  variant="outline"
                  className={cn(
                    'text-md font-bold',
                    isActive ? 'border-primary text-primary' : 'border-gray-500 text-gray-500'
                  )}
                >
                  {isActive ? 'Active' : 'Inactive'}
                </Badge>
              );
            } else if (column.type === 'calendar') {
              const formattedDate = value
                ? format(parse(value, 'yyyy-MM-dd', new Date()), t('dateFormats.dateFormat'))
                : t('medications.medicationForm.selectDate');

              return (
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant={'outline'}
                      className={cn('w-full justify-start text-left font-normal', !value && 'text-muted-foreground')}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {formattedDate}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <CustomCalendar
                      onChange={(selectedDate) => {
                        const formattedDate = format(selectedDate, 'dd/MM/yyyy');
                        field.onChange(parseDate(formattedDate));
                        handleCellChange(index, column.value, formattedDate);
                      }}
                      value={value ? parse(value, 'yyyy-MM-dd', new Date()) : new Date()}
                      name={`${column.value}-${index}`}
                      MonthAndYearPicker
                    />
                  </PopoverContent>
                </Popover>
              );
            } else {
              return (
                <Input
                  type={column.type === 'number' ? 'number' : 'text'}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleCellChange(index, column.value, e.target.value);
                  }}
                  style={{ width: '100px' }}
                />
              );
            }
          }}
        />
      </td>
    );
  };

  const handleSort = (columnKey) => {
    const sortedData = [...localTableData].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) return -1;
      if (a[columnKey] > b[columnKey]) return 1;
      return 0;
    });
    setLocalTableData(sortedData);
    setValue('chart', sortedData);
  };

  return (
    <TableCustomVirtoso
      columns={columnsChart}
      data={localTableData}
      renderCellContent={renderCellContent}
      onSort={handleSort}
      showSearchInput={false}
      additionalButtons={
        <Button className="bg-primary" onClick={onAddRow} size="sm">
          Add Row
        </Button>
      }
    ></TableCustomVirtoso>
  );
};

export default ChartRX;
