import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/common/presentation/components/ui/table';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import axios from 'axios';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import { Input } from '@/common/presentation/components/ui/input';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { Pencil, Trash, Ellipsis, CircleFadingPlus } from 'lucide-react';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import { Textarea } from "@/common/presentation/components/ui/textarea";
import { ImageCardTables } from '@/common/presentation/components/Selectors/ImageCardTables';

import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import internal from 'stream';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import RenderActionsTrackerDirectColumn from '../components/Direct/renderActionsTrackerDirectColumn';
import useTrackerDirectColumns from '../components/Direct/TrackerDirectColumns';
import FilterForm from '../components/Direct/FilterForm';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormSchema } from '../components/Direct/schema';
import { exportReportDirect } from '../slices/TrackerSlice';

import TrackerDirectType from './TrackerDirectType';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
export interface DirectFilterDataI {
    resident_id: string;
    resident?: string;
    location_id: string;
    page: number;
    per_page: number;
    date_range?: {
        from: string;
        to: string | null;
    } | null;
}

function TrackerDirect() {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [types, setTypes] = useState(null);
    const [dataReport, setDatareport] = useState(null);
    const dispatch = useDispatch();
    const { reportBlob, tracker } = useSelector((state: RootState) => state.tracker);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [selectedDirect, setSelectedDirect] = useState<string>(params.e || 'form');
    const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [inputValues, setInputValues] = useState({});
    
    const { t } = useTranslation();
    const onSelectDirect = (e: string) => {
        setParam('e', e);
        setSelectedDirect(e);
    };
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);
    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);
    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };
    const directColumns = useTrackerDirectColumns();
    const [dateRange, setDateRange] = useState({ from: '', to: '' });
    const { locationSelectedId } = useLocation();

    useEffect(() => {
        if (locationSelectedId && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        locationSelectedId === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);


    const setpTypes = async () => {
        const json = { location_id: locationSelectedId };
        const response = await TrackerService.getTrackerDirectType(json);
        if (response && response.data) {
            setTypes(JSON.parse(response.data));
        }

    };

    useEffect(() => {
        if (!types) {
            setpTypes();
        }
    }, [types]);

    const newColumns = () => {
        const firstObject = {
            label: t('trackers.type'),
            value: "type",
            type: "texto"
        };
        return [firstObject, ...residents.map((resident: { name: any; }) => ({
            label: (
                <div>
                    <ImageCardTables
                        item={{
                            id: resident.id,
                            firstName: resident.first_name,
                            lastName: resident.last_name,
                            image: resident.image_url
                        }}
                        aspectRatio={'rounded'}
                    />
                </div>
            ),
            value: resident.first_name,
            type: 'text'
        }))];
    };
    const columns = newColumns();
    const columnsreport = [
        { label: t('trackers.Date/Time'), value: 'date', type: 'date' },
        { label: t('trackers.Resident'), value: 'resident_name' },
        { label: t('trackers.Field'), value: 'type_name' },
        { label: t('trackers.Data'), value: 'value' },
        { label: t('trackers.Detail'), value: 'detail' },
        { label: t('trackers.Staff'), value: 'who_name' }
    ];


    const dataDirectReport = async () => {
        const json = { location_id: locationSelectedId };
        const response = await TrackerService.getTrackerDirect(json);
    
        if (response && Array.isArray(response) && response.length > 0) {
            let allData = [];
            response.forEach(item => {
                if (item && item.data) {
                    const parsedData = JSON.parse(item.data);
                    
                    // Log para depurar la estructura de los datos
                    //console.log('Parsed Data from Server:', parsedData);
    
                    allData = allData.concat(parsedData.map(dataItem => ({
                        ...dataItem,
                        created_at: item.created_at,
                        dataReportid: item.id
                    })));
                }
            });
            
            // Verifica la estructura de allData
            //console.log('Final allData:', allData);
            
            allData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setDatareport(allData);
        }
    };    

    useEffect(() => {
        if (!dataReport) {
            dataDirectReport();
        }
    }, [dataReport]);

    const [data, setData] = useState([]);

    const createData = (filteredReports) => {
        const columnField = types?.column_field || [];
        if (!types || !residents || !filteredReports) return [];
    
        const groupedData = [];
    
        residents.forEach((resident) => {
            // Busca el reporte para el residente filtrado
            const report = filteredReports.find(report => report.resident_id === resident.id);
    
            // Si no encuentra un reporte, no agrega ninguna fila
            if (!report) return;
    
            const row = {
                date: report ? new Date(report.date).toLocaleString() : 'N/A',
                resident_name: resident.first_name + ' ' + resident.last_name,
                type_name: report ? report.type_name || 'N/A' : 'N/A',
                value: report ? report.value || 'N/A' : 'N/A',
                detail: report ? (report.detail !== null ? report.detail : 'N/A') : 'N/A',
                who_name: report ? report.who_name || 'N/A' : 'N/A',
            };
    
            groupedData.push(row);
        });
    
        return groupedData;
    };       
      
    useEffect(() => {
        const newData = createData(dataReport);  // dataReport debería ser ya filtrado
        if (newData.length > 0) {
            setData(newData);
        } else {
            setData([]); // Vacía la tabla si no hay resultados
        }
    }, [types, dataReport, residents, inputValues]);

    const [pendingSubmit, setPendingSubmit] = useState(null);
    const handleInputChange = (e, typeId, residentId) => {
        const { name, value } = e.target;
        const intValue = value;
        setInputValues(prevValues => ({
            ...prevValues,
            [name]: intValue,
        }));
        if (pendingSubmit) {
            pendingSubmit.cancel();
        }
        const debouncedSubmit = debounce(() => {
            buttonSumbit({
                typeId: typeId,
                residentId: residentId,
                value: intValue
            });
        }, 500);
        setPendingSubmit(() => debouncedSubmit);
        debouncedSubmit();
    }

    const Fields = {
        'Edit': [
            // { fieldName: 'resident_name', label: 'Resident', type: 'hidden' },
            // { fieldName: 'type_name', label: 'Field', type: 'select' },
            { fieldName: t('trackers.Data'), label: 'Data', type: 'text' },
            { fieldName: t('trackers.Detail'), label: 'Detail', type: 'textarea' }
        ]
    };

    const [fields, setDialogField] = useState(false);

    const handleButtonOpenDialog = (fields: any[], title: string, row: any[], description: string,) => {
        setShowEditDialog(true);
        setFormValue(row);
        setDialogField(fields);
        setDialogTitle(title);
        setDialogDescription(description);
        setAction(title);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };


    const handleSubmit = async () => {
        const json: { [key: string]: any } = {};
        if (action === 'Edit') {
            json["id"] = formData.dataReportid;
            json["id_data"] = formData.id;
            json["data"] = {
                "type": formData.type,
                "detail": formData.detail,
                "value": formData.value,
                "resident_id": formData.resident_id,
                "date": formData.date
            };
            const response = await TrackerService.updateTrackerDirect(json);
        } else {
            json["id"] = formData.dataReportid;
            json["id_data"] = formData.id;
            const response = await TrackerService.destroyTrackerDirect(json);
        }
        dataDirectReport();
    };

    useEffect(() => {
        setpTypes();
        dataDirectReport();
    }, [locationSelectedId]);


    const [filters, setFilters] = useState({
        resident_name: '',
        type_name: ''
    });

    const buttonSumbit = async ({ typeId, residentId, value }: { typeId: string; residentId: string; value: BigInteger; }) => {
        const today = new Date().toISOString().split('T')[0];

        if (Array.isArray(dataReport)) {
            const foundReport = dataReport.find(report => {
                const reportDate = new Date(report.date).toISOString().split('T')[0];
                return report.type === typeId && report.resident_id === residentId && today === reportDate;
            });

            if (foundReport) {
                const json = {
                    id: foundReport.dataReportid,
                    id_data: foundReport.id,
                    data: {
                        type: typeId,
                        detail: foundReport.detail,
                        value: value,
                        resident_id: residentId,
                        date: foundReport.date
                    }
                };
                const response = await TrackerService.updateTrackerDirect(json);
                dataDirectReport();
                setData(createData());
            } else {
                const json = [{
                    type: typeId,
                    value: value,
                    resident_id: residentId,
                    detail: " ",
                    location_id: locationSelectedId
                }];
                const response = await TrackerService.saveTrackerDirect(json);
                dataDirectReport();
                setData(createData());
            }
        } else {
            const json = [{
                type: typeId,
                value: value,
                resident_id: residentId,
                detail: " ",
                location_id: locationSelectedId
            }];
            const response = await TrackerService.saveTrackerDirect(json);
            dataDirectReport();
            setData(createData());
        }
    };

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    };

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const renderInputForColumn = (column, index) => {

        switch (column.type) {
            case 'text':
                return (
                    <input
                        id={column.fieldName}
                        name={column.fieldName}
                        type="text"
                        value={formData[column.fieldName] || ''}
                        onChange={handleChange}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                    />
                );
            case 'textarea':
                return <textarea
                    id={column.fieldName}
                    name={column.fieldName}
                    value={formData[column.fieldName] || ''}
                    onChange={handleChange}
                    style={{ outline: 'none' }}
                    className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                />;
            case 'select':
                return (
                    <Select value={formData.type} onValueChange={(value) => handleSelectChange('type', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md ">
                            <SelectValue placeholder={column.label}>
                                {types.column_field.find((option) => option.id === formData.type)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {types.column_field.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            default:
                return null;
        }
    };

    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const handleSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction: direction });
    };

    const sortedData = useMemo(() => {
        if (sortConfig.key) {
            return [...dataReport].sort((a, b) => {
                if (sortConfig.key === 'date') {
                    const dateA = new Date(a[sortConfig.key]);
                    const dateB = new Date(b[sortConfig.key]);
                    if (dateA < dateB) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (dateA > dateB) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                } else {
                    if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
                    if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
                    return 0;
                }
            });
        }
        return dataReport;
    }, [dataReport, sortConfig]);

    const columnsWithActions = directColumns.map((column) => {
        if (column.key === 'actions') {
          return {
            ...column,
            render: (rowData: any) => (
              <RenderActionsTrackerDirectColumn row={rowData} handleButtonOpenDialog={handleButtonOpenDialog} />
            ),
          };
        }
        return column;
      });
      
      // Usa las columnas con las acciones
    const [columnsDirect, setColumns] = useState(columnsWithActions);
    const [sorting, setSorting] = useState({ key: '', direction: 'ascending' });
    
    const handleSortDirect = (columnKey, direction) => {
        setSorting({ key: columnKey, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[columnKey] < b[columnKey]) return direction === 'ascending' ? -1 : 1;
            if (a[columnKey] > b[columnKey]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setData(sortedData);
    };

    useEffect(() => {
        //console.log("Data:", createData());
        setData(createData());
    }, [types, dataReport, residents, inputValues]);
    
    useEffect(() => {
        //console.log("ColumnsDirect:", columnsDirect);
    }, [columnsDirect]);

    const renderCellContent = (index: number, column: Column, data: any[]) => {
        const rowData = data[index];
      
        // Revisa si los datos están llegando correctamente
        //console.log('Rendering Cell Content:', rowData, column.key);
      
        switch (column.key) {
          case 'resident_name':
            return <span>{rowData.resident_name}</span>;
          case 'date':
            return <span>{rowData.date}</span>;
          case 'type_name':
            return <span>{rowData.type_name}</span>;
          case 'value':
            return <span>{rowData.value}</span>;
          case 'actions':
            return (
              <RenderActionsTrackerDirectColumn 
                row={rowData}
                handleButtonOpenDialog={handleButtonOpenDialog} 
              />
            );
          default:
            return <span>{rowData[column.key]}</span>;
        }
      };
      
      const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema)
    });

    const [loading, setLoading] = useState(false); // Para mostrar loading
    const [rowsPerPage, setRowsPerPage] = useState(20);
    
    const fetchData = (filterData: DirectFilterDataI, page: number, rowsPerPage: number) => {
        const { date_range, resident_id, location_id } = filterData;
    
        // Filtra los datos según el rango de fechas y otros criterios
        const filteredReports = dataReport.filter((report) => {
            const reportDate = new Date(report.date);
            const from = new Date(date_range?.from || '1970-01-01');
            const to = new Date(date_range?.to || '2100-12-31');
    
            const matchesResident = !resident_id || report.resident_id === resident_id;
            const matchesLocation = !location_id || report.location_id === location_id;
    
            // Verifica si el reporte cae dentro del rango de fechas y cumple los otros filtros
            return reportDate >= from && reportDate <= to && matchesResident && matchesLocation;
        });
    
        //console.log('Filtered Reports:', filteredReports); // Verifica que el filtro funcione correctamente
    
        if (filteredReports.length === 0) {
            setData([]); // Si no hay reportes, vacía la tabla
            return;
        }
    
        // Actualizamos los datos de la tabla basados en los datos filtrados
        const newData = createData(filteredReports);
        //console.log('New Data:', newData); // Verifica que los datos generados para la tabla sean correctos
        setData(newData);
    };    

    const filter = async (data: z.infer<typeof FormSchema>) => {
        try {
            const { date_range, resident } = data;
            const filterData: DirectFilterDataI = {
                date_range: {
                    from: date_range?.from?.toISOString().split('T')[0] ?? '',
                    to: date_range?.to?.toISOString().split('T')[0] ?? ''
                },
                page: 1,
                per_page: 20,
                resident_id: params.r,
                location_id: locationSelectedId,
                resident: resident
            };
    
            // Guardar las fechas en el estado
            setDateRange({
                from: filterData.date_range.from,
                to: filterData.date_range.to
            });
    
            // Llamar a fetchData para aplicar el filtro
            await fetchData(filterData, 1, rowsPerPage);
            
            //console.log('Filter applied:', filterData);
        } catch (error) {
            //console.error('Error filtering', error);
        }
    };

    const cleanFilter = () => {
        form.reset({ resident: '', date_range: null });
    };

    const lastFilters: DirectFilterDataI = {
        date_range: { from: '', to: '' },
        resident_id: params.r,
        resident: undefined,
        location_id: locationSelectedId,
        per_page: 20,
        page: 1
    };

    const generateReportPdf = async (): Promise<void> => {
        try {
            if (!dateRange.from || !dateRange.to) {
                //console.error('No date range selected');
                return;
            }

            // Obtener el token almacenado
            const token = localStorage.getItem('access_token'); // Asumiendo que guardaste el token en localStorage
    
            const formData = {
                datestart: dateRange.from,
                datesend: dateRange.to,    
                resident_id: params.r,
                location_id: locationSelectedId
            };
    
            // Despachar la acción de Redux para exportar el reporte
            await dispatch(exportReportDirect({ formData, token }));
    
            // Si la exportación fue exitosa, abrir el PDF
            if (status === 'idle' && reportBlob) {
                const blob = new Blob([reportBlob], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url); // Abrir el PDF en una nueva pestaña
            }
        } catch (error) {
            //console.error('Error generating report:', error);
        }
    };

    useEffect(() => {
        if (loading) {
            //console.log('Generating PDF report...');
        }
    }, [loading]);

    return (
        <div>
            <LocationSelector quickView={true} allLocations={false} />

            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{ color: accentColor }}>{t('trackers.trackerdirect.title')}</div>
                            <div className="font-semibold text-1xl">{t('trackers.trackerdirect.description')}</div>
                        </div>

                        <Tabs defaultValue={selectedDirect} onValueChange={(e) => { onSelectDirect(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t('trackers.Form')}
                                </TabsTrigger>
                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>
                                <TabsTrigger value="type" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Type')}
                                </TabsTrigger>
                            </TabsList>

                        </Tabs>
                    </div>

                )
            }

            {
                selectedDirect === 'form' && (
                    <div>
                        {
                            locationSelectedId && (
                                <div className="flex flex-col gap-4">
                                    <div>
                                        <ResidentSelector
                                            onSelect={onResidentSelect}
                                            locationId={location}
                                            residentId={params.r}
                                            showAllResidents={false}
                                        />
                                    </div>
                                    <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                        <Table className="shadow-lg overflow-hidden">
                                            <TableHeader className="text-center">
                                                <TableRow className="py-2 px-4 text-center bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                    {columns.map((column, index) => (
                                                        <TableHead
                                                            className={`text-${index === 0 ? 'left' : 'center'} text-white`}
                                                            key={column.value}
                                                        >
                                                            {column.label}
                                                        </TableHead>
                                                    ))}
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody className="bg-white dark:bg-background">
                                                {data.map((row, index) => (
                                                    <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800 border-b border-gray-300">
                                                        {columns.map((column, columnIndex) => (
                                                            <TableCell key={columnIndex} className={`py-4 px-4 ${columnIndex !== 0 && 'border-l border-gray-300'}`} >
                                                                {row[column.value as keyof typeof row]}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                    <div>
                                        <div className="flex items-center justify-between px-2">
                                            <div className="flex-1 text-sm text-muted-foreground">
                                                {data.length} of {data.length} row(s) selected.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-end mb-4">
                                        <button className="p-2 bg-blue-500 text-white rounded" onClick={() => window.location.reload()}>Save Quick Tracker</button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }

            {
                selectedDirect === 'report' && (
                    <div>
                        <div>
                            <ResidentSelector
                                onSelect={onResidentSelect}
                                locationId={location}
                                residentId={params.r}
                                showAllResidents={false}
                            />
                        </div>
                        <br/>
                        <Card className="border-t-4 border-t-primary/50">
                            <CardHeader>
                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.trackerdirectreport.title')}</CardTitle>
                                <div className="flex w-full items-center justify-between">
                                    <div>
                                        {t('trackers.trackerdirectreport.description')}
                                    </div>
                                </div>
                            </CardHeader>
                            <CardContent>
                                <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                    <FilterForm
                                    form={form}
                                    onFilter={filter}
                                    onCleanFilter={cleanFilter}
                                    generateReport={generateReportPdf}
                                    />
                                    <TableCustomVirtuoso
                                    data={data} // Los datos adaptados con createData
                                    columns={[
                                        { key: 'resident_name', labelTranslationKey: 'Resident Name', sortable: true },
                                        { key: 'date', labelTranslationKey: 'Date', sortable: true },
                                        { key: 'type_name', labelTranslationKey: 'Type', sortable: true },
                                        { key: 'value', labelTranslationKey: 'Value', sortable: true },
                                        { key: 'actions', labelTranslationKey: 'Actions' }
                                    ]}
                                    renderCellContent={renderCellContent}
                                    onSort={handleSortDirect}
                                    showSearchInput = {false}
                                    />
                                    <div>
                                        <div className="flex items-center justify-between px-2">
                                            <div className="flex-1 text-sm text-muted-foreground">
                                                {dataReport && dataReport.length} of {dataReport && dataReport.length} row(s) selected.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )
            }
                {
                selectedDirect === 'type' && (
                    <div>
                        {
                           <div>
                            <TrackerDirectType setpTypesDirect={setpTypes}/>
                           </div>
                        }
                    </div>
                )
            }
        </div >
    )
}

export default TrackerDirect;
