import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { InitialStateI, NotesStructureI, DraftsStructureI } from '../../domain/note.domain';
import NoteService from '../../infrastructure/services/NoteService';
import { RootState } from '@/store/store';


const initialNotes: NotesStructureI = {
  result: "",
  payload: [],
  meta: []
};

const initialDrafts: DraftsStructureI = {
  data: [],
  current_page: 0,
  last_page: 0,
};

const initialState: InitialStateI = {
  noteId: '',
  notes: initialNotes,
  categories: [],
  noteFilters: [],
  selectedNotes: [],
  drafts: initialDrafts,
  staffNotes: initialNotes,
  quickNotes: []
};


export const fetchGetNotes = createAsyncThunk(
  'notes/get',
  async (page: number = 0, { getState }) => {
    const state = getState() as RootState;
    const noteFilters = state.notes.noteFilters;
    const updatedFilters = [...noteFilters];

    updatedFilters.push(['nextPage', 1]);

    if (state.notes.notes.payload.length > 0) {
      updatedFilters.push(['nextPage', Number(state.notes.notes.meta.page) + 1]);
    }

    if (page > 0) {
      updatedFilters.push(['nextPage', page]);
    }

    const response = await NoteService.getNotes(updatedFilters);
    return response;
  }
);

export const fetchFilteredNotes = createAsyncThunk('filteredNotes/get', async (filters: any) => {
  const response = await NoteService.getFilteredNotes(filters);
  return response;
});

export const fetchRefreshNotes = createAsyncThunk(
  'notes/refresh',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const noteFilters = state.notes.noteFilters;
    const updatedFilters = [...noteFilters];
    updatedFilters.push(['refresh', 'true']);
    updatedFilters.push(['nextPage', Number(state.notes.notes.meta.page ?? 1)]);
    const response = await NoteService.getNotes(updatedFilters);
    return response;
  }
);

export const fetchGetNotesCategories = createAsyncThunk('categories/get', async (_, { getState }) => {
  const response = await NoteService.getCategories();
  return response;
});

export const fetchGetQuickNotes = createAsyncThunk('quickNotes/get', async (_, { getState }) => {
  const response = await NoteService.getQuickNotes();
  return response;
});

export const fetchGetDrafts = createAsyncThunk(
  'notes/drafts',
  async (_, { getState }) => {
    const state = getState() as RootState;

    let draftFilters = [];

    if (state.notes.drafts.current_page) {
      draftFilters.push(['nextPage', Number(state.notes.drafts.current_page) + 1]);
    } else {
      draftFilters.push(['nextPage', 1]);
    }

    const response = await NoteService.getDrafts(draftFilters);
    return response;
  }
);

export const fetchrefreshDrafts = createAsyncThunk(
  'notes/refreshDrafts',
  async (_, { getState }) => {
    const response = await NoteService.getDrafts([['nextPage', 1]]);
    return response;
  }
);

export const fetchGetStaffNotes = createAsyncThunk(
  'notes/staff',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const noteFilters = state.notes.noteFilters;
    const updatedFilters = noteFilters.filter(
      filter => (filter[0] === 'search' || filter[0] === 'location')
    );

    if (state.notes.staffNotes.payload.length > 0) {
      updatedFilters.push(['nextPage', Number(state.notes.staffNotes.meta.page) + 1]);
    } else {
      updatedFilters.push(['nextPage', 1]);
    }

    updatedFilters.push(['target_type', 'staff']);

    const response = await NoteService.getNotes(updatedFilters);
    return response;
  }
);

export const fetchRefreshStaffNotes = createAsyncThunk(
  'notes/staffRefresh',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const noteFilters = state.notes.noteFilters;
    const updatedFilters = noteFilters.filter(
      filter => (filter[0] === 'search' || filter[0] === 'location')
    );


    if (state.notes.staffNotes.payload.length > 0) {
      updatedFilters.push(['nextPage', Number(state.notes.staffNotes.meta.page)]);
    } else {
      updatedFilters.push(['nextPage', 1]);
    }

    updatedFilters.push(['nextPage', 1]);

    updatedFilters.push(['refresh', 'true']);
    updatedFilters.push(['target_type', 'staff']);
    const response = await NoteService.getNotes(updatedFilters);
    return response;
  }
);

export const fetchRemoveNote = createAsyncThunk(
  'notes/removeNote',
  async (noteId: string, { getState }) => {
    const response = await NoteService.removeNote(noteId);
    return response;
  }
);

const NoteSlice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    setNoteId(state, action) {
      state.noteId = action.payload;
    },
    cleanNotes(state) {
      state.notes = initialNotes;
    },
    setNoteFilters(state, action) {
      const [key, value] = action.payload;

      const existingFilter = state.noteFilters.findIndex(filter => filter[0] === key);
      if (existingFilter >= 0) {
        state.noteFilters[existingFilter][1] = value;
      } else {
        state.noteFilters.push([key, value]);
      }
    },
    removeNoteFilter(state, action) {
      const key = action.payload;
      state.noteFilters = state.noteFilters.filter(filter => filter[0] !== key);
    },
    cleanFilters(state) {
      state.noteFilters = [];
    },
    setSelectedNotes(state, action) {
      const noteId = action.payload;
      const index = state.selectedNotes.indexOf(noteId);

      if (index > -1) {
        state.selectedNotes.splice(index, 1);
      } else {
        state.selectedNotes.push(noteId);
      }
    },
    resetState(state) {
      return {
        ...initialState,
        noteFilters: state.noteFilters,
      };
    },
    resetSelectedNote(state) {
      state.selectedNotes = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGetNotes.fulfilled, (state, action) => {
      if (Number(action.payload.meta.page) >= 2) {
        state.notes.payload = state.notes.payload.concat(action.payload.payload);
        state.notes.meta.page = action.payload.meta.page;
        state.notes.meta.lastPage = action.payload.meta.lastPage;
      } else {
        state.notes = action.payload;
      }
    });

    builder.addCase(fetchRefreshNotes.fulfilled, (state, action) => {
      let page = state.notes.meta.page;
      state.notes = action.payload;
      state.notes.meta.page = page;
    });

    builder.addCase(fetchGetNotesCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });

    builder.addCase(fetchGetQuickNotes.fulfilled, (state, action) => {
      state.quickNotes = action.payload;
    });

    builder.addCase(fetchrefreshDrafts.fulfilled, (state, action) => {
      state.drafts = action.payload;
    });

    builder.addCase(fetchGetDrafts.fulfilled, (state, action) => {
      if (Number(action.payload.current_page) >= 2) {
        state.drafts.current_page = action.payload.current_page;
        state.drafts.data = state.drafts.data.concat(action.payload.data);
      } else {
        state.drafts = action.payload;
      }
    });

    builder.addCase(fetchGetStaffNotes.fulfilled, (state, action) => {

      if (Number(action.payload.meta.page) >= 2) {
        state.staffNotes.payload = state.staffNotes.payload.concat(action.payload.payload);
        state.staffNotes.meta.page = action.payload.meta.page;
        state.staffNotes.meta.lastPage = action.payload.meta.lastPage;
      } else {
        state.staffNotes = action.payload;
      }
    });

    builder.addCase(fetchRefreshStaffNotes.fulfilled, (state, action) => {
      let page = state.staffNotes.meta.page;
      state.staffNotes = action.payload;
      state.staffNotes.meta.page = page;
    });

    builder.addCase(fetchFilteredNotes.fulfilled, (state, action) => {
    });

    builder.addCase(fetchRemoveNote.fulfilled, (state, action) => {
    });
  }
});

export const { setNoteId, cleanNotes, setNoteFilters, removeNoteFilter, cleanFilters, setSelectedNotes, resetState, resetSelectedNote } = NoteSlice.actions;
export default NoteSlice.reducer;
