// import { Button } from '@/common/presentation/components/ui/button';
// import { cn } from '@/lib/utils';
// import { ChevronDown, ChevronUp } from 'lucide-react';
// import { useEffect, useRef, useState } from 'react';

// interface TimePickerProps {
//   onChange: (time: string) => void;
//   value: string;
// }

// export const TimePicker = ({ onChange, value }: TimePickerProps) => {
//   const [time, setTime] = useState(value);
//   const [isReady, setIsReady] = useState(false);

//   const [hour, minute] = time.split(':');
//   const [period, setPeriod] = useState<AmPmType>(convert24to12(hour)[1] as AmPmType);

//   const handleTimeChange = (value: number, type: 'hour' | 'minute') => {
//     if (isReady) {
//       if (type === 'hour') {
//         setTime(`${convert12to24(value, period)}:${minute}`);
//       } else {
//         setTime(`${hour}:${value}`);
//       }
//     }
//   };

//   const handlePeriodChange = (value: AmPmType) => {
//     if (isReady) {
//       setTime(`${convert12to24(hour, value)}:${minute}`);
//       setPeriod(value);
//     }
//   };

//   useEffect(() => {
//     !isReady && setIsReady(true);

//     if (isReady) {
//       onChange?.(time);
//     }
//   }, [onChange, time]);

//   return (
//     <>
//       <div className="rounded-md py-4 w-full flex flex-row justify-center items-center gap-2">
//         <TimeInput onChange={(value) => handleTimeChange(value, 'hour')} max={12} min={1} value={convert24to12(hour)} />
//         <span className="text-2xl">:</span>
//         <TimeInput onChange={(value) => handleTimeChange(value, 'minute')} value={+minute} />
//         <Period onChange={(value) => handlePeriodChange(value)} period={period} />
//       </div>
//     </>
//   );
// };

// interface TimeInputProps {
//   max?: number;
//   min?: number;
//   onChange: (value: number) => void;
//   value: number | [number, AmPmType];
// }

// const TimeInput = ({ max = 59, min = 0, onChange, value = 0 }: TimeInputProps) => {
//   const inputRef = useRef<HTMLInputElement>(null);

//   const [time, setTime] = useState<number>(Array.isArray(value) ? value[0] : value);

//   const formatTime = (value: number) => {
//     return value < 10 ? `0${value}` : value;
//   };

//   const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const value = Number(e.target.value);

//     if (inputRef.current?.value.length === 3 && inputRef.current?.value.startsWith('0')) {
//       inputRef.current.value = inputRef.current.value.slice(1);
//     }

//     setTime(value);
//   };

//   const incrementTime = () => {
//     setTime((prev) => {
//       const newValue = prev + 1;
//       return newValue <= max ? newValue : min;
//     });
//   };

//   const decrementTime = () => {
//     setTime((prev) => {
//       const newValue = prev - 1;
//       return newValue >= min ? newValue : max;
//     });
//   };

//   useEffect(() => {
//     onChange?.(time);
//   }, [time]);

//   return (
//     <>
//       <div className="bg-muted h-[90px] rounded-md flex flex-col items-center justify-center hover:bg-primary/10">
//         <ChevronUp className="p-1 hover:cursor-pointer" onClick={incrementTime} role="button" />
//         <input
//           ref={inputRef}
//           style={{
//             'appearance': 'none',
//             'WebkitAppearance': 'none',
//             'MozAppearance': 'textfield'
//           }}
//           type="number"
//           inputMode="numeric"
//           min={min}
//           max={max}
//           className="w-12 bg-transparent text-center text-xl focus-visible:outline-0"
//           value={formatTime(time)}
//           onChange={handleTimeChange}
//         />
//         <ChevronDown className="p-1 hover:cursor-pointer" onClick={decrementTime} role="button" />
//       </div>
//     </>
//   );
// };

// interface PeriodProps {
//   period?: AmPmType;
//   onChange: (period: AmPmType) => void;
// }

// const Period = ({ period = 'am', onChange }: PeriodProps) => {
//   const periods = ['am', 'pm'];
//   const buttonCommonClass = 'w-16 bg-muted/50 text-black text-xs rounded-md hover:bg-primary/40 hover:text-white';

//   const getButtonClass = (p: string) => {
//     return cn(buttonCommonClass, period === p && 'bg-primary text-white');
//   };

//   return (
//     <>
//       <div className="flex flex-col gap-2">
//         {periods.map((p) => (
//           <Button key={p} className={getButtonClass(p)} onClick={() => onChange(p as AmPmType)}>
//             {p.toUpperCase()}
//           </Button>
//         ))}
//       </div>
//     </>
//   );
// };

// export type AmPmType = 'am' | 'pm';

// const convert12to24 = (hour12: string | number, amPm: AmPmType): number => {
//   let hour24 = Number(hour12);

//   if (amPm === 'am' && hour24 === 12) {
//     hour24 = 0;
//   } else if (amPm === 'pm' && hour24 < 12) {
//     hour24 += 12;
//   } else if (amPm === 'am' && hour24 > 12) {
//     hour24 -= 12;
//   }

//   return hour24;
// };

// const convert24to12 = (hour24: string | number): [number, AmPmType] => {
//   const hour12 = Number(hour24) % 12 || 12;
//   return [hour12, Number(hour24) < 12 ? 'am' : 'pm'];
// };

import { useEffect, useState, useRef } from 'react';
import { Button } from '@/common/presentation/components/ui/button';
import { cn } from '@/lib/utils';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface TimePickerProps {
  onChange: (time: string) => void;
  value: string;
}

export const TimePicker = ({ onChange, value }: TimePickerProps) => {
  const [hour24, setHour24] = useState<number>(() => {
    const [hour] = value.split(':');
    return Number(hour);
  });
  const [minute, setMinute] = useState<number>(() => {
    const [, minute] = value.split(':');
    return Number(minute);
  });

  const [period, setPeriod] = useState<AmPmType>(() => {
    return hour24 >= 12 ? 'pm' : 'am';
  });

  // Sincroniza el estado local cuando cambia la prop 'value'
  useEffect(() => {
    const [hourStr, minuteStr] = value.split(':');
    const hourNum = Number(hourStr);
    const minuteNum = Number(minuteStr);
    setHour24(hourNum);
    setMinute(minuteNum);
    setPeriod(hourNum >= 12 ? 'pm' : 'am');
  }, [value]);

  // Notifica al padre cuando cambian la hora o los minutos
  useEffect(() => {
    const formattedHour = hour24.toString().padStart(2, '0');
    const formattedMinute = minute.toString().padStart(2, '0');
    onChange(`${formattedHour}:${formattedMinute}`);
  }, [hour24, minute, onChange]);

  const handleHourChange = (hour12: number) => {
    const newHour24 = convert12to24(hour12, period);
    setHour24(newHour24);
  };

  const handleMinuteChange = (newMinute: number) => {
    setMinute(newMinute);
  };

  const handlePeriodChange = (newPeriod: AmPmType) => {
    const hour12 = convert24to12(hour24)[0];
    const newHour24 = convert12to24(hour12, newPeriod);
    setPeriod(newPeriod);
    setHour24(newHour24);
  };

  return (
    <div className="rounded-md py-4 w-full flex flex-row justify-center items-center gap-2">
      <TimeInput onChange={handleHourChange} max={12} min={1} value={convert24to12(hour24)[0]} />
      <span className="text-2xl">:</span>
      <TimeInput onChange={handleMinuteChange} max={59} min={0} value={minute} />
      <PeriodSelector onChange={handlePeriodChange} period={period} />
    </div>
  );
};

interface TimeInputProps {
  max: number;
  min: number;
  onChange: (value: number) => void;
  value: number;
}

const TimeInput = ({ max, min, onChange, value }: TimeInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const formatTime = (value: number) => value.toString().padStart(2, '0');

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = Number(e.target.value);
    if (isNaN(newValue)) return;
    if (newValue > max) newValue = max;
    if (newValue < min) newValue = min;
    onChange(newValue);
  };

  const incrementTime = () => {
    onChange(value < max ? value + 1 : min);
  };

  const decrementTime = () => {
    onChange(value > min ? value - 1 : max);
  };

  return (
    <div className="bg-muted h-[90px] rounded-md flex flex-col items-center justify-center hover:bg-primary/10">
      <ChevronUp className="p-1 cursor-pointer" onClick={incrementTime} role="button" />
      <input
        ref={inputRef}
        type="number"
        inputMode="numeric"
        min={min}
        max={max}
        className="w-12 bg-transparent text-center text-xl focus-visible:outline-0"
        value={formatTime(value)}
        onChange={handleTimeChange}
      />
      <ChevronDown className="p-1 cursor-pointer" onClick={decrementTime} role="button" />
    </div>
  );
};

interface PeriodSelectorProps {
  period: AmPmType;
  onChange: (period: AmPmType) => void;
}

const PeriodSelector = ({ period, onChange }: PeriodSelectorProps) => {
  const periods: AmPmType[] = ['am', 'pm'];
  const buttonCommonClass = 'w-16 bg-muted/50 text-black text-xs rounded-md hover:bg-primary/40 hover:text-white';

  const getButtonClass = (p: AmPmType) => cn(buttonCommonClass, period === p && 'bg-primary text-white');

  return (
    <div className="flex flex-col gap-2">
      {periods.map((p) => (
        <Button key={p} className={getButtonClass(p)} onClick={() => onChange(p)}>
          {p.toUpperCase()}
        </Button>
      ))}
    </div>
  );
};

export type AmPmType = 'am' | 'pm';

const convert12to24 = (hour12: number, amPm: AmPmType): number => {
  let hour24 = hour12 % 12;
  if (amPm === 'pm') {
    hour24 += 12;
  }
  return hour24;
};

const convert24to12 = (hour24: number): [number, AmPmType] => {
  const period: AmPmType = hour24 >= 12 ? 'pm' : 'am';
  const hour12 = hour24 % 12 || 12;
  return [hour12, period];
};
