import { EmarContent } from '@/modules/settings/presentation/components/menus/emar/EmarSettings';
import { ResidentContent } from '@/modules/settings/presentation/components/menus/residents/ResidentsSettings';
import { StaffContent, StaffTab } from '@/modules/settings/presentation/components/menus/Staff/StaffSettings';
import { UserRoundCog, Users, Home, Activity, ArrowLeft, ListChecks, NotepadText } from 'lucide-react';
import { TrackerCleaningAreaTask } from '@/modules/tracker/presentation/components/cleaning/TrackerCleaningAreaTask';
import { EnabledTrackers } from "@/modules/settings/presentation/components/menus/trackers/presentation/components/EnabledTrackers";
import { AllowStaffEditMedication } from "@/modules/settings/presentation/components/menus/medications/AllowStaffEditMedication";

import { Module } from './interfaceModules'

export const modules: Module[] = [
    { key: 'residents', label: 'Residents', component: <ResidentContent />, icon: <Users className='max-w-[22px]' /> },
    { key: 'staff', label: 'Staff', component: <StaffContent />, item: <StaffTab />, icon: <UserRoundCog className='max-w-[22px]' /> },
    { key: 'emar', label: 'Emar', component: <EmarContent />, icon: null },
    {
      key: 'medications', 
      label: 'Medications', 
      icon: <ListChecks /> ,
      subModules:[
        {
          key: "enabledMedications",
          label: "Staff allowed to make changes",
          icon: <ListChecks />,
          component: <AllowStaffEditMedication />
        }
      ]
    },
    {
      key: 'trackers',
      label: 'Trackers',
      icon: <Activity />,
      subModules: [
        {
          key: "cleaning",
          label: "Cleaning",
          icon: <NotepadText />,
          isRequiredLocation: true,
          component: <TrackerCleaningAreaTask key='trackerCleaningArea' />
        },
        {
          key: "enabledTrackers",
          label: "Enabled Trackers",
          icon: <ListChecks />,
          component: <EnabledTrackers  />
        }
      ]
    }
  ];