import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TrackerService from '../../infrastructure/services/TrackerService';

interface ITrackerHygieneState {
    hygienes: any[];
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
}

const initialState: ITrackerHygieneState = {
  hygienes: [],
    status: 'idle',
    error: null
};

export const getHygiene = createAsyncThunk("trackerHygiene/getHygiene", async (body: any) => {
    const response = await TrackerService.getTrackerHygiene(body);
    return response;
});

export const saveHygiene = createAsyncThunk("trackerHygiene/saveHygiene", async (body: any) => {
  const response = await TrackerService.saveTrackerHygiene(body);
  return response;
});

export const updateHygiene = createAsyncThunk("trackerHygiene/updateHygiene", async (body: any) => {
  const response = await TrackerService.updateTrackerHygiene(body);
  return response.data;
});

const trackerHygiene = createSlice({
  name: 'trackerHygiene',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getHygiene.pending, (state) => {
      state.status = 'loading';
      state.error = null;
    });
    
    builder.addCase(getHygiene.fulfilled, (state, action) => {
      state.status = 'idle';
      state.hygienes = action.payload;
    });

    builder.addCase(getHygiene.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || null;
    });
  }
});

export default trackerHygiene.reducer;
