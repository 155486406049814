import React from 'react';
import { Button } from '@/common/presentation/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { Pencil, BookCopy, Ellipsis, FolderArchive } from 'lucide-react';
import { Row } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { UserRolPermissions } from "@/utils/helpers/permissions.helper"
import { RootState } from '@/store/store';
import { useSelector } from 'react-redux';

interface RenderActionsColumnProps {
  rowData: Row<any>;
  handleEdit: (id: string) => void;
  handleCopy: (id: string) => void;
  handleAudit: (id: string) => void;
}

const RenderActionsColumn: React.FC<RenderActionsColumnProps> = ({ rowData, handleEdit, handleCopy, handleAudit }) => {
  const { t } = useTranslation();
  const { permissions } = useSelector((state: RootState) => state.auth.user);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="flex h-8 w-8 p-0 data-[state=open]:bg-muted">
          <Ellipsis className="h-4 w-4" />
          <span className="sr-only">{t('medications.columnsResidentMedications.render.open')}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        
        {UserRolPermissions("medications", ["edit"], permissions) && (
          <DropdownMenuItem onClick={() => handleEdit(rowData.id)}>
            <Pencil className="h-4 w-4 mr-2" /> {t('medications.columnsResidentMedications.render.edit')}
          </DropdownMenuItem>
        )}
        
        <DropdownMenuItem onClick={() => handleCopy(rowData.id)}>
          <BookCopy className="h-4 w-4 mr-2" /> {t('medications.columnsResidentMedications.render.duplicate')}
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleAudit(rowData.id)}>
          <FolderArchive className="h-4 w-4 mr-2" /> {t('medications.columnsResidentMedications.render.audit')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default RenderActionsColumn;
