import React, { useCallback, useState, useEffect } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { cn } from '@/lib/utils';
import { ArrowDownUp, ArrowUpDown, Filter, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { TableViewOptions } from './TableViewOption';
import { useNavigate } from 'react-router-dom';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { SplashScreen } from '../SplashScreen/SplashScreen';
import { Skeleton } from '../ui/skeleton';

interface Column {
  key: string;
  labelTranslationKey: string;
  numeric?: boolean;
  render?(data: any): React.ReactNode;
  visible?: boolean;
  width?: string;
  editable?: boolean;
  sortable?: boolean;
  static?: boolean;
}

interface Props {
  additionalButtons: React.ReactNode;
  filters?: React.ReactNode;
  data: any[];
  columns: Column[];
  renderCellContent: (index: number, column: Column, data: any[]) => React.ReactNode;
  onSort: (columnKey: string, direction: 'ascending' | 'descending') => void;
  rowClickPath?: (data: any) => string;
  loadMore: () => void;
  hasMore: boolean;
  onFilterChange?: (filterValue: string) => void;
  isLoading: boolean;
  showSearchInput?: boolean;
  showAdvancedFilters?: boolean;
  disableAllFilters?: boolean;
  onFilterClear?: () => void;
  showViewOptions?: boolean;
}

const TableCustomVirtuoso: React.FC<Props> = ({
  additionalButtons,
  filters,
  data,
  columns,
  renderCellContent,
  onSort,
  rowClickPath,
  loadMore,
  hasMore,
  onFilterChange,
  showSearchInput = true,
  showAdvancedFilters = true,
  disableAllFilters = false,
  isLoading,
  onFilterClear,
  showViewOptions = true
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState('');

  const [columnVisibility, setColumnVisibility] = useState<{ [key: string]: boolean }>(() => {
    const savedVisibility = localStorage.getItem('columnVisibility');
    if (savedVisibility) {
      return JSON.parse(savedVisibility);
    }
    const visibility: { [key: string]: boolean } = {};
    columns.forEach((column) => {
      visibility[column.key] = column.visible ?? true;
    });
    return visibility;
  });

  const [sorting, setSorting] = useState<{ key: string; direction: 'ascending' | 'descending' } | null>(null);

  const visibleColumns = columns.filter((column) => columnVisibility[column.key]);

  useEffect(() => {
    const visibility: { [key: string]: boolean } = {};
    columns.forEach((column) => {
      visibility[column.key] = columnVisibility[column.key] ?? column.visible ?? true;
    });
    setColumnVisibility(visibility);
    localStorage.setItem('columnVisibility', JSON.stringify(visibility));
  }, [columns]);

  const totalSpecifiedWidth = visibleColumns.reduce((total, column) => {
    return total + (column.width ? parseFloat(column.width) : 0);
  }, 0);
  const remainingWidth = 100 - totalSpecifiedWidth;
  const unspecifiedColumns = visibleColumns.filter((column) => !column.width);
  const defaultColumnWidth = unspecifiedColumns.length > 0 ? `${remainingWidth / unspecifiedColumns.length}%` : '0%';

  const getColumnWidth = (column: Column): string => {
    return column.width || defaultColumnWidth;
  };

  const handleColumnHeaderClick = (columnKey: string) => {
    const direction = sorting?.key === columnKey && sorting.direction === 'ascending' ? 'descending' : 'ascending';
    setSorting({ key: columnKey, direction });
    onSort(columnKey, direction);
  };

  const handleRowClick = (rowData: any) => {
    if (rowClickPath) {
      navigate(rowClickPath(rowData));
    }
  };

  function itemContent(index: number) {
    if (isLoading) {
      return visibleColumns.map((column) => (
        <td
          key={column.key}
          className={cn(
            'px-4 py-2 whitespace-nowrap justify-start',
            'border-b border-background/20',
            column.numeric ? 'text-right' : 'text-left',
            'table-cell-wrap',
            'bg-background/95 transition-colors duration-150'
          )}
          style={{ width: getColumnWidth(column) }}
        >
          <Skeleton className="h-8 w-full my-2 bg-black/10 dark:bg-gray-500/20" />
        </td>
      ));
    }

    if (data.length === 0 && index === 0) {
      return (
        <td colSpan={visibleColumns.length} className="px-4 py-2 bg-background/95" style={{ textAlign: 'center' }}>
          {t('medications.noRecords')}
        </td>
      );
    }

    if (index >= data.length) {
      return null;
    }

    return (
      <React.Fragment key={index}>
        {visibleColumns.map((column) => (
          <td
            key={column.key}
            className={cn(
              'px-4 py-2 whitespace-nowrap justify-start',
              'border-b border-gray-200',
              column.numeric ? 'text-right' : 'text-left',
              'table-cell-wrap',
              'transition-colors duration-150',
              column.render ? '' : 'cursor-pointer'
            )}
            style={{ width: getColumnWidth(column) }}
          >
            <div
              onClick={() => {
                if (!column.render && rowClickPath) {
                  handleRowClick(data[index]);
                }
              }}
            >
              {column.render ? column.render(data[index]) : renderCellContent(index, column, data)}
            </div>
          </td>
        ))}
      </React.Fragment>
    );
  }

  function fixedHeaderContent() {
    return (
      <tr>
        {visibleColumns.map((column) => (
          <th
            key={column.key}
            className={cn(
              'px-4 py-2 whitespace-nowrap bg-primary text-white shadow-md',
              'sticky top-0 z-10',
              'cursor-pointer hover:bg-primary-dark transition-colors duration-150'
            )}
            style={{ width: getColumnWidth(column) }}
            onClick={() => column.sortable && handleColumnHeaderClick(column.key)}
          >
            <div className="flex items-center">
              <span>{t(column.labelTranslationKey)}</span>
              {column.sortable && (
                <span className="ml-1">
                  {sorting?.key === column.key ? (
                    sorting.direction === 'ascending' ? (
                      <ArrowUpDown size={16} />
                    ) : (
                      <ArrowDownUp size={16} />
                    )
                  ) : (
                    <ArrowUpDown size={16} />
                  )}
                </span>
              )}
            </div>
          </th>
        ))}
      </tr>
    );
  }

  const handleEndReached = useCallback(() => {
    if (hasMore) {
      loadMore();
    }
  }, [hasMore, loadMore]);

  const handleColumnVisibilityChange = (columnKey: string, isVisible: boolean) => {
    const staticColumns = columns.filter((column) => column.static).map((column) => column.key);
    if (staticColumns.includes(columnKey)) {
      return;
    }
    const visibleColumnCount = Object.values(columnVisibility).filter(Boolean).length;
    if (visibleColumnCount > 3 || (visibleColumnCount === 3 && isVisible)) {
      const newVisibility = {
        ...columnVisibility,
        [columnKey]: isVisible
      };
      setColumnVisibility(newVisibility);
      localStorage.setItem('columnVisibility', JSON.stringify(newVisibility));
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFilterValue(value);
    if (value.length > 3 && onFilterChange) {
      onFilterChange(value);
    }
  };

  const handleClearSelection = () => {
    setFilterValue('');
    if (onFilterChange) {
      onFilterChange('');
    }
    if (onFilterClear) {
      onFilterClear();
    }
  };

  return (
    <div className="h-full w-full flex flex-col space-y-4">
      {showViewOptions && (
        <div className="bg-white shadow rounded-lg p-4">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0 md:space-x-4">
            <div className="flex items-center space-x-4 flex-grow">
              {showSearchInput && (
                <div className="relative flex-grow max-w-md">
                  <Input
                    type="text"
                    placeholder={t('tablevirtuoso.search')}
                    className="w-full h-10 rounded-lg border-gray-300 px-4 py-2 text-sm focus:border-primary focus:ring-primary focus:outline-none focus-visible:ring-primary focus-visible:ring-offset-0"
                    value={filterValue}
                    onChange={handleInputChange}
                  />
                  <Button
                    onClick={handleClearSelection}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 bg-transparent hover:bg-transparent border-none shadow-none text-gray-500"
                    variant="outline"
                  >
                    <X className="w-4 h-4" />
                  </Button>
                </div>
              )}
              {filters && <div>{filters}</div>}
            </div>
            <div className="flex items-center space-x-2">
              {React.Children.map(additionalButtons, (button) =>
                React.isValidElement(button) ? React.cloneElement(button) : null
              )}
              <TableViewOptions
                columns={columns.filter((column) => !column.static)}
                columnVisibility={columnVisibility}
                onColumnVisibilityChange={handleColumnVisibilityChange}
              />
            </div>
          </div>
        </div>
      )}

      <div className="table-container overflow-hidden relative" style={{ height: 'calc(100vh - 23rem)' }}>
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white z-20">
            <SplashScreen />
          </div>
        )}
        <TableVirtuoso
          totalCount={data.length > 0 ? data.length : 1}
          itemContent={(index) => itemContent(index)}
          fixedHeaderContent={fixedHeaderContent}
          className="table-auto w-full border-collapse"
          endReached={handleEndReached}
          overscan={200}
          style={{ borderRadius: '0.5rem' }}
        />
      </div>
    </div>
  );
};

export default TableCustomVirtuoso;
