import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/common/presentation/components/ui/table';

import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { getResidents } from '@/modules/residents/presentation/slices/residentSlice';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
// import TableCustom from '@/common/presentation/components/Table/TableCustom';
import TrackerService from '../../infrastructure/services/TrackerService';
import * as temperatureActions from '../slices/TrackerSlice';
import { Input } from '@/common/presentation/components/ui/input';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';

import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';

import { Pencil, Trash, Ellipsis } from 'lucide-react';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { Label } from '@/common/presentation/components/ui/label';
import { Switch } from '@/common/presentation/components/ui/switch';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';

interface TrackerVitalTypeProps {
    setpTypesVital: () => void;
}

function TrackerVitalsType({ setpTypesVital }: TrackerVitalTypeProps) {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const dispatch = useDispatch();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [types, setTypes] = useState(null);
    const [dataid, setDataid] = useState(null);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [selectedVitals, setSelectedVitals] = useState<string>(params.e || 'form');
    const [showEditDialog, setShowEditDialog] = useState(false);
    const { locationSelectedId } = useLocation();
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const { t } = useTranslation();
    const onSelectVitals = (e: string) => {
        setParam('e', e);
        setSelectedVitals(e);
    };
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);
    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);
    useEffect(() => {
        if (locationSelectedId && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        locationSelectedId === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };
            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);



    const columnsreport = [
        { label: t('trackers.customtype.Name'), value: 'name', type: 'date' },
        { label: t('trackers.customtype.Status'), value: 'status', type: 'text' }
    ];

    const setpTypes = async () => {
        const json = { location_id: locationSelectedId };
        const response = await TrackerService.getTrackerVitalsType(json);
        if (response && response.data) {
            const jsonData = JSON.parse(response.data);
            const columnField = jsonData.column_field;
            setTypes(columnField);
            setDataid(response.id);
        }

    };

    useEffect(() => {
        if (!types) {
            setpTypes();
        }
    }, [types, dataid]);


    const Fields = {
        'Edit': [
            { fieldName: 'name', label: t('trackers.customtype.Name'), type: 'text' },
            { fieldName: 'status', label: t('trackers.customtype.Status'), type: 'switch' }
        ]
    };

    const [fields, setDialogField] = useState(false);

    const handleButtonOpenDialog = (fields: any[], title: string, row: any[]) => {
        setShowEditDialog(true);
        setFormValue(row);
        setDialogField(fields);
        setDialogTitle(title);
        setAction(title);
    };


    const handleSubmit = async () => {
        const json: { [key: string]: any } = {};
        if (action == 'Edit') {
            json["id"] = dataid;
            json["id_data"] = formData.id;
            json["data"] = {
                "name": formData.name,
                "status": formData.status === true || formData.status === 'active' ? 'active' : 'inactive'
            };
            const response = await TrackerService.updateTrackerVitalsType(json);
            if (response) {
                setpTypesVital();
            }
            setShowEditDialog(false);
        } else {
            json["data"] = {
                "name": formData.name,
                "status": formData.status === true || formData.status === 'active' ? 'active' : 'inactive'
            };
            json["location_id"] = locationSelectedId;
            const response = await TrackerService.saveTrackerVitalsType(json);
            if (response) {
                setpTypesVital();
            }
            setShowEditDialog(false);
        }
        setpTypes();
    };

    useEffect(() => {
        setpTypes();
    }, [locationSelectedId]);

    const [filters, setFilters] = useState({
        name: '',
        status: ''
    });

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
        setFormData({});
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    const handleChangechecked = (checked, column) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [column.fieldName]: checked
        }));
    };


    const capitalizeWords = (str: string) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const renderInputForColumn = (column, index) => {
        switch (column.type) {
            case 'text':
                return (
                    <input
                        id={column.fieldName}
                        name={column.fieldName}
                        type="text"
                        value={formData[column.fieldName] || ''}
                        onChange={handleChange}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                    />
                );
            case 'switch':
                return (<Switch
                    checked={formData[column.fieldName] === 'inactive' ? false : !!formData[column.fieldName]}
                    onCheckedChange={(checked) => handleChangechecked(checked, column)}
                />);
            default:
                return null;
        }
    };
    return (
        <div>
            {!locationSelectedId && (
                <div>
                </div>
            )}

            <div>
                {
                    locationSelectedId && (
                        <div>
                            <Card className="border-t-4 border-t-primary/50">
                                <CardHeader>
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.trackervitalsreport.title')}</CardTitle>
                                    <div className="flex w-full items-center justify-between">
                                        <div>

                                        </div>
                                        <div>
                                            <Button onClick={() => handleButtonOpenDialog(Fields['Edit'], 'New', [])}>+ {t('trackers.customtype.New')}</Button>
                                        </div>

                                    </div>
                                </CardHeader>
                                <CardContent>
                                    <div className="flex space-x-4">
                                        <Input
                                            placeholder="Filter by Name"
                                            value={filters.name}
                                            onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                                            className="w-[200px]"
                                        />
                                        <Input
                                            placeholder="Filter by Status"
                                            value={filters.status}
                                            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                                            className="w-[200px]"
                                        />
                                    </div>
                                    <br />
                                    <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                        <Table className="shadow-lg overflow-hidden">
                                            <TableHeader>
                                                <TableRow className="py-2 px-4 text-left bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                    {columnsreport.map((column) => (
                                                        <TableHead className="text-white" key={column.value}>
                                                            {column.label}
                                                        </TableHead>
                                                    ))}
                                                    <TableHead className="text-white">{t('trackers.Actions')}</TableHead>
                                                </TableRow>
                                            </TableHeader>
                                            <TableBody className="bg-white dark:bg-background">
                                                {types && types
                                                    .filter(row =>
                                                        (!filters.name || row.name.toLowerCase().includes(filters.name.toLowerCase())) &&
                                                        (!filters.status || row.status.toLowerCase().includes(filters.status.toLowerCase()))
                                                    )
                                                    .map((row, index) => (
                                                        <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800">
                                                            {columnsreport.map((column, columnIndex) => (
                                                                <TableCell key={columnIndex} className="py-2 px-4">
                                                                    {capitalizeWords(String(row[column.value as keyof typeof row]))}
                                                                </TableCell>
                                                            ))}
                                                            <TableCell className="py-2 px-4">
                                                                <DropdownMenu>
                                                                    <DropdownMenuTrigger asChild>
                                                                        <Button variant="outline" size="icon">
                                                                            <Ellipsis className="h-4 w-4" />
                                                                        </Button>
                                                                    </DropdownMenuTrigger>
                                                                    <DropdownMenuContent className="w-56">
                                                                        <DropdownMenuSeparator />
                                                                        <DropdownMenuGroup>
                                                                            <DropdownMenuItem>
                                                                                <Pencil className="mr-2 h-4 w-4" />
                                                                                <span onClick={() => handleButtonOpenDialog(Fields['Edit'], 'Edit', row)}>{t('trackers.Edit')}</span>
                                                                            </DropdownMenuItem>
                                                                        </DropdownMenuGroup>
                                                                    </DropdownMenuContent>
                                                                </DropdownMenu>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                            <CustomDialog
                                                width="30rem"
                                                newDialog={true}
                                                isOpen={showEditDialog}
                                                onClose={handleCloseEditDialog}
                                                title={dialogTitle}
                                                onSubmit={handleSubmit}
                                                description={dialogDescription}
                                            >
                                                <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                                    <div className="grid gap-4">
                                                        {fields && fields.map((field, index) => (
                                                            <div key={field.fieldName} className="grid grid-cols-4 items-center gap-4">
                                                                <Label htmlFor={field.fieldName} className="text-right">{field.label}</Label>

                                                                {renderInputForColumn(field, index)}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="flex flex-row justify-end mt-4">
                                                        <Button type="submit" className="bg-primary">
                                                            Edit
                                                        </Button>
                                                    </div>
                                                </form>

                                            </CustomDialog>
                                        </Table>

                                        <div>
                                            <div className="flex items-center justify-between px-2">
                                                <div className="flex-1 text-sm text-muted-foreground">
                                                    {types && types.length} of {types && types.length} row(s) selected.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    )
                }
            </div>


        </div >
    )
}

export default TrackerVitalsType