import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '@/common/presentation/components/ui/table';
import { DocumentCategory } from '@/modules/residents/infrastructure/types/documents';
import { RootState } from '@/store/store';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { CategoryItem } from './CategoryItem';
import { Input } from '@/common/presentation/components/ui/input';
import { Button } from '@/common/presentation/components/ui/button';
import { CircleFadingPlus } from 'lucide-react';
import { useUploadDocument } from '@/modules/residents/infrastructure/hooks/documents/useUploadDocument';

interface Column {
  key: string;
  label: string;
  type?: 'text' | 'checkbox' | 'action';
  width?: string;
}

const columns: Column[] = [
  {
    key: 'name',
    label: 'Document name',
    type: 'text',
    width: '30%'
  },
  {
    key: 'comments',
    label: 'Comments',
    type: 'text',
    width: '30%'
  },
  {
    key: 'hard_copy',
    label: 'Hard Copy',
    type: 'checkbox',
    width: '10%'
  },
  {
    key: 'version_history',
    label: 'Version History',
    type: 'text',
    width: '10%'
  },
  {
    key: 'actions',
    label: 'Actions',
    type: 'action',
    width: '20%'
  }
];

interface CategoryCardProps {
  category: DocumentCategory;
}

export const CategoryCard = ({ category }: CategoryCardProps) => {
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const { resident } = useSelector((state: RootState) => state.residents.resident);
  const { onOpen: onUploadDocumentOpen } = useUploadDocument();

  const defaultDocuments = useMemo(() => {
    return category.documents || [];
  }, [category]);

  const documents = useMemo(() => {
    const defaultValues = {
      category: category.value,
      comments: null,
      url: null,
      other_information: {
        hard_copy: false
      },
      hard_copy: false,
      parent_documents: []
    };

    const docs = defaultDocuments.map((doc) => {
      const residentDoc = resident?.documents?.find((d: any) => d.key === doc.value && d.category === category.value);
      return { ...defaultValues, ...doc, ...(residentDoc || {}), key: doc.value };
    });

    const additionalDocs =
      resident?.documents
        ?.filter((doc: any) => doc.category === category.value && !defaultDocuments.some((d) => d.value === doc.key))
        .map((doc: any) => ({
          ...defaultValues,
          ...doc,
          key: doc.key
        })) || [];

    return [...docs, ...additionalDocs];
  }, [resident, defaultDocuments, category.value]);

  const filteredDocuments = useMemo(() => {
    if (!filter) {
      return documents;
    }

    return documents.filter((doc) => doc.label.toLowerCase().includes(filter.toLowerCase()));
  }, [documents, filter]);

  return (
    <CustomAccordion title={category.label}>
      <div className="flex gap-4 px-1 py-2">
        <Input placeholder="Search" className="w-[300px]" value={filter} onChange={(e) => setFilter(e.target.value)} />
        <Button
          onClick={() =>
            onUploadDocumentOpen({
              category: category.value
            })
          }
          className="flex ml-auto w-auto gap-2 bg-primary"
        >
          <CircleFadingPlus className="h-4 w-4 flex-1 gap-4" />
          New
        </Button>
      </div>

      <Table className="w-full border-collapse rounded-t-lg overflow-hidden">
        <TableHeader>
          <TableRow className="bg-primary text-white hover:bg-primary">
            {columns.map((column) => (
              <TableCell key={column.key} style={{ width: column.width }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredDocuments.map((document: any, index: number) => {
            return (
              <CategoryItem
                resident_id={resident?.id}
                document={document}
                residentDocumentsCount={resident?.documents.length}
                key={index}
              />
            );
          })}
        </TableBody>
      </Table>
    </CustomAccordion>
  );
};
