import React, { useState, useMemo } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/common/presentation/components/ui/dialog';
import { useSettings } from '../../infrastructure/hooks/use-settings';

import CardSetting from '@/modules/settings/presentation/components/CardSetting';
import { Skeleton } from '@/common/presentation/components/ui/skeleton';

import { Home, ArrowLeft } from 'lucide-react';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from '@/common/presentation/components/ui/breadcrumb';

import { Button } from '@/common/presentation/components/ui/button';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { RootState } from '@/store/store';

import { IRoute } from '../../infrastructure/utils/interfaceModules'
import { modules } from '../../infrastructure/utils/Modules';


export const SettingsDialog: React.FC = () => {
  const { isOpen, onClose } = useSettings();
  const [route, setRoute] = useState<string[]>([]);
  const { t } = useTranslation();
  const { isLoading, configurations } = useSelector((state: RootState) => state.configurations);
  const primaryColor = useMemo(() => (isLoading) ? '#3b8bc3' : configurations.accent_color ?? '#3b8bc3', [isLoading]);

  const currentModule = useMemo(() => {
    return route.reduce<IRoute>((accumulator, currentValue) => {
      if (accumulator === undefined) {
        return modules.find(module => module.key === currentValue);
      }
      if ('subModules' in accumulator) {
        return accumulator.subModules?.find(subModule => subModule.key === currentValue)
      }
      return accumulator;

    }, undefined)

  }, [route]);


  const handleNavigation = (key: string) => {
    setRoute(prev => [...prev, key]);
  };

  const handleBack = () => {
    setRoute(prev => prev.slice(0, -1));
  };


  const renderContent = () => {

    if (currentModule == undefined) { //Default module settings
      return (
        <div className="grid grid-cols-1 bg-[#0000] sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 sm:gap-1 md:gap-2">
          {modules.map((module: any) => (
            <CardSetting
              key={module.key}
              id={module.key}
              color={primaryColor}
              title={module.label}
              icon={module.icon}
              onClick={() => handleNavigation(module.key)}
            />
          ))}
        </div>
      );
    }

    if ('subModules' in currentModule && currentModule.subModules) { // Sub module of module
      return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 sm:gap-1 md:gap-2">
          {currentModule.subModules.map((subModule: any) => (
            <CardSetting
              key={subModule.key}
              color={primaryColor}
              id={subModule.key}
              title={subModule.label}
              icon={subModule.icon}
              onClick={() => handleNavigation(subModule.key)}
            />
          ))}
        </div>
      );
    }

    return currentModule.component; // Component of module or subModule
  };


  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose} key={'dialogSettingsConfigurations'}>
        <DialogContent className="max-w-[92%]  min-h-[90vh] flex flex-col gap-6 md:max-w-[80%] rounded-lg p-6">
          <DialogHeader>
            <DialogTitle>{t('settings.settings')}</DialogTitle>
            <div className='flex justify-between'>
              <Breadcrumb className='pt-4'>
                <BreadcrumbList>
                  <BreadcrumbItem>
                    <BreadcrumbLink onClick={() => setRoute([])} className='flex gap-1'>
                      <Home className="h-4 w-4" />
                      <span>{t('settings.settings')}</span>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  {route.map((key, index) => (
                    <React.Fragment key={key}>
                      <BreadcrumbSeparator />
                      <BreadcrumbItem>
                        <BreadcrumbPage onClick={() => setRoute(route.slice(0, index + 1))}>
                          {modules.find(m => m.key === key)?.label ||
                            modules.flatMap(m => m.subModules || []).find(sm => sm.key === key)?.label}
                        </BreadcrumbPage>
                      </BreadcrumbItem>
                    </React.Fragment>
                  ))}
                </BreadcrumbList>
              </Breadcrumb>
              {route.length > 0 && (
                <Button variant="ghost" onClick={handleBack}>
                  <ArrowLeft className="mr-2 h-4 w-4" />
                  {t('settings.back')}
                </Button>
              )}
            </div>
          </DialogHeader>
          <div className="h-full w-full mt-3">
            {isLoading && (
              <>
                <Skeleton className="h-8 w-[95%] my-2 bg-black/10 dark:bg-gray-500/20" />
                <Skeleton className="h-8 w-[95%] my-2 bg-black/10 dark:bg-gray-500/20" />
                <Skeleton className="h-8 w-[95%] my-2 bg-black/10 dark:bg-gray-500/20" />
                <Skeleton className="h-8 w-[95%] my-2 bg-black/10 dark:bg-gray-500/20" />
              </>
            )}

            {!isLoading && renderContent()}
          </div>
        </DialogContent>
      </Dialog>


    </>
  );
};