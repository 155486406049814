import { useEffect, useRef } from "react";
import { AppDispatch, RootState } from "@/store/store";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Form, FormField, FormItem, FormLabel, FormControl } from "@/components/ui/form";
import { Switch } from "@/common/presentation/components/ui/switch";
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { Button } from "@/components/ui/button";
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';
import { toast } from "@/common/presentation/components/ui/use-toast.ts";

import { 
    getAllowUsersEditMedication, 
    allowUsersEditMedicationLoading,
    updateAllowUsersEditMedication
} from '@/modules/settings/presentation/components/menus/medications/slices/MedicationConfigurationSlice';
import { getClientConfigurations } from "@/common/presentation/slices/configurationSlice";
import { useTranslation } from 'react-i18next';

interface IAllowUsers {
    name: string;
    userId: string;
    label?: string;
    icon?: string;
    path?: string;
    menuId: string;
    status: boolean;
}

interface FormValues {
    [key: string]: boolean;
}

export const AllowStaffEditMedication = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { allowedUsers, isLoading } = useSelector((state: RootState) => state?.settings.medicationConfiguration);
    const formRef = useRef<HTMLFormElement>(null);
    const { t } = useTranslation();

    const form = useForm<FormValues>({
        defaultValues: {},
    });

    const onSubmit = async (data: FormValues) => {
       const response = await dispatch(updateAllowUsersEditMedication(data));       
       if( response?.payload?.status === 'ok' ){
        toast({
            description: 'updated successfully',
            className: 'bg-green-500 text-white z-[99999]'
          });
        
          dispatch(getClientConfigurations());
       }
       
    };

    useEffect(() => {
        if (allowedUsers.length > 0) {
          const defaultValues: FormValues = allowedUsers.reduce((acc, userAllow: IAllowUsers) => {
            acc[userAllow.userId] = userAllow.status;
            return acc;
          }, {} as FormValues);
    
          form.reset(defaultValues);
        }
    }, [allowedUsers, form]);

    useEffect(() => {
        dispatch(allowUsersEditMedicationLoading());
        dispatch(getAllowUsersEditMedication());
    }, [dispatch]);


    return (
        <>
        {isLoading && (
            <div className='w-full h-full flex justify-center items-center absolute top-[0] left-[0] '>
                <Spinner size={'icon'} />
            </div>
        )}

        {!isLoading && (
            <div className="flex flex-col h-[75vh]">
                <Form {...form}>
                    <form ref={formRef} onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col h-[75vh]">
                        <ScrollArea className="pr-4">
                            <div className="space-y-5 pb-2">
                                {allowedUsers && allowedUsers.map((user: IAllowUsers) => (
                                    <FormField
                                        key={user.userId}
                                        control={form.control}
                                        name={user.userId}
                                        render={({ field }) => (
                                            <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                                                <div className="space-y-0.5">
                                                    <FormLabel className="text-base">
                                                        {user.label}
                                                    </FormLabel>
                                                </div>
                                                <FormControl>
                                                    <Switch
                                                        checked={field.value}
                                                        onCheckedChange={field.onChange}
                                                    />
                                                </FormControl>
                                            </FormItem>
                                        )}
                                    />
                                ))}
                            </div>
                        </ScrollArea>
                        <div className="mt-4 flex justify-end">
                            <Button type="submit">
                                {t('settings.save')}
                            </Button>
                        </div>
                    </form>
                </Form>

            </div>
        )}
        </>
    );
};

export default AllowStaffEditMedication;