import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const MedicationConfiguration = {

  update: async (formData: any) => {
    try {
      const response = await axios.post(`medicationConfiguration/update`, formData);
      if (response.data) {
        return response.data;
      }
    } catch (error: any) {
      throw new Error(`Error update enabled trackers: ${error.message}`);
    }
  },
  getAllowUser: async () => {
    try {
      const { data } = await axios.get(`medicationConfiguration/getAllowUser`);
      if ('data' in data) {
        return data.data;
      }
    } catch (error: any) {
      throw new Error(`Error get enabled trackers: ${error.message}`);
    }
  }

}

export default MedicationConfiguration;