import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { SingleTable } from '@/common/presentation/components/Table/SingleTable';
import { Button } from '@/common/presentation/components/ui/button';
import * as staffSliceActions from '@/modules/staff/presentation/slices/staffSlice';
import { AppDispatch, RootState } from '@/store/store';
import { CircleFadingPlus } from 'lucide-react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useNewStaff } from '../../infrastructure/hooks/use-new-staff';
import { columns } from '@/modules/staff/presentation/components/dashboard/tables/StaffTableColumns';
import { useTranslation } from 'react-i18next';
import { Dialogs as StaffDashboardDialogs } from '@/modules/staff/presentation/components/dashboard/dialogs/Dialogs';

const canCreateRoles = ['Super Administrator', 'Executive'];

export const StaffPage = () => {
  const { t } = useTranslation();
  const { onOpen } = useNewStaff();
  const { params, setParam } = useRouteParams();
  const router = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const { staff, status } = useSelector((state: RootState) => state.staff.allStaff);
  const { user } = useSelector((state: RootState) => state.auth);

  const canCreate = useMemo(() => {
    return user?.roles.some((role: any) => canCreateRoles.includes(role.name));
  }, [user]);

  useEffect(() => {
    if (params.l) {
      dispatch(staffSliceActions.getStaffByLocation(params.l === 'all-locations' ? undefined : params.l));
    } else {
      setParam('l', 'all-locations');
    }
  }, [params]);

  const selectedRow = (row: any) => {
    const selectedStaff = staff[row];
    router(`/staff/profile?s=${selectedStaff.id}`);
  };

  return (
    <>
      <LocationSelector locationId={useParams().l} />

      <div>
        <div className="Table">
          {status === 'loading' ? (
            <div>Loading...</div>
          ) : (
            <SingleTable
              data={staff}
              columns={columns}
              onDelete={() => {}}
              disable={false}
              filterKey={'first_name'}
              onRowClick={selectedRow}
              aditionalActions={
                <>
                  {canCreate && (
                    <Button onClick={onOpen} className="flex ml-auto w-auto gap-2 bg-primary">
                      <CircleFadingPlus className="h-4 w-4 flex-1 gap-4" />
                      {t('staff.dashboard.new_staff')}
                    </Button>
                  )}
                </>
              }
            />
          )}
        </div>
      </div>

      <StaffDashboardDialogs />
    </>
  );
};
