import { Button } from '@/common/presentation/components/ui/button';
import { Pencil } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface Props {
  id: string;
}

export const TableActions = ({ id }: Props) => {
  const navigate = useNavigate();

  const handleView = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/staff/update?sm=${id}`);
  };

  return (
    <>
      <div className="flex gap-2">
        <Button size={'icon'} variant={'outline'} onClick={(e) => handleView(e)}>
          <Pencil className="size-4" />
        </Button>
      </div>
    </>
  );
};
