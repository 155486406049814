import { EmarPrnHistoryDialog } from '@/modules/emar/presentation/components/dialogs/EmarPrnHistoryDialog';
import { NewExceptionDialog } from '@/modules/emar/presentation/components/dialogs/NewExceptionDialog';
import { NewPrnDialog } from '@/modules/emar/presentation/components/dialogs/NewPrnDialog';
import { ListMedicationDocumentDialog } from '@/modules/medications/presentation/components/MedicationsPDF/ListMedicationDocumentDialog';
import { MarDocumentDialog } from '@/modules/medications/presentation/components/MedicationsPDF/MarDocumentDialog';
import { AddNewFieldFormDialog } from '@/modules/residents/presentation/components/PlanOfCareForm/AddNewFieldFormDialog';
// import { HistoryDocumentsDialog } from '@/modules/residents/presentation/components/documents/HistoryDocumentsDialog';
// import { AddNewResidentDocumentDialog } from '@/modules/residents/presentation/components/documents/add-custom-file-dialog';
// import { NewResidentDocumentDialog } from '@/modules/residents/presentation/components/documents/new-resident-document-dialog';
import { NewResidentDialog } from '@/modules/residents/presentation/components/new-resident-dialog';
import { SettingsDialog } from '@/modules/settings/presentation/components/SettingsDialog';
import { StaffNewCustomFieldsDialog } from '@/modules/settings/presentation/components/menus/Staff/NewCustomFieldsDialog';
import { StaffUpdateCustomFieldsDialog } from '@/modules/settings/presentation/components/menus/Staff/UpdateCustomFieldsDialog';
import { NewCustomFieldsDialog } from '@/modules/settings/presentation/components/menus/residents/NewCustomFieldsDialog';
import { UpdateCustomFieldsDialog } from '@/modules/settings/presentation/components/menus/residents/UpdateCustomFieldsDialog';

// Residents
import { ResidentDocumentsDialog } from '@/modules/residents/presentation/components/Documents/Dialogs/ResidentDocumentsDialog';
import { HistoryDocumentsDialog } from '@/modules/residents/presentation/components/Documents/Dialogs/HistoryDocumentsDialog';
import { FormFieldsManagerDialog } from '@/modules/residents/presentation/components/PlanOfCareForm/Dialogs/AddFormFieldsManagerDialog';
import { AddFieldFormDialog } from '@/modules/residents/presentation/components/PlanOfCareForm/Dialogs/AddFieldFormDialog';
import { EditFieldFormDialog } from '@/modules/residents/presentation/components/PlanOfCareForm/Dialogs/EditFieldFormDialog';
import { EditDefaultFormDialog } from '@/modules/residents/presentation/components/Forms/ApprasialNeedsAndServicePlan/Dialogs/EditDefaultFormDialog';

export const Dialogs = () => {
  return (
    <>
      <NewResidentDialog />
      <NewExceptionDialog />
      <NewPrnDialog />
      <SettingsDialog />
      <NewCustomFieldsDialog />
      <UpdateCustomFieldsDialog />
      <EmarPrnHistoryDialog />
      <MarDocumentDialog />
      <ListMedicationDocumentDialog />
      <StaffNewCustomFieldsDialog />
      <StaffUpdateCustomFieldsDialog />
      <StaffUpdateCustomFieldsDialog />
      <HistoryDocumentsDialog />
      {/* Residents */}
      <ResidentDocumentsDialog />
      <EditDefaultFormDialog />
      <AddNewFieldFormDialog />
      <FormFieldsManagerDialog />
      <AddFieldFormDialog />
      <EditFieldFormDialog />
    </>
  );
};
