import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';
import { VitalsSettingFormI } from '../types/trackerAkerts';

const TrackerAlertService = {
  postVitalSettings: async (formData: VitalsSettingFormI) => {
    let url = `/tracker-alerts`;
    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error posting alerts';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
};

export default TrackerAlertService;