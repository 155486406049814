import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { Button } from '@/common/presentation/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/common/presentation/components/ui/form';
import { Input } from '@/common/presentation/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/common/presentation/components/ui/select';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { GetPicklist } from '@/modules/staff/infrastructure/services/StaffService';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import { AppDispatch, RootState } from '@/store/store';
import { zodResolver } from '@hookform/resolvers/zod';
import { Separator } from '@radix-ui/react-select';
import { Eye, EyeOff, Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { z } from 'zod';

interface Props {
  className?: string;
}

const formSchema = z
  .object({
    user_level: z.string().optional(),
    username: z.string().optional(),
    password: z
      .string()
      .optional()
      .refine(
        (value) => {
          if (value === undefined || value === '') return true; // Si no hay valor o es una cadena vacía, es válido
          if (value.length < 8 || value.length > 30) return false; // Validación de longitud
          return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,30}$/.test(value); // Validación de contenido
        },
        {
          message:
            'Password must be at least 8 characters, no more than 30 characters, and contain at least one uppercase letter, one lowercase letter, and one number.'
        }
      ),
    confirm_password: z.string()
  })
  .refine(
    (data) => {
      if (data.password === undefined || data.password === '') return true; // Si la contraseña no está presente, no necesita coincidir
      return data.password === data.confirm_password;
    },
    {
      message: "Passwords don't match",
      path: ['confirm_password']
    }
  );

type FormValues = z.infer<typeof formSchema>;

export const LoginInformationForm = ({ className }: Props) => {
  const [rolOptions, setRolOptions] = useState([]);
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);
  const { status: staffFormStatus } = useSelector((state: RootState) => state.staff.staffMemberForm);
  const [defaultValues, setDefaultValues] = useState<Partial<FormValues>>({});

  const [passwordVisible, setPasswordVisible] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
    mode: 'onChange',
    shouldUnregister: false,
    values: { ...defaultValues } as FormValues
  });

  const onSubmitForm = async (data: FormValues) => {
    const response = dispatch(
      staffMemberFormActions.updateStaffloginForm({
        staff_id: staffMember?.id,
        ...data
      })
    );

    if ((await response).payload) {
      toast({
        description: 'Login member staff updated successfully'
      });

      form.reset({
        user_level: staffMember?.role_id || '',
        username: staffMember?.username || '',
        password: '',
        confirm_password: ''
      });
    }
  };

  const getPicklist = async () => {
    try {
      const { data }: any = await GetPicklist();
      const { rol } = data;
      const rolOptions = rol.map((rol: any) => {
        return {
          label: rol.name,
          value: rol.id
        };
      });
      setRolOptions(rolOptions);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPicklist();
  }, []);

  useEffect(() => {
    if (staffMember && rolOptions.length > 0) {
      setDefaultValues({
        user_level: staffMember?.role_id || '',
        username: staffMember?.username || '',
        password: '',
        confirm_password: ''
      });
    }
  }, [staffMember, rolOptions]);

  return (
    <>
      {status === 'loading' && <div>Loading...</div>}
      {status === 'failed' && <div>Failed to load </div>}
      {status === 'idle' && (
        <div className={cn(className)}>
          <CustomAccordion title="Login Infomation">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmitForm)}>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                  <div>
                    <FormField
                      control={form.control}
                      name="user_level"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel>
                              User Level <span>(*)</span>
                            </FormLabel>
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value);
                                console.log('Selected value:', value); // Imprime el valor seleccionado
                              }}
                              defaultValue={field.value}
                              value={field.value}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder="Select a resident house" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {rolOptions.map((rol: any, index: number) => (
                                  <SelectItem value={rol.value} key={index}>
                                    {rol.label}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormField
                      control={form.control}
                      name="username"
                      render={() => (
                        <FormItem>
                          <FormLabel>Username (*)</FormLabel>
                          <FormControl>
                            <Input placeholder="" disabled value={staffMember?.username} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormField
                      control={form.control}
                      name="password"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Password</FormLabel>
                          <FormControl>
                            <div className="relative flex items-center">
                              <Input
                                {...field}
                                type={passwordVisible ? 'text' : 'password'}
                                disabled={staffFormStatus === 'loading'}
                                placeholder="********"
                                autoCorrect="off"
                                autoComplete="off"
                                className="w-full rounded-lg bg-background"
                              />
                              <Eye
                                className={cn(
                                  'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                                  passwordVisible && 'hidden'
                                )}
                                onClick={() => setPasswordVisible(true)}
                              />
                              <EyeOff
                                className={cn(
                                  'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                                  !passwordVisible && 'hidden'
                                )}
                                onClick={() => setPasswordVisible(false)}
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <FormField
                      control={form.control}
                      name="confirm_password"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Confirm password</FormLabel>
                          <FormControl>
                            {/* <Input placeholder="" {...field} disabled={staffFormStatus === 'loading'} /> */}

                            <div className="relative flex items-center">
                              <Input
                                {...field}
                                type={passwordVisible ? 'text' : 'password'}
                                disabled={staffFormStatus === 'loading'}
                                placeholder="********"
                                autoCorrect="off"
                                autoComplete="off"
                                className="w-full rounded-lg bg-background"
                              />
                              <Eye
                                className={cn(
                                  'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                                  passwordVisible && 'hidden'
                                )}
                                onClick={() => setPasswordVisible(true)}
                              />
                              <EyeOff
                                className={cn(
                                  'absolute right-2.5 top-2.5 h-4 w-4 text-muted-foreground',
                                  !passwordVisible && 'hidden'
                                )}
                                onClick={() => setPasswordVisible(false)}
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  {form.formState.isDirty && (
                    <>
                      <Separator className="col-span-full" />
                      <Button type="submit" className="w-64" disabled={staffFormStatus === 'loading'}>
                        {staffFormStatus === 'loading' && <Loader className="size-4 mr-2 animate-spin" />}
                        Update
                      </Button>
                    </>
                  )}
                </div>
              </form>
            </Form>
          </CustomAccordion>
        </div>
      )}
    </>
  );
};
