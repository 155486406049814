export interface DocumentResourcesParams {
  country: string;
  state: string | null;
  city: string | null;
  module: string;
}

export interface DocumentCategory {
  label: string;
  value: string;
  order: number;
  documents: Document[];
}

export interface Document {
  name: string;
  value: string;
  order: number;
}

export interface DocumentResourceResponse {
  message: string;
  document_resource: DocumentResource;
}

export interface DocumentResource {
  id: string;
  country: string;
  state: string;
  city: null;
  module: string;
  other_information: DocumentCategory[];
  status: string;
  created_by: string;
  updated_by: string;
  deleted_by: null;
  created_at: Date;
  updated_at: Date;
  deleted_at: null;
}

export const MAX_FILE_SIZE = 2000000;

export const ACCEPTED_FILE_TYPES = {
  'image/jpeg': [],
  'image/jpg': [],
  'image/png': [],
  'image/webp': [],
  'application/pdf': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
  'text/plain': []
};
