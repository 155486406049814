import { cn } from '@/lib/utils';
import { Badge } from '@/common/presentation/components/registry/new-york/ui/badge';
import { ScrollArea } from '@/common/presentation/components/registry/new-york/ui/scroll-area';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/store';
import { NoteI } from '../../../domain/note.domain';
import { setNoteId, fetchGetStaffNotes } from '../../slices/NoteSlice';
import { useEffect, useState } from 'react';
import InfiniteScroll from '@/common/presentation/components/ui/infinite-scroll';
import { Loader2 } from 'lucide-react';
import { CrudState } from '@/modules/notes/domain/note.domain';
import NoteHelperService from '../../../infrastructure/services/NoteHelperService';
import { useTranslation } from 'react-i18next';


interface NoteListProps {
  items: NoteI[] | [];
  setCrudState: (crudState: CrudState) => void;
  crudState: CrudState;
  scroll?: boolean;
}

export function NoteStaffList({ items, setCrudState, crudState, scroll = true }: NoteListProps) {
  const { t } = useTranslation();

  // Redux
  const dispatch = useDispatch();
  const authUserId = useSelector((state: RootState) => state.auth.user?.id);
  const noteSelected = useSelector((state: RootState) => state.notes.noteId);
  const staff = useSelector((state: RootState) => state.staff.allStaff.staff);
  const selectedNotes = useSelector((state: RootState) => state.notes.selectedNotes);
  const metaNote = useSelector((state: RootState) => state.notes.staffNotes?.meta);

  // Hooks
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (metaNote && metaNote.page >= metaNote.lastPage) {
      setHasMore(false);
    }
  }, [metaNote]);

  //Behaviors
  const setNote = (noteId: string) => {
    dispatch(setNoteId(noteId));
  };

  const staffNames = staff.reduce((acc, item) => {
    acc[item.id] = item.first_name + ' ' + item.last_name;
    return acc;
  }, {});

  const fectchMoreNotes = async () => {
    setLoading(true);
    await dispatch<any>(fetchGetStaffNotes());
    setLoading(false);
  };

  if (!items) {
    return;
  }

  return (
    <ScrollArea className="h-[calc(100vh-15rem)]">
      <div className="flex flex-col gap-2 p-4 pt-0">
        {items?.map((item) => (
          <button
            key={item.id}
            className={cn(
              'flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all',
              noteSelected === item.id && 'bg-primary/10'
            )}
            onClick={() => {
              setCrudState(NoteHelperService.defineState({ showingStaffNotes: true }));
              setNote(item.id);
            }}
          >
            <div className="flex w-full flex-col gap-1">
              <div className="flex items-center">
                <div className="flex items-center gap-2">
                  <div className="font-semibold">
                    {item.staff_id && item.staff_id.length > 0
                      ? item.staff_id.map((id, index) => <div key={index}>{staffNames[id] || ''}</div>)
                      : t('notes.everyone')}
                  </div>
                  {!item && <span className="flex h-2 w-2 rounded-full bg-primary" />}
                </div>
                <div
                  className={cn(
                    'ml-auto text-xs',
                    noteSelected === item.id ? 'text-foreground' : 'text-muted-foreground',
                    selectedNotes.includes(item.id) && 'hidden'
                  )}
                >
                  {item.created_at}
                </div>
              </div>
              <div className="text-xs font-medium">{`${t('notes.by') + ' '}${item.author}`}</div>
            </div>
            <div className="text-xs text-muted-foreground text-ellipsis line-clamp-3" title={item.content} dangerouslySetInnerHTML={{ __html: item.html_content }}></div>
            {item.created_by == authUserId && (
              <div className="ml-auto">
                <Badge variant={'default'}>{t('notes.author')}</Badge>
              </div>
            )}
          </button>
        ))}

        {scroll && (
          <InfiniteScroll hasMore={hasMore} isLoading={loading} next={fectchMoreNotes} threshold={1}>
            {hasMore && <Loader2 className="my-4 h-8 w-8 animate-spin" />}
          </InfiniteScroll>
        )}
      </div>
    </ScrollArea>
  );
}
