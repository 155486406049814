import useRouteParams from '@/common/hooks/RouteParamsHook';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import { toast } from '@/common/presentation/components/ui/use-toast';
import * as staffConfigurationsActions from '@/modules/staff/presentation/slices/staffConfigurationsSlice';
import * as staffMemberActions from '@/modules/staff/presentation/slices/staffMemberSlice';
import { AppDispatch, RootState } from '@/store/store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProfilePicture } from '../components/ProfileStaffPicture';
import { CompanyForm } from '../components/StaffForm/CompanyForm';
import { CustomField } from '../components/StaffForm/CustomFields';
import { LoginInformationForm } from '../components/StaffForm/LoginInformation';
import { OtherForm } from '../components/StaffForm/OtherForm';
import { StaffPrincipalInformationForm } from '../components/StaffForm/StaffPrincipalInformation';
import { SwitchStaffStatus } from '../components/StaffForm/SwitchesStatus';
import { TerminationForm } from '../components/StaffForm/TerminationForm';
import { TrainingForm } from '../components/StaffForm/TrainingForm';

export const UpdateStaffMemberPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { params } = useRouteParams();
  const navigate = useNavigate();
  const { configurations } = useSelector((state: RootState) => state.staff.configurations);
  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);

  const [formsToShow, setFormsToShow] = useState<Record<string, boolean> | undefined>(undefined);
  const getStaffMember = async () => {
    const response = await dispatch(staffMemberActions.getStaffMemberById(params.sm));

    if (response?.meta.requestStatus === 'rejected') {
      toast({
        description: 'Failed to load staff member',
        variant: 'destructive'
      });
      navigate('/');
    }
  };

  useEffect(() => {
    getStaffMember();
  }, [params.sm]);

  useEffect(() => {
    if (configurations) {
      setFormsToShow(configurations.staff_form_cards);
    }
  }, [configurations]);

  useEffect(() => {
    if (Object.keys(configurations).length === 0) {
      dispatch(staffConfigurationsActions.getStaffConfigurations());
    }
  }, [dispatch]);

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full py-4 flex flex-col gap-6">
          <BackNavigationBar
            onlyBack={true}
            titleComponent={
              <h1 className="text-2xl font-semibold">
                Update Staff for{' '}
                <span className="font-bold">
                  {staffMember?.first_name} {staffMember?.middle_name} {staffMember?.last_name}
                </span>
              </h1>
            }
          />

          <div className="grid grid-cols-4 rounded-lg bg-secondary p-4 w-full gap-4">
            <div className="col-span-4 xl:col-span-1 flex flex-col gap-4">
              <ProfilePicture />
              {status === 'idle' && <SwitchStaffStatus defaultValues={{ status: staffMember?.status === 'active' }} />}
            </div>
            <div className="col-span-4  xl:col-span-3 ">
              <StaffPrincipalInformationForm />
            </div>
            {/* <div className="col-span-4">{formsToShow?.company && <CompanyForm className="col-span-4" />}</div>
            <div className="col-span-4">{formsToShow?.training && <TrainingForm className="col-span-4" />}</div>
            <div className="col-span-4">{formsToShow?.other && <OtherForm className="col-span-4" />}</div>
            <div className="col-span-4">{formsToShow?.custom_fields && <CustomField className="col-span-4" />}</div>
            <div className="col-span-4">{formsToShow?.termination && <TerminationForm className="col-span-4" />}</div> */}
            <div className="col-span-4">
              <LoginInformationForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
