import PropTypes from 'prop-types';
import { Phone, PhoneForwarded, Plus, Trash, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/common/presentation/components/ui/button';
import { getListPhonesItems } from '../../slices/contactsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PhoneInput } from '@/common/presentation/components/PhoneInput/phone-number-input';
import { useEffect } from 'react';

const ContactsPhonesCreate = ({ category, contactIndex, phones, handlePhoneInputChange, addPhone, removePhone }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const phoneCategories = useSelector((state) => state.contacts.phoneCategories); // Evitar undefined
  
  // Dispatch para obtener los datos del API
  useEffect(() => {
    if (!phoneCategories?.length) {
      dispatch(getListPhonesItems());
    }
  }, [dispatch, phoneCategories]);

  
  const countries = useSelector((state) => state.contacts.countries);
  const countriesCode = countries.map((country) => country.code.toUpperCase());

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneInputChangeWrapper = (category, contactIndex, phoneIndex, field, value) => {
    const formattedPhoneNumber = field === 'number' ? value : value;
    handlePhoneInputChange(category, contactIndex, phoneIndex, field, formattedPhoneNumber);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-bold">{t('contacts.phone')}</h3>
        <Button
          type="button"
          onClick={() => addPhone(category, contactIndex)}
          className="flex items-center px-3 py-2 text-white rounded"
        >
          <Plus className="mr-2" />
          {t('contacts.add_phone')}
        </Button>
      </div>
      {phoneCategories && phoneCategories.length > 0 ? (
      phones.map((phone, phoneIndex) => (
        <div key={phoneIndex} className="grid grid-cols-1 md:grid-cols-[2fr_3fr_2fr_auto] gap-6 mb-4 items-end">
          <div className="relative">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`phone-category-${category}-${contactIndex}-${phoneIndex}`}>
              {t('contacts.category')}
            </label>
            <div className="relative">
              <select
                id={`phone-category-${category}-${contactIndex}-${phoneIndex}`}
                value={phone.category}
                onChange={(e) => handlePhoneInputChange(category, contactIndex, phoneIndex, 'category', e.target.value)}
                className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                {phoneCategories.map((item) => (
                  <option key={item.id} value={item.name.toLowerCase()}>
                    {t(`contacts.${item.name.toLowerCase()}`) || item.name}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7 10l5 5 5-5z" /></svg>
              </div>
            </div>
          </div>
          {/* resto del código */}
        </div>
      ))
    ) : (
      <p>{t('contacts.loading')}</p> // Mensaje de carga o vacío
    )}
    </div>
  );
};

ContactsPhonesCreate.propTypes = {
  category: PropTypes.string.isRequired,
  contactIndex: PropTypes.number.isRequired,
  phones: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    number: PropTypes.string,
    ext: PropTypes.string,
  })).isRequired,
  handlePhoneInputChange: PropTypes.func.isRequired,
  addPhone: PropTypes.func.isRequired,
  removePhone: PropTypes.func.isRequired,
};

export default ContactsPhonesCreate;
