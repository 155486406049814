import React, { useState, useEffect } from 'react';
import { Input } from '@/common/presentation/components/ui/input';
import { Label } from '@/common/presentation/components/ui/label';
import { Button } from '@/common/presentation/components/ui/button';
import { Popover, PopoverContent } from '@/common/presentation/components/ui/popover';
import { PopoverTrigger } from '@/common/presentation/components/registry/default/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { Calendar } from '@/common/presentation/components/ui/calendar';
import { format } from 'date-fns';

interface TypeOptionsProps {
  type: string;
  onUpdateFormData: (updatedData: { special_day?: string[] | Date[]; text?: string; type?: string }) => void;
  special_days: {
    type?: string;
    special_day?: string[] | Date[];
    text?: string;
  };
}

const TypeOptions: React.FC<TypeOptionsProps> = ({ type, onUpdateFormData, special_days }) => {
  const [showOption, setShowOption] = useState<'every_day' | 'days_of_week' | 'calendar' | 'alternate_days'>(
    special_days.type || 'every_day'
  );
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState<string[]>(
    Array.isArray(special_days.special_day) && special_days.type === 'days_of_week'
      ? (special_days.special_day as string[])
      : []
  );
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(
    Array.isArray(special_days.special_day) && special_days.type === 'alternate_days' && special_days.special_day[0]
      ? new Date(special_days.special_day[0])
      : undefined
  );
  const [selectedDateRange, setSelectedDateRange] = useState<{ from?: Date; to?: Date }>({
    from:
      Array.isArray(special_days.special_day) && special_days.type === 'calendar' && special_days.special_day[0]
        ? new Date(special_days.special_day[0])
        : undefined,
    to:
      Array.isArray(special_days.special_day) && special_days.type === 'calendar' && special_days.special_day[1]
        ? new Date(special_days.special_day[1])
        : undefined
  });
  const [text, setText] = useState(special_days.text || '');

  // Automatically send "every_day" to parent if no data is provided by default
  useEffect(() => {
    if (!special_days.type) {
      onUpdateFormData({ special_day: 'every_day', text, type: 'every_day' });
    }
  }, [special_days, onUpdateFormData, text]);

  const handleDayChange = (day: string) => {
    setSelectedDays((prevDays) => (prevDays.includes(day) ? prevDays.filter((d) => d !== day) : [...prevDays, day]));
    onUpdateFormData({ special_day: selectedDays, text, type: showOption });
  };

  const handleCalendarChange = (range: { from?: Date; to?: Date }) => {
    setSelectedDateRange(range);
    if (range.from && range.to) {
      setIsPopoverOpen(false);
      onUpdateFormData({ special_day: [range.from, range.to], text, type: showOption });
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
    onUpdateFormData({ special_day: selectedDays, text: event.target.value, type: showOption });
  };

  const handleOptionChange = (option: 'every_day' | 'days_of_week' | 'calendar' | 'alternate_days') => {
    setShowOption(option);
    setIsPopoverOpen(option === 'calendar' || option === 'alternate_days'); // Opens popover only for calendar or alternate_days

    if (option === 'alternate_days') {
      const today = new Date();
      const alternateDays = [];

      for (let i = 0; i < 14; i += 2) {
        const nextDay = new Date(today);
        nextDay.setDate(today.getDate() + i);
        alternateDays.push(nextDay);
      }

      onUpdateFormData({ special_day: alternateDays, text, type: option });
    } else {
      setSelectedDate(undefined);
      setSelectedDateRange({ from: undefined, to: undefined });
      onUpdateFormData({
        special_day: option === 'every_day' ? 'every_day' : option === 'days_of_week' ? selectedDays : [],
        text,
        type: option
      });
    }
  };

  const handleAlternateDaysClick = () => {
    handleOptionChange('alternate_days');
  };

  return (
    <div className="p-6 border border-gray-300 rounded-lg shadow-sm bg-gray-50 mb-4">
      <h2 className="text-lg font-semibold mb-4">Select Type of Days</h2>
      <div className="mb-6">
        <div className="mt-3 flex flex-wrap items-center gap-4">
          <div className="flex items-center">
            <Input
              id="every_day"
              type="radio"
              name="days_option"
              value="every_day"
              checked={showOption === 'every_day'}
              onChange={() => handleOptionChange('every_day')}
              className="form-radio h-5 w-5"
            />
            <Label htmlFor="every_day" className="ml-2 text-gray-700 cursor-pointer">
              Every Day
            </Label>
          </div>
          <div className="flex items-center">
            <Input
              id="alternate_days"
              type="radio"
              name="days_option"
              value="alternate_days"
              checked={showOption === 'alternate_days'}
              onChange={handleAlternateDaysClick}
              className="form-radio h-5 w-5"
            />
            <Label htmlFor="alternate_days" className="ml-2 text-gray-700 cursor-pointer">
              Every Other Day
            </Label>
          </div>
        </div>
        <div className="mt-3 flex flex-wrap items-center gap-4">
          <div className="flex items-center">
            <Input
              id="days_of_week"
              type="radio"
              name="days_option"
              value="days_of_week"
              checked={showOption === 'days_of_week'}
              onChange={() => handleOptionChange('days_of_week')}
              className="form-radio h-5 w-5"
            />
            <Label htmlFor="days_of_week" className="ml-2 text-gray-700 cursor-pointer">
              Days of the Week
            </Label>
          </div>
          <div className="flex items-center">
            <Input
              id="calendar"
              type="radio"
              name="days_option"
              value="calendar"
              checked={showOption === 'calendar'}
              onChange={() => handleOptionChange('calendar')}
              className="form-radio h-5 w-5"
            />
            <Label htmlFor="calendar" className="ml-2 text-gray-700 cursor-pointer">
              According to the calendar
            </Label>
          </div>
        </div>
      </div>

      {/* Days of the Week Section */}
      {showOption === 'days_of_week' && (
        <div className="grid grid-cols-7 gap-2 mb-6">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <Button
              key={day}
              className={`py-2 px-3 rounded-md border ${
                selectedDays.includes(day) ? 'bg-blue-500 text-white' : 'bg-white text-gray-700 hover:bg-gray-100'
              }`}
              onClick={() => handleDayChange(day)}
            >
              {day}
            </Button>
          ))}
        </div>
      )}

      {/* Alternate Days Section */}
      {showOption === 'alternate_days' && (
        <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <PopoverTrigger asChild>
            <Button
              id="date"
              variant={'outline'}
              className={`w-full justify-start text-left font-normal ${!selectedDate ? 'text-muted-foreground' : ''}`}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {selectedDate ? format(selectedDate, 'LLL dd, y') : <span>Select a date</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={selectedDate}
              onSelect={(date) => {
                setSelectedDate(date);
                onUpdateFormData({ special_day: [date], text, type: showOption });
              }}
              initialFocus
            />
          </PopoverContent>
        </Popover>
      )}

      {/* Calendar Section */}
      {showOption === 'calendar' && (
        <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <PopoverTrigger asChild>
            <Button
              id="date"
              variant={'outline'}
              className={`w-full justify-start text-left font-normal ${
                !selectedDateRange?.from ? 'text-muted-foreground' : ''
              }`}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {selectedDateRange?.from ? (
                selectedDateRange.to ? (
                  <>
                    {format(selectedDateRange.from, 'LLL dd, y')} - {format(selectedDateRange.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(selectedDateRange.from, 'LLL dd, y')
                )
              ) : (
                <span>Select a date</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={selectedDateRange?.from}
              selected={selectedDateRange}
              onSelect={handleCalendarChange}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>
      )}

        <div className="mb-6">
          <Label htmlFor="text">Special Instructions:</Label>
          <textarea
            id="text"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
            rows={3}
            value={text}
            onChange={handleTextChange}
            placeholder="Enter special instructions (optional)"
          ></textarea>
        </div>
    </div>
  );
};

export default TypeOptions;
