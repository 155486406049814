import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  fetchResidentMedicationById,
  fetchTypes,
  getResidentMedicationTime,
  editResidentMedicationById,
  fetchForms
} from '../../slices/medicationsSlice';
import { RootState } from '@/store/store';
import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { ArrowLeft, Workflow } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { CustomImages } from '../CustomImages';
import MedicationsOptional from './MedicationOptional';
import MedicationFormSelect from './MedicationFormSelect';
import ChartRX from './ChartRX';
import { useTranslation } from 'react-i18next';
import { toast } from '@/common/presentation/components/ui/use-toast';
import { websocket } from '@/utils/helpers/websocket.helper';
import columnsChart from './columnChartRX';
import { Badge } from '@/common/presentation/components/ui/badge';
import LastDispensing from './LastDispensing';

const MedicationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { medicationId } = useParams();
  const [listSelectForm, setlistSelectForm] = useState<string[]>([]);
  const [residentId, setResidentId] = useState(null);
  const [currentListForm, setCurrentListForm] = useState([]);
  const { types, residentMedicationTime, medicationsResident, forms, status } = useSelector(
    (state: RootState) => state.medications
  );

  const { control, handleSubmit, setValue, reset, watch } = useForm({
    defaultValues: useMemo(
      () => ({
        medication: '',
        form: [],
        strength: '',
        purpose: '',
        not_exceed: '',
        frequency: '',
        instruction: '',
        special: '',
        date_prescribed: '',
        current_quantity: 0,
        minimum_quantity: 0,
        units_per_dose: 0,
        type: '',
        location_type_id: '',
        responsible_administrations_id: '',
        routes_medication_id: '',
        resident_id: '',
        status: '',
        typeName: '',
        selectedMedication: {} as [],
        special_days: [],
        location_id: '',
        chart: [],
        physician_id: '',
        prescriber_npi: '',
        physician_typeName: '',
        physician_type_id: '',
        content_notes: '',
        pharmacy_id: '',
        time: []
      }),
      []
    )
  });

  const pharmacyId = watch('pharmacy_id');
  const chartData = watch('chart');
  const strength = watch('strength');

  const [images, setImages] = useState<File[]>([]);
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [typeName, setTypeName] = useState('');

  const handleImageChange = useCallback((file: File) => {
    setImages((prevImages) => [...prevImages, file]);
  }, []);

  const handleSelectedTimesChange = useCallback(
    (selectedTimes: string[]) => {
      setSelectedTimes(selectedTimes);
      setValue('time', selectedTimes);
    },
    [setValue]
  );

  const handleSelect = useCallback(
    (option: any) => {
      setValue('medication_id', option.id);
      setValue('form', option.forms || []);
      setValue('strength', option.strengths || []);
    },
    [setValue]
  );

  const handleBackClick = useCallback(() => navigate(-1), [navigate]);

  useEffect(() => {
    if (medicationId) {
      dispatch(fetchResidentMedicationById(medicationId)).then((response) => {
        if (response.payload) {
          const fetchedResidentId = response.payload.resident_id;
          setResidentId(fetchedResidentId);

          dispatch(fetchTypes({ locationId: medicationsResident.location_id }));
          dispatch(fetchForms());
        }
      });
    }
  }, [medicationId]);

  useEffect(() => {
    if (residentId) {
      dispatch(getResidentMedicationTime(residentId));
    }
  }, [residentId, dispatch]);

  useEffect(() => {
    const handleSocketMessage = (data) => {
      if (data?.message?.chart) {
        const updatedChartData = data.message.chart.map((item) => ({
          ...item,
          status: item.status
        }));
        setValue('chart', updatedChartData);
      }
    };

    const socket = websocket.subscribe(`resident-medication-channel-${medicationId}`, handleSocketMessage);

    return () => {
      if (websocket.unsubscribe) {
        websocket.unsubscribe(socket);
      }
    };
  }, [medicationId, setValue]);

  useEffect(() => {
    if (medicationsResident) {
      const upperCaseForm = medicationsResident?.form ? medicationsResident.form.toUpperCase() : null;
      const listForm = forms.find((form) => form.name === upperCaseForm);

      if (listForm) {
        setlistSelectForm(forms);
        setCurrentListForm(listForm);
      } else {
        const formValue = medicationsResident?.medication?.form
          ? medicationsResident.medication.form
              .toLowerCase()
              .split(',')
              .map((item) => item.trim())
          : [];
        setlistSelectForm(formValue);
      }

      reset({
        ...medicationsResident,
        medication: medicationsResident.medication?.name || '',
        type: medicationsResident?.type_medication_id || '',
        typeName: medicationsResident?.location_type_medication?.type_medication?.name || '',
        location_type_id: medicationsResident.location_type_id || '',
        responsible_administrations_id: medicationsResident.responsible_administrations_id || '',
        routes_medication_id: medicationsResident.routes_medication_id || '',
        special_days: medicationsResident.special_days || [],
        location_id: medicationsResident.location_id || '',
        chart: medicationsResident.chart || [],
        physician_id: medicationsResident.physician?.id || '',
        prescriber_npi: medicationsResident.physician?.npi || '',
        physician_type_id: medicationsResident.physician?.physician_type_id || '',
        physician_typeName: medicationsResident.physician_type || '',
        content_notes: medicationsResident.notes?.content || '',
        pharmacy_id: medicationsResident.pharmacy?.id || '',
        time: medicationsResident.time || [],
        date_prescribed: medicationsResident.date_prescribed || '',
        purpose: medicationsResident.purpose || '',
        minimum_quantity: medicationsResident.minimum_quantity || 0,
        form: listForm ? medicationsResident.form : medicationsResident.form?.toLowerCase(),
        strength: medicationsResident?.strength
      });
    }
  }, [medicationsResident, reset]);

  const onSubmit = async (data: any) => {
    try {
      const response = await dispatch(editResidentMedicationById({ id: medicationId, data }));
      if (!response.error) {
        toast({ description: 'Actualización realizada con éxito!', variant: 'success' });
        await dispatch(fetchResidentMedicationById(medicationId));
      }
    } catch (error) {
      console.error('Error al actualizar los datos:', error);
      toast({ description: error.message, variant: 'destructive' });
    }
  };

  const getDefaultRow = () => {
    const defaultRow = {};
    columnsChart.forEach((column) => {
      switch (column.type) {
        case 'checkbox':
          defaultRow[column.value] = false;
          break;
        case 'calendar':
          defaultRow[column.value] = '';
          break;
        case 'number':
          defaultRow[column.value] = 0;
          break;
        default:
          defaultRow[column.value] = '';
      }
    });
    return defaultRow;
  };

  const handleAddRow = useCallback(() => {
    const newRow = getDefaultRow();
    const updatedChart = [newRow, ...chartData];
    setValue('chart', updatedChart);
  }, [chartData, setValue]);

  console.log(medicationsResident);
  return (
    <div className="gap-4 my-4">
      <div>
        <div className="flex flex-col gap-4 my-4">
          <div className="flex items-center mb-4">
            <Button variant="ghost" onClick={handleBackClick}>
              <ArrowLeft className="mr-2 h-4 w-4" />
              {t('medications.back')}
            </Button>
            <div className="text-xl text-primary font-semibold ml-4">{t('medications.detailsTitle')}</div>
          </div>

          <CustomAccordion
            defaultOpen
            title="Medications"
            description="Information about the prescribing physician and pharmacy."
          >
            <MedicationFormSelect
              control={control}
              handleSelect={handleSelect}
              types={types}
              selectedTimes={watch('time')}
              handleSelectedTimesChange={handleSelectedTimesChange}
              handleSelectedTypeChange={setTypeName}
              typeName={watch('typeName')}
              selectedListForm={listSelectForm || []}
              medicationTimes={residentMedicationTime}
              setValue={setValue}
              strength={strength}
            />
            <div className="flex justify-end p-6">
              <Button onClick={handleSubmit(onSubmit)} type="submit" className="bg-primary text-white">
                {t('medications.update')}
              </Button>
            </div>
          </CustomAccordion>
          <CustomAccordion
            title="Medication Information"
            description="Additional details about the prescribing physician and pharmacy."
          >
            {medicationsResident && (
              <MedicationsOptional
                residentId={medicationsResident.resident_id}
                locationId={medicationsResident.location_id}
                control={control}
                handleSubmit={handleSubmit}
                setValue={setValue}
                watch={watch}
              />
            )}
            <div className="flex justify-end p-6">
              <Button onClick={handleSubmit(onSubmit)} type="submit" className="bg-primary text-white">
                {t('medications.update')}
              </Button>
            </div>
          </CustomAccordion>
          <CustomAccordion title="RX Chart" description="Additional details about the medication.">
            <div className="max-h-96 overflow-y-auto">
              {' '}
              <ChartRX control={control} fields={chartData} onAddRow={handleAddRow} setValue={setValue} />
            </div>
            <div className="flex justify-end p-6">
              <Button onClick={handleSubmit(onSubmit)} type="submit" className="bg-primary text-white">
                {t('medications.update')}
              </Button>
            </div>
          </CustomAccordion>

          {medicationsResident?.is_from_pharmacy && (
            <CustomAccordion
              titleComponent={
                <div className="flex flex-row justify-between gap-2 items-center">
                  <span className="text-xl text-primary font-semibold">
                    {t('medications.lastDispensingInformation')}
                  </span>
                  <Badge variant="outline" className="h-7 text-xs text-primary border-primary">
                    <Workflow size={15} />
                    {t('medications.integration')}
                  </Badge>
                </div>
              }
            >
              <LastDispensing
                status={status === 'loading' || medicationsResident.length === 0}
                dispensingHistorical={medicationsResident?.dispensing_historical ?? []}
              />
            </CustomAccordion>
          )}

          {/* <CustomAccordion title="Images" description="Additional notes for the medication.">
            <div className="mb-4">
              <CustomImages onChange={handleImageChange} />
            </div>
            <div className="flex justify-end p-6">
              <Button onClick={handleSubmit(onSubmit)} type="submit" className="bg-primary text-white">
                {t('medications.update')}
              </Button>
            </div>
          </CustomAccordion> */}
        </div>
      </div>
    </div>
  );
};

export default MedicationDetails;
