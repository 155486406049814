import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from '@/common/presentation/components/ui/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '@/common/presentation/components/ui/table';
import useRouteParams from '@/common/hooks/RouteParamsHook';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { ResidentSelector } from '@/common/presentation/components/Selectors/ResidentSelector';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, TabsList, TabsTrigger } from '@/common/presentation/components/ui/tabs';
import { RootState } from '@/store/store';
import { Card, CardContent, CardHeader, CardTitle } from '@/common/presentation/components/ui/card';
import TrackerService from '../../infrastructure/services/TrackerService';
import TrackerHygieneType from './TrackerHygieneType';
import { Input } from '@/common/presentation/components/ui/input';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from '@/common/presentation/components/ui/dropdown-menu';
import { Pencil, Trash, Ellipsis, Settings, Search } from 'lucide-react';
import * as locationsActions from '@/modules/locations/presentation/slices/locationsSlice';
import { CustomDialog } from '@/common/presentation/components/CustomDialog/CustomDialog';
import { Label } from '@/common/presentation/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/common/presentation/components/ui/select';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/common/presentation/components/ui/popover";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { CustomCalendar } from '@/common/presentation/components/CustomCalendar/CustomCalendar';
import { ImageCardTables } from '@/common/presentation/components/Selectors/ImageCardTables';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { CircleFadingPlus } from "lucide-react";
import { useDateHelpers } from '@/utils/helpers/dates.helper';
import useHygineTypeColumns from '../components/hygine/HygineTypeColumns';
import TableCustomVirtuoso from '@/common/presentation/components/Table/TableCustomVirtoso';
import useHygineReportColumns from '../components/hygine/HygineReportColumns';
import RenderHygineReportActionsColumns from '../components/hygine/RenderHygineReportActionsColumns';
import { getHygiene } from '../slices/TrackerHygieneSlice';
import TrackerHygieneDetailed from '../components/hygine/TrackerHygieneDetailed';

interface TypeI {
    id: string;
    name: string;
    status: 'active' | 'inactive';
}

interface TypesI {
    column_field: TypeI[];
    column_type: string[];
}

interface TrackerI {
    id: string;
    location_id: string;
    created_at: string;
    data: TrackerDataI;
}

interface TrackerDataI {
    type_name?: string;
    detail: string | null;
    location_id: string;
    type: string;
    value: string;
    id: string;
    resident: {
        id: string;
        first_name: string;
        last_name: string;
    };
    who: {
        id: string;
        first_name: string;
        last_name: string;
    };
    date: string;
}

function TrackerHygiene() {
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);
    const { locations } = useSelector((state: RootState) => state.locations.allLocations);
    const { params, setParam } = useRouteParams();
    const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
    const [types, setTypes] = useState<TypeI[]>([]);
    const [dataReport, setDatareport] = useState<TrackerI[]>([]);
    const dispatch = useDispatch();
    const { tracker } = useSelector((state: RootState) => state.tracker);
    const [dialogTitle, setDialogTitle] = useState<string>('');
    const [dialogDescription, setDialogDescription] = useState<string>('');
    const [formValue, setFormValue] = useState<any>({});
    const [action, setAction] = useState('');
    const [selectedHygiene, setSelectedHygiene] = useState<string>(params.e || 'form');
    const { residents, status } = useSelector((state: RootState) => state.residents.allResidents);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [select, setSelect] = useState<string[]>([]);
    const [data, setData] = useState([]);
    const [formData, setFormData] = React.useState<{ [key: string]: string }>({});
    const [selectedTab, setSelectedTab] = useState<string>("quick");
    const trackerHygiene = useSelector((state: RootState) => state.trackerHygiene);
    const { locationSelectedId } = useLocation();
    const { t } = useTranslation();
    const [accentColor, setAccentColor] = useState("");
    const configurations = useSelector((state: RootState) => state.configurations);
    const [columns, setColumns] = useState(null);
    const [fields, setDialogField] = useState(false);
    const onSelectHygiene = (e: string) => {
        setParam('e', e);
        setSelectedHygiene(e);
    };
    const onResidentSelect = (resident: any) => {
        setParam('r', resident);
    };
    const [hygineReportColumns] = useState(
        useHygineReportColumns().map((value) => {
            if (value.key === "actions") {
                return {
                    ...value,
                    render: (rowData: any) => RenderHygineReportActionsColumns(rowData, handleButtonOpenDialog.bind(this))
                };
            }

            return value;
        })
    );

    useEffect(() => {
        setColumns(newColumns());
        setData(createData());
    }, []);

    useEffect(() => {
        if (configurations.configurations) setAccentColor(configurations.configurations.accent_color);
    }, [configurations]);

    useEffect(() => {
        if (!params?.e || params.e.length === 0) {
            setParam('e', 'form');
            setSelectedHygiene('form');
        }

        if (!locationSelectedId || locationSelectedId.length === 0) {
            setLocation(locationSelectedId ?? 'all-locations');
            setParam('l', locationSelectedId ?? 'all-locations');
        }

    }, [params]);

    useEffect(() => {
        if (formValue && Object.keys(formValue).length > 0) {
            setFormData(formValue);
        }
    }, [formValue]);

    useEffect(() => {
        dataHygieneReport();
    }, [params.l]);

    useEffect(() => {
        if (params.l && locations.length === 0) {
            const fetchData = async () => {
                const response = await dispatch(locationsActions.getLocations());
                if (response.payload) {
                    const selectedLocations =
                        locationSelectedId === 'all-locations'
                            ? response.payload
                            : response.payload.filter((l: any) => l.location.id === locationSelectedId);
                    setSelectedLocations(selectedLocations.map((l: any) => l.location));
                }
            };

            fetchData();
        }

        if (!locationSelectedId && locations.length === 1) {
            setSelectedLocations(locations);
            setLocation(locations[0].id);
            setParam('l', locations[0].id);
        }

        setSelectedLocations(locationSelectedId === 'all-locations' ? locations : locations.filter((l) => l.id === locationSelectedId));
        setLocation(locationSelectedId);

        if (locationSelectedId && !params.e) {
            setParam('e', 'form');
        }
    }, [params]);

    useEffect(() => {
        if (trackerHygiene.hygienes) {
            const { types, trackerHygienes }: { types: TypesI, trackerHygienes: TrackerI[] } = trackerHygiene.hygienes;

            if (types && types.column_field) {
                setTypes(types.column_field || []);
                setSelect(
                    [
                        "Done",
                        "Refused"
                    ]
                );

                if (trackerHygienes && trackerHygienes.length) {
                    setDatareport(
                        trackerHygienes.map(row => ({
                            ...row,
                            data: {
                                ...row.data,
                                type_name: types.column_field.find(type => type.id === row.data.type)?.name || 'Unknown'
                            }
                        })));

                }
            }
        }

        setColumns(newColumns());
        setData(createData());
    }, [trackerHygiene]);

    useEffect(() => {
        if (!dataReport) {
            dataHygieneReport();
        }
    }, [dataReport]);

    useEffect(() => {
        setData(createData());
    }, [types, dataReport, residents]);

    const newColumns = () => {
        const firstObject = {
            label: t('trackers.type'),
            value: "type",
            type: "texto"
        };

        return [firstObject, ...residents.map((resident: { name: any; }) => ({
            label: (
                <div>
                    <ImageCardTables
                        item={{
                            id: resident.id,
                            firstName: resident.first_name,
                            lastName: resident.last_name,
                            image: resident.image_url
                        }}
                        aspectRatio={'rounded'}
                    />
                </div>
            ),
            value: resident.first_name,
            type: 'text'
        }))];
    };

    const renderInputForColumn = (column, index) => {
        switch (column.type) {
            case 'text':
                return (
                    <input
                        id={column.fieldName}
                        name={column.fieldName}
                        type="text"
                        value={formData[column.fieldName] || ''}
                        onChange={handleChange}
                        style={{ outline: 'none' }}
                        className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                    />
                );
            case 'date':

                if (formData[column.fieldName] != undefined) {
                    const dateString = formData[column.fieldName];
                    const parsedDate = new Date(dateString);
                    const formattedDate = format(parsedDate, "dd/MM/yyyy hh:mm a");
                    return <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={"outline"}
                                className={`w-[280px] justify-start text-left font-normal`}>
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {formattedDate}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <CustomCalendar
                                id={column.fieldName}
                                name={column.fieldName}
                                selectedDate={parsedDate}
                                onChange={date => handleDateChange(column.fieldName, date)}
                                MonthAndYearPicker={true}
                                timePicker={true}
                            />
                        </PopoverContent>
                    </Popover>
                }
            case 'textarea':
                return <textarea
                    id={column.fieldName}
                    name={column.fieldName}
                    value={formData[column.fieldName] || ''}
                    onChange={handleChange}
                    style={{ outline: 'none' }}
                    className="col-span-3 px-2 py-2 bg-gray-100 rounded-md"
                />;
            case 'select':
                return (
                    <Select value={formData.type} onValueChange={(value) => handleSelectChange('type', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {types.column_field.find((option) => option.id === formData.type)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            {types.column_field.map((option) => (
                                <SelectItem key={option.id} value={option.id}>
                                    {option.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                );
            case 'selectval':
                return (
                    <Select value={formData.value} onValueChange={(value) => handleSelectChange('value', value)}>
                        <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                            <SelectValue placeholder={column.label}>
                                {types.column_field.find((option) => option.id === formData.value)?.name}
                            </SelectValue>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem key="done" value="done">
                                Done
                            </SelectItem>
                            <SelectItem key="refused" value="refused">
                                Refused
                            </SelectItem>
                        </SelectContent>
                    </Select>
                );
            default:
                return null;
        }
    };

    const dataHygieneReport = async () => {
        dispatch(getHygiene(
            {
                location_id: locationSelectedId
            }
        ));
    };

    const createData = () => {
        const columnField = types;

        if (!types) return [];

        const groupedData = {};

        const today = new Date().toISOString().split('T')[0];

        const isReportChecked = (residentId: string, value: string, typeId: string) => {
            if (!dataReport) {
                return '';
            } else {

                return Array.isArray(dataReport) && dataReport.some((report) => {
                    const reportDate = new Date(report.created_at).toISOString().split('T')[0];
                    return report.data.resident.id === residentId &&
                        report.data.value === value &&
                        reportDate === today &&
                        report.data.type === typeId
                })
            }

        };

        const handleSelectChange = (typeId, residentId, value) => {
            buttonSumbit({
                typeId,
                residentId,
                status: value,
            });
        };

        residents.forEach((resident) => {
            columnField.forEach((field) => {
                if (field.status === "inactive") {
                    return;
                }
                const type = field.name;
                const typeid = field.id;

                const currentValue = select
                    .find(option => isReportChecked(resident.id, option, typeid)) || '';
                if (!groupedData[type]) {
                    groupedData[type] = { type: type };
                }


                groupedData[type][resident.first_name] = (
                    <div className="flex justify-center" key={`${type}-${resident.first_name}`}>
                        <div className="text-center flex-1">
                            <Select
                                value={currentValue}
                                onValueChange={(value) => handleSelectChange(typeid, resident.id, value)}
                            >
                                <SelectTrigger className="col-span-3 px-2 py-1 bg-gray-100 rounded-md focus:outline-none">
                                    <SelectValue placeholder="">
                                        {currentValue ? currentValue.charAt(0).toUpperCase() + currentValue.slice(1) : ' '}
                                    </SelectValue>
                                </SelectTrigger>

                                <SelectContent>
                                    {select.map((option, index) => {
                                        return (
                                            <SelectItem key={index} value={option}>
                                                {option}
                                            </SelectItem>
                                        );
                                    })}
                                </SelectContent>
                            </Select>
                        </div>
                    </div>
                );
            });
        });

        const newData = Object.values(groupedData);
        return newData;
    };

    const buttonSumbit = async ({ typeId, residentId, status }: { typeId: string; residentId: string; status: string; }) => {
        const today = new Date().toISOString().split('T')[0];

        if (Array.isArray(dataReport)) {
            const foundReport = dataReport.find(report => {
                const reportDate = new Date(report.created_at).toISOString().split('T')[0];
                return report.data.resident.id === residentId &&
                    reportDate === today &&
                    report.data.type === typeId
            });

            if (foundReport) {
                const json: { [key: string]: any } = {};
                json["id"] = foundReport.id;
                json["id_data"] = foundReport.data.id;
                json["data"] = {
                    "type": typeId,
                    "detail": status,
                    "value": status,
                    "resident_id": residentId,
                    "date": foundReport.data.date
                };
                const response = await TrackerService.updateTrackerHygiene(json);
                dataHygieneReport();
                setData(createData());
            } else {
                const json = [{
                    type: typeId,
                    value: status,
                    resident_id: residentId,
                    detail: " ",
                    location_id: params.l
                }];
                const response = await TrackerService.saveTrackerHygiene(json);
                dataHygieneReport();
                setData(createData());
            }
        } else {
            const json = [{
                type: typeId,
                value: status,
                resident_id: residentId,
                detail: " ",
                location_id: params.l
            }];
            const response = await TrackerService.saveTrackerHygiene(json);
            dataHygieneReport();
            setData(createData());;
        }

    };

    const handleButtonOpenDialog = (editFields: any[], title: string, row: any[], description: string) => {
        setShowEditDialog(true);
        setFormValue(row);
        setDialogField(editFields);
        setDialogTitle(title);
        setDialogDescription(description);
        setAction(title);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (name, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleDateChange = (name, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value instanceof Date && !isNaN(value) ? value : null
        }));
    };

    const onSelectedTab = (event: string) => {
        setSelectedTab(event);
    }

    const handleCloseEditDialog = () => {
        setShowEditDialog(false);
    };

    const handleSubmit = async () => {
        const json: { [key: string]: any } = {};

        if (!(action === 'Edit')) {
            json["id"] = formData.id;
            json["id_data"] = formData.data.id;

            await TrackerService.destroyTrackerHygiene(json);
        }

        setShowEditDialog(false);

        dataHygieneReport();
    };

    return (
        <div>
            <LocationSelector locationId={location ?? 'all-locations'} quickView={true} />
            {
                locationSelectedId && (
                    <div className="my-4 flex items-center justify-between">
                        <div className="flex flex-col gap-2">
                            <div className="font-semibold text-2xl" style={{ color: accentColor }}>{t('trackers.trackerhygiene.title')}</div>
                            <div className="font-semibold text-1xl">{t('trackers.trackerhygiene.description')}</div>
                        </div>

                        <Tabs defaultValue={selectedHygiene} onValueChange={(e) => { onSelectHygiene(e); }}
                            className="w-auto">
                            <TabsList>
                                <TabsTrigger value="form" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <CircleFadingPlus className="h-4 w-4 mr-1" />
                                    {t("trackers.trackerhygiene.new")}
                                </TabsTrigger>

                                <TabsTrigger value="report" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    {t('trackers.Report')}
                                </TabsTrigger>

                                <TabsTrigger value="type" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                    <Settings className='w-5 h-5' />
                                </TabsTrigger>
                            </TabsList>

                        </Tabs>
                    </div>

                )
            }

            {
                selectedHygiene === 'form' && (
                    <div>
                        {
                            selectedTab === "detailed" && (
                                <div className='mb-3'>
                                    <ResidentSelector
                                        onSelect={onResidentSelect}
                                        locationId={location}
                                        residentId={params.r}
                                        showAllResidents={false}
                                    />
                                </div>
                            )
                        }

                        <Card className="border-t-4 border-t-primary/50">
                            <CardHeader>
                                <div className='flex justify-between'>
                                    <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>
                                        {
                                            selectedTab === "quick" ? (
                                                <span>
                                                    {t("trackers.trackerhygiene.quickTracker")}
                                                </span>
                                            ) : (
                                                <span>
                                                    {t("trackers.trackerhygiene.detailedTracker")}
                                                </span>
                                            )
                                        }
                                    </CardTitle>

                                    <Tabs defaultValue={selectedTab} onValueChange={(event) => { onSelectedTab(event); }} className="w-auto">
                                        <TabsList>
                                            <TabsTrigger value="quick" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                                {t("trackers.trackerhygiene.quick")}
                                            </TabsTrigger>

                                            <TabsTrigger value="detailed" className="data-[state=active]:bg-primary/80 data-[state=active]:text-white">
                                                {t("trackers.trackerhygiene.detailed")}
                                            </TabsTrigger>
                                        </TabsList>
                                    </Tabs>
                                </div>
                            </CardHeader>

                            <CardContent>
                                <div>
                                    {
                                        locationSelectedId && selectedTab === "quick" && (
                                            <div className="flex flex-col gap-4">
                                                <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                                    <Table className="shadow-lg overflow-hidden">
                                                        <TableHeader className="text-center">
                                                            <TableRow className="py-2 px-4 text-center bg-primary/80 text-white rounded-t-md hover:bg-primary/80">
                                                                {
                                                                    columns && columns.length ? (
                                                                        columns.map((column, index) => (
                                                                            <TableHead
                                                                                className={`text-${index === 0 ? 'left' : 'center'} text-white`}
                                                                                key={column.value}
                                                                                style={{ position: 'sticky', top: 0, zIndex: 1, background: 'inherit' }} // Sticky header styles
                                                                            >
                                                                                {column.label}

                                                                            </TableHead>
                                                                        ))
                                                                    ) : null
                                                                }
                                                            </TableRow>
                                                        </TableHeader>

                                                        <TableBody className="bg-white dark:bg-background">
                                                            {
                                                                data.map((row, index) => (
                                                                    <TableRow key={index} className="hover:bg-gray-100 dark:hover:bg-gray-800 border-b border-gray-300">
                                                                        {
                                                                            columns && columns.length ? (
                                                                                columns.map((column, columnIndex) => (
                                                                                    <TableCell
                                                                                        key={columnIndex}
                                                                                        className={`py-4 px-4 ${columnIndex !== 0 && 'border-l border-gray-300'}`}
                                                                                        style={{
                                                                                            position: columnIndex === 0 ? 'sticky' : 'relative', // Sticky first column
                                                                                            left: columnIndex === 0 ? 0 : 'auto',
                                                                                            background: columnIndex === 0 ? 'inherit' : 'none',
                                                                                            zIndex: columnIndex === 0 ? 1 : 'auto',
                                                                                        }}
                                                                                    >
                                                                                        {row[column.value as keyof typeof row]}
                                                                                    </TableCell>
                                                                                ))
                                                                            ) : null
                                                                        }
                                                                    </TableRow>
                                                                ))
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </div>

                                                <div className="flex justify-end mb-4">
                                                    <button className="p-2 bg-primary text-white rounded" onClick={() => window.location.reload()}>{t('trackers.Save Quick Tracker')}</button>
                                                </div>
                                            </div>
                                        )
                                    }

                                    {
                                        locationSelectedId && selectedTab === "detailed" && (
                                            <TrackerHygieneDetailed />
                                        )
                                    }
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )
            }

            {
                selectedHygiene === 'report' && (
                    <div>
                        <Card className="border-t-4 border-t-primary/50">
                            <CardHeader>
                                <CardTitle className="flex flex-row items-center text-lg font-bold" style={{ color: accentColor }}>{t('trackers.trackerhygiene.report')}</CardTitle>
                            </CardHeader>

                            <CardContent>
                                <div className="border border-solid border-gray-300 border-opacity-50 rounded-md overflow-hidden">
                                    <CustomDialog
                                        width="30rem"
                                        newDialog={true}
                                        isOpen={showEditDialog}
                                        onClose={handleCloseEditDialog}
                                        title={dialogTitle}
                                        onSubmit={handleSubmit}
                                        description={dialogDescription}
                                    >
                                        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                                            <div className="grid gap-4">
                                                {fields && fields.map((field, index) => (
                                                    <div key={field.fieldName} className="grid grid-cols-4 items-center gap-4">
                                                        <Label htmlFor={field.fieldName} className="text-right">{field.label}</Label>
                                                        {renderInputForColumn(field, index)}
                                                    </div>
                                                ))}
                                            </div>

                                            <div className='flex justify-end'>
                                                <Button type='submit'>Save</Button>
                                            </div>
                                        </form>
                                    </CustomDialog>

                                    <TableCustomVirtuoso
                                        data={dataReport}
                                        columns={hygineReportColumns}
                                        renderCellContent={(index, column, data) => data[index][column.key]}
                                        additionalButtons={<></>}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )
            }

            {
                selectedHygiene === 'type' && (
                    <div>
                        {
                            <div>
                                <TrackerHygieneType dataHygieneReport={dataHygieneReport} />
                            </div>
                        }
                    </div>
                )
            }

        </div >
    )
}

export default TrackerHygiene;
