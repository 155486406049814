import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { FormControl, FormField, FormItem, FormLabel, Form } from "@/common/presentation/components/ui/form";
import { Switch } from "@/common/presentation/components/ui/switch";
import { Button } from "@/common/presentation/components/ui/button";
import { useLocation } from '@/modules/locations/infrastructure/providers/LocationContextProvider';
import { getEnabledTrackers, update, trackerConfigurationLoading } from "@/modules/settings/presentation/components/menus/trackers/slices/TrackerConfigurationSlice";
import { Spinner } from '@/common/presentation/components/SplashScreen/components/spinner';

import * as configActions from '@/common/presentation/slices/configurationSlice';
import { AppDispatch, RootState } from '@/store/store';
import { ScrollArea } from '@/common/presentation/components/ui/scroll-area';
import { useTranslation } from 'react-i18next';
import { toast } from "@/common/presentation/components/ui/use-toast.ts";
// import { Skeleton } from '@/common/presentation/components/ui/skeleton';
import { LocationSelector } from '@/common/presentation/components/Selectors/LocationSelector';
import { setConfigurationsLoading } from '@/modules/configurations/presentation/slices/configurationSlice';
import { getLocationMenusConfiguration } from '@/common/presentation/slices/menuSlice';


interface FormValues {
  [key: string]: boolean;
}

interface ITrackers {
  name: string;
  label?: string;
  icon?: string;
  path?: string;
  menuId: string;
}

export const EnabledTrackers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { locationSelectedId } = useLocation();
  const { enabledTrackers, isLoading: isLoadingTracker } = useSelector((state: RootState) => state.settings.trackerConfiguration);
  const { configurations, isLoading } = useSelector((state: any) => state.configurations);
  
  const form = useForm<FormValues>({
    defaultValues: {},
    mode: 'onChange'
  });

  const handleUpdate = async (data: FormValues) => {
    const newData = { ...data, locationId: locationSelectedId };
    
    const { payload } = await dispatch(update(newData));

    if (payload.result === 'ok') {
      dispatch(getEnabledTrackers(locationSelectedId));
      freshMenu(locationSelectedId);
      toast({
        description: t('settings.updatedSuccessfully'),
        className: 'bg-green-500 text-white z-[99999]'
      });
    }

  };

  const freshMenu = async (locationId: string) => {
    if (isLoading) return null;

    dispatch(setConfigurationsLoading());
    const response: any = await dispatch(getLocationMenusConfiguration(locationId));
    if (response?.payload?.data) {
      dispatch(configActions.setConfigurations({
        ...configurations,
        'menus': response.payload.data
      }));

    }
  }

  const trackers: ITrackers[] = (enabledTrackers && enabledTrackers?.length > 0) ?
    enabledTrackers.map((tracker: any) => {
      const status = tracker?.status === 'active' ? true : false;
      return {
        name: tracker.name,
        menuId: tracker.menu_tracker_id,
        label: tracker.label,
        status: status
      };
    }) : [];


  useEffect(() => {
    if (enabledTrackers && enabledTrackers?.length > 0) {
      const defaultValues: any = enabledTrackers.reduce((acc: { [key: string]: boolean }, tracker: any) => {
        const name: string = tracker.name;
        acc[name] = tracker.status;
        return acc;
      }, {});
      
      form.reset(defaultValues);
    }
  }, [enabledTrackers]);



  useEffect(() => {
    dispatch(trackerConfigurationLoading());
    dispatch(getEnabledTrackers(locationSelectedId));
  }, [locationSelectedId]);

  return (
    <>

      {isLoadingTracker && locationSelectedId !== 'all-locations' && (
        <div className='w-full h-full flex justify-center items-center absolute top-[0] left-[0] '>
          <Spinner size={'icon'} />
        </div>
      )}

      {locationSelectedId === 'all-locations' && (
        <>
          <p className='text-red-600'>{t('settings.selectedLocation') + '!'}</p>
          <div><LocationSelector quickView={true} /></div>
        </>
      )}

      {trackers.length > 0 && !isLoadingTracker && (
        <div className="flex flex-col h-[75vh]">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleUpdate)} className="flex flex-col h-[75vh]">
              <h3 className="mb-4 text-lg font-medium">{t('settings.enabledTrackers.enabledTrackers')}</h3>
              <ScrollArea className="flex-grow pr-4">
                <div className="space-y-4">
                  {trackers.map((tracker: ITrackers) => (

                    <FormField
                      key={tracker.menuId}
                      control={form.control}
                      name={tracker.name}
                      render={({ field }) => (
                        <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                          <div className="space-y-0.5">
                            <FormLabel className="text-base">
                              {tracker.label}
                            </FormLabel>
                          </div>
                          <FormControl>
                            <Switch
                              checked={field.value}
                              onCheckedChange={field.onChange}
                              defaultChecked={true}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  ))}
                </div>
              </ScrollArea>
              <div className="mt-4 flex justify-end">
                <Button type="submit">{t('settings.save')}</Button>
              </div>
            </form>
          </Form>
        </div>
      )}
    </>

  );

};