import CustomCard from '@/common/presentation/components/CustomCard/CustomCard';
import { Label } from '@/common/presentation/components/ui/label';
import { Switch } from '@/common/presentation/components/ui/switch';
import { Gauge, HeartPulse, Loader2, LucideIcon, Thermometer, Weight, Wind } from 'lucide-react';
import { useCallback, useEffect, useState } from 'react';
import VitalSetting from './VitalSetting';
import { Button } from '@/components/ui/button';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/store/store';
import { postResidentVitalSetting } from '../../slices/residentSlice';
import { VitalSettingI } from '@/modules/residents/infrastructure/types/trackerAkerts';

interface IRowField {
  first_label: string;
  second_label: string;
  third_label?: string;
  key: string;
  value1: string;
  value2: string;
}

interface IVitalSetting {
  label: string;
  icon: LucideIcon;
  key: string;
  fields: IRowField[];
}

const ResidentAlerts = ({ residentId } : { residentId: string; }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [medicineAlert, setMedicineAlert] = useState<boolean>(false);
  const [bowelAlert, setBowelAlert] = useState<boolean>(false);
  const [savingInfo, setSavingInfo] = useState<boolean>(false);
  const [vitalSettings, setVitalSettings] = useState<IVitalSetting[]>([
    {
      icon: Weight,
      label: 'Weight',
      key: 'weight',
      fields: [
        {
          key: 'increase_in_weight_1',
          first_label: 'Increase in weight',
          second_label: 'lbs in',
          third_label: 'days.',
          value1: '',
          value2: ''
        },
        {
          key: 'increase_in_weight_2',
          first_label: 'Increase in weight',
          second_label: 'lbs in',
          third_label: 'days.',
          value1: '',
          value2: ''
        },
        {
          key: 'decrease_in_weight_1',
          first_label: 'Decrease in weight',
          second_label: 'lbs in',
          third_label: 'days.',
          value1: '',
          value2: ''
        }
      ]
    },
    {
      icon: Gauge,
      label: 'Blood Pressure',
      key: 'blood_pressure',
      fields: [
        {
          key: 'systolic_if_above_1',
          first_label: 'Systolic, if above',
          second_label: 'or below',
          value1: '',
          value2: ''
        },
        {
          key: 'diaslotic_if_above_1',
          first_label: 'Diastolic, if above',
          second_label: 'or below',
          value1: '',
          value2: ''
        }
      ]
    },
    {
      icon: Wind,
      label: 'Pulse Oxygen',
      key: 'pulse_oxygen',
      fields: [
        {
          key: 'pulse_oxygen_if_above',
          first_label: 'If above',
          second_label: 'or below',
          value1: '',
          value2: ''
        }
      ]
    },
    {
      icon: HeartPulse,
      label: 'Heart Rate',
      key: 'heart_rate',
      fields: [
        {
          key: 'heart_rate_if_above',
          first_label: 'If above',
          second_label: 'or below',
          value1: '',
          value2: ''
        }
      ]
    },
    {
      icon: Thermometer,
      label: 'Temperature',
      key: 'temperature',
      fields: [
        {
          key: 'temperature_if_above',
          first_label: 'If above',
          second_label: 'or below',
          value1: '',
          value2: ''
        }
      ]
    }
  ]);

  const handleInputChange = (
    settingIndex: number,
    fieldIndex: number,
    inputName: 'value1' | 'value2',
    value: string
  ) => {
    setVitalSettings((prevSettings) => {
      const newSettings = [...prevSettings];
      newSettings[settingIndex].fields[fieldIndex][inputName] = value;
      return newSettings;
    });
  };

  const saveAlertSettings = useCallback(async () => {
    setSavingInfo(true);
    try {
        const settingValues = formatValues(vitalSettings);
        await dispatch(postResidentVitalSetting({
            resident_id: residentId,
            vitals_setting: settingValues
        }));

    } catch (error) {
        
    }
      setSavingInfo(false);
  }, [vitalSettings]);

  const formatValues = (settingValues: IVitalSetting[]): VitalSettingI => {
    return settingValues.reduce((acc, setting) => {
      acc[setting.key] = setting.fields.map((field) => ({
        key: field.key,
        value1: field.value1,
        value2: field.value2
      }));
      return acc;
    }, {} as VitalSettingI);
  };

  return (
    <>
      <CustomCard
        title="Alerts"
        description=""
        button={
          <Button disabled={savingInfo} onClick={saveAlertSettings}>
            {savingInfo && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}Save
          </Button>
        }
      >
        <div>
          <div className="mx-auto pt-6 pb-6">
            <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 2lg:grid-cols-3 gap-6">
              {vitalSettings.map((settings, index) => (
                <VitalSetting
                  indexAlert={index}
                  key={index}
                  icon={settings.icon}
                  label={settings.label}
                  fields={settings.fields}
                  onInputChange={handleInputChange}
                />
              ))}
            </div>
          </div>

          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-row items-center justify-between rounded-lg  p-4">
              <div className="space-y-0.5 max-w-md">
                <Label className="text-base">Medicine Alerts</Label>
                <p>Enable to display medicine alerts for the resident on the dashboard.</p>
              </div>
              <Switch
                checked={medicineAlert}
                onCheckedChange={() => {
                  setMedicineAlert(!medicineAlert);
                }}
              />
            </div>

            {/* Bowel Movement Alerts */}
            <div className="flex flex-row items-center justify-between rounded-lg  p-4">
              <div className="space-y-0.5 max-w-md">
                <Label className="text-base">Bowel Movement Alerts</Label>
                <p>
                  Enable to display alerts if the resident's bowel movement has not been tracked for the last 2 days or
                  shows irregularities.
                </p>
              </div>
              <Switch
                checked={bowelAlert}
                onCheckedChange={() => {
                  setBowelAlert(!bowelAlert);
                }}
              />
            </div>
          </div>
        </div>
      </CustomCard>
    </>
  );
};

export default ResidentAlerts;
