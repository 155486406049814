import { CustomAccordion } from '@/common/presentation/components/CustomAccordion/CustomAccordion';
import { SplashScreen } from '@/common/presentation/components/SplashScreen/SplashScreen';
import { BackNavigationBar } from '@/common/presentation/components/navigations/BackNavigationBar';
import { Avatar, AvatarFallback, AvatarImage } from '@/common/presentation/components/ui/avatar';
import { cn } from '@/lib/utils';
import * as staffMemberFormActions from '@/modules/staff/presentation/slices/staffMemberSlice';
import { AppDispatch, RootState } from '@/store/store';
import { format, parseISO } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { ResidentNavigationMenu } from '../components/ResidentNavigationMenu';
import { ContactCard } from '../components/profile/ContactCard';

export const DashboardProfile = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const { staffMember, status } = useSelector((state: RootState) => state.staff.staffMember);

  const StafftAge = staffMember?.dob ? new Date().getFullYear() - new Date(staffMember?.dob).getFullYear() : 0;

  useEffect(() => {
    if (searchParams.get('s')) {
      dispatch(staffMemberFormActions.getStaffMemberById(searchParams.get('s')!));
    }
  }, [searchParams.get('s')]);

  if (status === 'loading' && !staffMember) {
    return <SplashScreen />;
  }

  return (
    <>
      <div className="flex justify-center">
        <div className="w-full py-4 flex flex-col gap-6">
          <BackNavigationBar title="Staff Profile" path={'/staff'} />

          <ResidentNavigationMenu />

          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-12 flex flex-col gap-4">
              <CustomAccordion openOption={false} className="w-full" headerPadding={false} defaultOpen>
                <div className="grid grid-cols-12 gap-4 pb-6">
                  <div className="overflow-hidden w-full flex justify-center col-span-full xl:col-span-4">
                    <Avatar className="w-64 h-64">
                      <AvatarImage
                        src={staffMember?.profile_url}
                        alt={`${staffMember?.first_name} ${staffMember?.last_name}`}
                        className="h-full w-full object-cover"
                      />
                      <AvatarFallback className={cn('bg-gray-100 dark:bg-[#1d1d1d] text-5xl rounded-md')}>
                        {staffMember?.first_name[0] + staffMember?.last_name[0]}
                      </AvatarFallback>
                    </Avatar>
                  </div>

                  <div className="col-span-full xl:col-span-8">
                    <div className="flex flex-col gap-4">
                      <h1 className="text-2xl font-bold">
                        {staffMember?.first_name} {staffMember?.middle_name} {staffMember?.last_name}{' '}
                        {staffMember?.preferred_name}
                      </h1>
                      <p>
                        <span className="font-bold mr-2">{t('staff.dob')}:</span>
                        {staffMember?.dob
                          ? `${format(parseISO(staffMember?.dob), 'MM/dd/yyyy')} (${StafftAge} years old)`
                          : 'Not Specified'}
                      </p>
                      <p>
                        <span className="font-bold mr-2">{t('staff.sex')}:</span>
                        {staffMember?.sex?.[0].toUpperCase() + staffMember?.sex?.slice(1) || 'Not Specified'}
                      </p>
                      <p>
                        <span className="font-bold mr-2">{t('staff.spoken_languages')}:</span>{' '}
                        {staffMember?.spoken_languages || 'Not Specified'}
                      </p>
                      <p className="max-w-[300px] w-full grid grid-cols-2 gap-2">
                        <div>
                          <span className="font-bold">{t('staff.weight')}:</span>{' '}
                          {staffMember?.weight || 'Not Specified'}
                        </div>
                        <div>
                          <span className="font-bold">{t('staff.height')}:</span>{' '}
                          {staffMember?.height || 'Not Specified'}
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </CustomAccordion>

              <div className="grid grid-cols-2 gap-2">
                <ContactCard />
                {/* <AlertsCard /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
