import { axios } from '@/common/infrastructure/providers/AxiosContextProvider';

const NoteService = {
  getNotes: async (filters: [string, string][]) => {
    let url = '/notes';

    const params = new URLSearchParams(filters).toString();

    if (params) {
      url += `?${params}`;
    }

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  getFilteredNotes: async (filters: any) => {
    let url = `/notes`;

    const params = new URLSearchParams(filters).toString();
    if (params) {
      url += `?${params}`;
    }

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },
  createNote: async (formData: any) => {
    let url = '/notes/create';

    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  updateNote: async (formData: any) => {
    let url = '/notes/update';

    try {
      const response = await axios.put(url, formData);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  createAnswer: async (formData: any) => {
    let url = '/notes/createAnswer';

    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  unreadNote: async (formData: any) => {
    let url = '/notes/createReader';

    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  getCategories: async () => {
    let url = '/notes/getCategories';

    try {
      const response = await axios.get(url);
      return response.data.payload;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  getQuickNotes: async () => {
    let url = '/notes/getQuickNotes';

    try {
      const response = await axios.get(url);
      return response.data.payload;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  addUsertoNote: async (formData: any) => {
    let url = '/notes/addUserToNote';

    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  sharePrivateNote: async (formData: any) => {
    let url = '/notes/sharePrivateNote';

    try {
      const response = await axios.post(url, formData);
      return response.data.payload;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  getDrafts: async (filters: any) => {
    let url = '/notes/getDrafts';

    const params = new URLSearchParams(filters).toString();

    if (params) {
      url += `?${params}`;
    }

    try {
      const response = await axios.get(url);
      return response.data.payload;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  createDraft: async (formData: any) => {
    let url = '/notes/createDraft';

    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  updateDraft: async (formData: any) => {
    let url = '/notes/editDraft';

    try {
      const response = await axios.put(url, formData);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  shareNote: async (formData: any) => {
    let url = '/notes/shareNote';

    try {
      const response = await axios.post(url, formData);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

  removeNote: async (noteId: string) => {
    let url = `/notes/${noteId}`;

    try {
      const response = await axios.delete(url);
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const errorMessage = error.response.data.message || 'Error';
        throw new Error(errorMessage);
      } else if (error.request) {
        throw new Error('No response received from the server');
      } else {
        throw new Error('An error occurred while processing the request');
      }
    }
  },

};

export default NoteService;
