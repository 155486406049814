import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authReducer from '@/auth/presentation/slices/authSlice';

import locationsReducer from '@/modules/locations/presentation/slices/locationsSlice';
import locationReducer from '@/modules/locations/presentation/slices/locationSlice';

import trackerReducer from '@/modules/tracker/presentation/slices/TrackerSlice';
import trackerCleaningReducer from '@/modules/tracker/presentation/slices/TrackerCleaningSlice';
import trackerMenstruation from '@/modules/tracker/presentation/slices/TrackerMenstruationSlice';
import trackerSeizure from '@/modules/tracker/presentation/slices/TrackerSeizureSlice';
import trackerHygiene from '@/modules/tracker/presentation/slices/TrackerHygieneSlice';
import trackerFood from '@/modules/tracker/presentation/slices/TrackerFoodSlice';

import medicationReducer from '@/modules/medications/presentation/slices/medicationsSlice';

import calendarReducer from '@/modules/calendar/presentation/slices/CalendarSlice';

import pharmacynReducer from '@/modules/pharmacy/presentation/slices/PharmacySlice';

import physiciansReducer from '@/common/presentation/slices/physicianSlice';

import ResidentReducer from '@/modules/residents/presentation/slices/residentSlice';
import ResidentsReducer from '@/modules/residents/presentation/slices/residentsSlice';
import ResidentFormReducer from '@/modules/residents/presentation/slices/residentsForm';
import ResidentConfigurationsReducer from '@/modules/residents/presentation/slices/residentConfigurationsSlice';
import StaffConfigurationsReducer from '@/modules/staff/presentation/slices/staffConfigurationsSlice';

import configurationReducer from '@/common/presentation/slices/configurationSlice';
import contactsReducer from '@/modules/contacts/presentation/slices/contactsSlice';

import emarRoutineReducer from '@/modules/emar/presentation/slices/emarRoutineSlice';

import staffReducer from '@/modules/staff/presentation/slices/staffSlice';
import usersReducer from '@/modules/staff/presentation/slices/usersSlice';
import staffMemberReducer from '@/modules/staff/presentation/slices/staffMemberSlice';
import staffMemberFormReducer from '@/modules/staff/presentation/slices/staffMemberFormSlice';
import PaymentsReducer from '@/modules/payments/presentation/slices/paymentsSlice';
import BannerReducer from '@/common/presentation/slices/bannerSlice';
import SubscriptionReducer from '@/modules/subscription/presentation/slices/SubscriptionSlice';
import SignUpReducer from '@/modules/wizard/presentation/slices/SignUpSlice';
import MenuReducer from '@/common/presentation/slices/menuSlice';
import NoteReducer  from '@/modules/notes/presentation/slices/NoteSlice';
import DashboardReducer from '@/modules/dashboard/presentation/slices/DashboardSlice';
import authMiddleware from '@/auth/infrastructure/middleware/authMiddleware';
import DepartmentReducer from '@/modules/departments/presentation/slices/DepartmentSlice';

import TrackerConfigurationReducer from '@/modules/settings/presentation/components/menus/trackers/slices/TrackerConfigurationSlice'
import MedicationConfigurationReducer from '@/modules/settings/presentation/components/menus/medications/slices/MedicationConfigurationSlice'

const store = configureStore({
  reducer: {
    auth: authReducer,
    configurations: configurationReducer,
    locations: combineReducers({
      allLocations: locationsReducer,
      location: locationReducer
    }),
    residents: combineReducers({
      allResidents: ResidentsReducer,
      resident: ResidentReducer,
      residentForm: ResidentFormReducer,
      configurations: ResidentConfigurationsReducer
    }),
    staff: combineReducers({
      allStaff: staffReducer,
      staffMember: staffMemberReducer,
      staffMemberForm: staffMemberFormReducer,
      configurations: StaffConfigurationsReducer
    }),
    users: usersReducer,
    emar: combineReducers({
      emarRoutine: emarRoutineReducer
    }),
    dashboard: DashboardReducer,
    contacts: contactsReducer,
    tracker: trackerReducer,
    trackerCleaning: trackerCleaningReducer,
    trackerFood: trackerFood,
    trackerSeizure: trackerSeizure,
    trackerHygiene: trackerHygiene,
    trackerMenstruation: trackerMenstruation,
    signUp: SignUpReducer,
    subscription: SubscriptionReducer,
    menu: MenuReducer,
    banner: BannerReducer,
    payment: PaymentsReducer,
    medications: medicationReducer,
    pharmacy: pharmacynReducer,
    notes: NoteReducer,
    physicians: physiciansReducer,
    calendar: calendarReducer,
    departments: DepartmentReducer,
    settings: combineReducers({
      trackerConfiguration: TrackerConfigurationReducer,
      medicationConfiguration: MedicationConfigurationReducer
    }) 
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(authMiddleware.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
